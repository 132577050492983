import { PersistConfig } from 'redux-persist';
import { CookieStorage, CookiesAdapter } from 'redux-persist-cookie-storage';
import { NotPersistedRootState } from '../slices';

interface ReduxPersistConfigCreatorOptions {
  cookiesAdapter: CookiesAdapter;
}

interface ReduxPersistConfigCreator {
  (options: ReduxPersistConfigCreatorOptions): PersistConfig<NotPersistedRootState>;
}

export const createReduxPersistConfig: ReduxPersistConfigCreator = ({ cookiesAdapter }) => ({
  key: 'adminRoot',
  storage: new CookieStorage(cookiesAdapter),
  // Here should be added names of preserved redux store slices
  whitelist: ['authentication', 'theme'],
});
