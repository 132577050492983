import React, { FC, useMemo } from 'react';
import { DialogMessageViewModel } from '@common/model/dialog/message/dialogMessageViewModel';
import { format } from 'date-fns';

export interface MessageDateProps {
  message: DialogMessageViewModel;
}

export const MessageDate: FC<MessageDateProps> = ({ message }) => {
  const formatted = useMemo(() => format(new Date(message.createdAt), 'H:mm'), [message.createdAt]);
  return <time dateTime={message.createdAt}>{formatted}</time>;
};
