import styled from '@mui/material/styles/styled';
import { SELECT_BUTTON_ICON_LEFT_SPACING } from '../const';
import { SelectButtonStateProps, SpanTagProps } from '../model';

type UnstyledSpanComponent = React.ComponentClass<SpanTagProps & { ownerState: SelectButtonStateProps }>;

export const SelectIcon = styled('span' as unknown as UnstyledSpanComponent, {
  name: 'Select',
  slot: 'Icon',
})(({ theme, ownerState }) => {
  const commonStyles = {
    color: theme.palette['brand gray'],
  };

  if (ownerState.disabled) {
    commonStyles.color = theme.palette['gray light'];
  }

  return {
    ...commonStyles,
    ...(ownerState.active ? { transform: 'rotate(180deg)' } : undefined),
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.standard,
    }),
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    marginLeft: theme.spacing(SELECT_BUTTON_ICON_LEFT_SPACING),
  };
});
