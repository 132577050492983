import { useHistory } from 'react-router';
import { useEffect } from 'react';
import { generateAdminPath } from 'src/app/shared/utils/generateAdminPath';
import { AdminRoutePath } from 'src/app/routes/model';
import { DialogViewerProps } from './viewer.interface';

export const RedirectViewer = ({ dialogId }: DialogViewerProps) => {
  const history = useHistory();
  useEffect(() => {
    if (dialogId) {
      history.push(generateAdminPath(AdminRoutePath.Messages, { queryParams: { dialogId } }));
    }
  }, [dialogId, history]);
  return null;
};
