import React, { ReactNode } from 'react';
import { Typography } from '@cp/ds/src/components/typography';
import { Box } from '@cp/ds/src/components/box';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { Card, CardProps } from 'src/shared/ui/card';

export interface VacancyCardProps extends CardProps {
  title: ReactNode;
  footer?: ReactNode | ReactNode[];
  tags?: ReactNode;
  matched?: boolean;
  viewed?: boolean;
  badges?: ReactNode;
}
export const VacancyCard: React.FC<VacancyCardProps> = ({ title, viewed, tags, matched, footer, sx, children, badges, ...props }) => {
  const theme = useTheme();
  return (
    <Card sx={{ ...sx, ...(viewed && { opacity: 0.5 }), ...(matched && { borderColor: theme.palette.green }) }} {...props}>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-start' }}>
        <Typography sx={{ mb: 1, display: 'block', flexGrow: 1 }} variant="body bold">
          {title}
        </Typography>
        <Box sx={{ '&:empty': { display: 'none' } }}>{badges}</Box>
      </Box>
      <Box sx={{ display: 'flex', gap: 1 }}>{tags}</Box>
      <Box>{children}</Box>
      <Typography sx={{ display: 'block' }} variant="small text">
        {footer}
      </Typography>
    </Card>
  );
};
