import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const EditIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="edit" ref={ref as any} {...props}>
      <path
        clipRule="evenodd"
        d="M15.0409 5.10332C15.4866 4.70106 16.0704 4.48551 16.671 4.50076C17.2716 4.51601 17.8436 4.76093 18.2683 5.18535L18.2716 5.18868L18.8284 5.75583C19.2587 6.19364 19.5 6.78224 19.5 7.39568C19.5 8.00911 19.2587 8.59771 18.8284 9.03552L18.8245 9.03944L9.75697 18.065C9.6978 18.1239 9.62484 18.167 9.54472 18.1905L5.14049 19.4799C4.96637 19.5308 4.77829 19.4835 4.64906 19.3561C4.51982 19.2288 4.46972 19.0414 4.51815 18.8666L5.73577 14.4698C5.75892 14.3862 5.80343 14.3101 5.8649 14.2489L14.1009 6.05096L14.1034 6.04848L15.0207 5.12261L15.3759 5.47451L15.0409 5.10332ZM15.7204 5.83712L14.8089 6.75724L6.66418 14.8642L5.72162 18.2678L9.14167 17.2665L18.117 8.33274C18.3628 8.08166 18.5 7.74538 18.5 7.39568C18.5 7.04512 18.3621 6.70806 18.1152 6.45678L17.5598 5.89108C17.3171 5.6493 16.9899 5.50918 16.6456 5.50044C16.3049 5.49178 15.9742 5.61248 15.7204 5.83712Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M6.14646 14.1464C6.34172 13.9512 6.65831 13.9512 6.85357 14.1464L9.85357 17.1464C10.0488 17.3417 10.0488 17.6583 9.85357 17.8536C9.65831 18.0488 9.34172 18.0488 9.14646 17.8536L6.14646 14.8536C5.9512 14.6583 5.9512 14.3417 6.14646 14.1464Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M14.1465 6.14645C14.3417 5.95118 14.6583 5.95118 14.8536 6.14645L17.8536 9.14645C18.0488 9.34171 18.0488 9.65829 17.8536 9.85355C17.6583 10.0488 17.3417 10.0488 17.1465 9.85355L14.1465 6.85355C13.9512 6.65829 13.9512 6.34171 14.1465 6.14645Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
});
