import React, { useId } from 'react';
import { Collapse } from '@mui/material';
import { ChevronDownLightIcon } from '../../icons';
import { Typography } from '../typography';
import { useIsMobile } from '../../hooks/useIsMobile';
import { AccordionDetails } from './components/details';
import { AccordionRoot } from './components/root';
import { AccordionSummary } from './components/summary';
import { AccordionOwnerState, AccordionProps } from './model';
import { SummaryIconWrapper } from './components/summaryIconWrapper';
import { ACCORDION_SUMMARY_DESKTOP_TYPOGRAPHY_VARIANT, ACCORDION_SUMMARY_MOBILE_TYPOGRAPHY_VARIANT } from './const';
export type { AccordionProps } from './model';

export const Accordion = React.forwardRef<HTMLDivElement, AccordionProps>((props, ref) => {
  const isMobile = useIsMobile();
  const [isExpanedFromState, setIsExpanded] = React.useState(false);

  const { isExpanded: isExpandedFromProps } = props;

  const isExpanded = isExpandedFromProps ?? isExpanedFromState;
  const {
    onChange,
    summary,
    details,
    color = 'brand gray',
    sx,
    summarySx,
    summaryTypography = isMobile ? ACCORDION_SUMMARY_MOBILE_TYPOGRAPHY_VARIANT : ACCORDION_SUMMARY_DESKTOP_TYPOGRAPHY_VARIANT,
    summaryIcon: SummaryIcon = ChevronDownLightIcon,
    detailsSx,
    // summaryId: summaryIdDefault,
    // detailsId: detailsIdDefault,
  } = props;

  const summaryId = useId();
  const detailsId = useId();

  const ownerState: AccordionOwnerState = {
    summaryTypography,
    color,
    isExpanded,
    isDetailsPassedAsPlainText: typeof details === 'string',
    isSummaryPassedAsPlainText: typeof summary === 'string',
  };

  const handleSummaryClick = () => (onChange ? onChange(!isExpanded) : setIsExpanded(!isExpanded));

  return (
    <AccordionRoot ref={ref} sx={sx}>
      <AccordionSummary
        aria-controls={detailsId}
        aria-expanded={isExpanded}
        id={summaryId}
        onClick={handleSummaryClick}
        ownerState={ownerState}
        role="button"
        sx={summarySx}
        tabIndex={0}
      >
        {typeof summary === 'string' ? <Typography variant={summaryTypography}>{summary}</Typography> : summary}
        <SummaryIconWrapper ownerState={ownerState}>
          <SummaryIcon fontSize="small" />
        </SummaryIconWrapper>
      </AccordionSummary>
      <Collapse in={isExpanded}>
        <AccordionDetails aria-labelledby={summaryId} id={detailsId} ownerState={ownerState} role="region" sx={detailsSx}>
          {details}
        </AccordionDetails>
      </Collapse>
    </AccordionRoot>
  );
});
