import React from 'react';
import { Typography, TypographyProps } from '@cp/ds/src/components/typography';
import { styled } from '@cp/ds/src/theme';
import { getFullName } from '../lib';

const Root = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
}));

export interface AuthorNameProps extends TypographyProps {
  author: {
    name?: string;
    surname?: string;
  };
}

export const AuthorName: React.FC<AuthorNameProps> = ({ author, ...props }) => {
  return (
    <Root variant="body bold" {...props}>
      {getFullName(author, '\u00A0')}
    </Root>
  );
};
