import React from 'react';
import { useDispatch } from 'react-redux';
import { CommonModalProps, ModalDictionary } from '../components/modalProvider/model';
import { ModalQueueItem, modalQueueSlice } from './../../../redux/slices/modal/queue/reducer';

export const useModal = () => {
  const dispatch = useDispatch();

  return React.useMemo(
    () => ({
      openModal: <N extends keyof ModalDictionary>(name: N, props?: CommonModalProps & ModalDictionary[N]) => {
        dispatch(modalQueueSlice.actions.push({ name, props } as ModalQueueItem<N>));
      },
    }),
    [dispatch],
  );
};
