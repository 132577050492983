import React from 'react';
import { AdminClientProfileViewModel } from '@common/model/client/profile/adminClientProfileViewModel';
import { Typography } from '@cp/ds/src/components/typography';
import { Skeleton } from '@cp/ds/src/components/skeleton';
import { ClientName, ClientNameProps } from './clientName';

export type ClientNameLazyProps = Omit<ClientNameProps, 'client'> & {
  client?: AdminClientProfileViewModel;
};

export const ClientNameLazy = ({ client, ...props }: ClientNameLazyProps) => {
  return client ? (
    <ClientName client={client} {...props} />
  ) : (
    <Skeleton>
      <Typography {...props}>Name is loading</Typography>
    </Skeleton>
  );
};
