import React, { PropsWithChildren, ReactNode } from 'react';
import { Box, BoxProps } from '@cp/ds/src/components/box';
import { Typography } from '@cp/ds/src/components/typography';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { styled } from '@cp/ds/src/theme/styled';

export interface ResponseCardProps extends BoxProps, PropsWithChildren {
  title: ReactNode;
  respondedAt: ReactNode;
  avatar?: ReactNode;
  badge?: ReactNode;
  description?: ReactNode;
}

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'flex-start',
  gap: theme.spacing(1),
  border: '1px solid',
  padding: theme.spacing(1.5),
  borderColor: theme.palette['gray light'],
  borderRadius: `${theme.borderRadius.normal}px`,
  boxShadow: '0px 2.166555166244507px 3.24983286857605px 0px #8AB6D11A',
  backgroundColor: theme.palette.background.default,
}));

export const ResponseCard: React.FC<ResponseCardProps> = ({ title, avatar, respondedAt, badge, description, children, ...props }) => {
  const theme = useTheme();
  return (
    <Root {...props}>
      <Box>{avatar}</Box>
      <Box sx={{ flexGrow: '1' }}>
        <Typography sx={{ display: 'block' }} variant="body bold">
          {title}
        </Typography>
        <Typography sx={{ display: 'block' }} variant="caption">
          {description}
        </Typography>
        {children}
      </Box>
      <Box>
        <Typography sx={{ color: theme.palette.gray }} variant="small text">
          {respondedAt}
        </Typography>
        {badge}
      </Box>
    </Root>
  );
};
