import { differenceInDays, differenceInSeconds } from 'date-fns';
import { FormattingStrategy } from './formattingStrategy';

export const approximateStrategy: FormattingStrategy = (lastSeen, now) => {
  if (differenceInSeconds(now, lastSeen) <= 60) {
    return 'только что';
  } else if (differenceInDays(now, lastSeen) < 7) {
    return 'недавно';
  } else {
    return 'давно';
  }
};
