import React from 'react';
import { SvgIcon, SvgIconProps } from '../../../components/svgIcon';

export const MailIconRound = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="social/round/mailRound" ref={ref as any} {...props}>
      <g clipPath="url(#clip0_318_25159)" fill="none">
        <path
          d="M6.34824 6.85718H17.6518C18.318 6.85718 18.8572 7.39203 18.8572 8.04399V15.9561C18.8572 16.612 18.314 17.1429 17.6518 17.1429H6.34824C5.68203 17.1429 5.14288 16.608 5.14288 15.9561V8.04399C5.14288 7.38982 5.68444 6.85718 6.34824 6.85718Z"
          fill="#405368"
        />
        <path
          d="M18.3583 6H5.64173C4.89495 6 4.28571 6.62142 4.28571 7.38462V16.6154C4.28571 17.376 4.89224 18 5.64173 18H18.3583C19.1032 18 19.7143 17.3807 19.7143 16.6154V7.38462C19.7143 6.624 19.1077 6 18.3583 6ZM18.171 6.92308L12.0287 13.1949L5.83335 6.92308H18.171ZM5.18972 16.4242V7.57135L9.54344 11.9787L5.18972 16.4242ZM5.82895 17.0769L10.1854 12.6286L11.7119 14.1739C11.8886 14.3528 12.1738 14.3522 12.3498 14.1725L13.8382 12.6527L18.171 17.0769H5.82895ZM18.8103 16.4242L14.4774 12L18.8103 7.57575V16.4242Z"
          fill="white"
        />
        <circle cx="12" cy="12" r="11.5" stroke="#405368" strokeWidth="1" />
      </g>
    </SvgIcon>
  );
});
