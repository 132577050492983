import { SelectButtonSize } from './model';

export const SELECT_BUTTON_ICON_LEFT_SPACING = 1;

export const selectSizeDict: Record<
  SelectButtonSize,
  {
    buttonVerticalSpacing: number;
    buttonLeftSpacing: number;
    buttonRightSpacing: number;
  }
> = {
  normal: {
    buttonVerticalSpacing: 12,
    buttonLeftSpacing: 24,
    buttonRightSpacing: 12,
  },
  small: {
    buttonVerticalSpacing: 9,
    buttonLeftSpacing: 16,
    buttonRightSpacing: 8,
  },
};
