import React, { PropsWithChildren } from 'react';
import { styled } from '@cp/ds/src/theme/styled';
import { Box, BoxProps } from '@cp/ds/src/components/box';

const Root = styled(Box)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  lineClamp: '1',
  wordBreak: 'break-all',
}));

export interface ResumeCardTitleProps extends BoxProps, PropsWithChildren {}

export const ResumeCardTitle: React.FC<ResumeCardTitleProps> = (props) => {
  return <Root {...props} />;
};
