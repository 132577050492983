import { useMemo } from 'react';
import { ClientInteractionsRequest } from '@common/model/stats/clientInteractions.request';
import { InteractionType } from '@common/model/stats/interactionType';
import { ClientInteractionsResponse } from '@common/model/stats/clientInteractions.response';
import { useDispatch, useStore } from 'react-redux';
import { ClientInteractionCreatedEvent } from '@common/model/stats/events/ClientInteractionCreated.event';
import { ClientInteractionDeletedEvent } from '@common/model/stats/events/ClientInteractionDeleted.event';
import { ThunkDispatch } from 'redux-thunk';
import { clientInteractionsCoreApi, useStatsSocket } from '../api';
import { IResponseDelayApiProvider } from './IApiProvider';

export const useApiProvider = (): IResponseDelayApiProvider => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const store = useStore();
  const socket = useStatsSocket();

  return useMemo(
    () => ({
      getItems: (params?: ClientInteractionsRequest): Promise<ClientInteractionsResponse> => {
        const fullParams: ClientInteractionsRequest = { type: InteractionType.DialogEstablishing, isInitiatedByEmployer: true, ...params };
        const query = dispatch(clientInteractionsCoreApi.endpoints.getClientInteractions.initiate(fullParams));
        query.unsubscribe();
        return query.unwrap();
      },
      getCachedItems(params?: ClientInteractionsRequest): ClientInteractionsResponse | undefined {
        const fullParams: ClientInteractionsRequest = { type: InteractionType.DialogEstablishing, isInitiatedByEmployer: true, ...params };
        const cachedResponse = clientInteractionsCoreApi.endpoints.getClientInteractions.select(fullParams)(store.getState());
        return cachedResponse.data;
      },
      onItemAdded(cb) {
        socket.on(ClientInteractionCreatedEvent.type, (payload: ClientInteractionCreatedEvent['payload']) => {
          cb(payload);
        });
      },
      onItemDeleted(cb) {
        socket.on(ClientInteractionDeletedEvent.type, (payload: ClientInteractionDeletedEvent['payload']) => {
          cb(payload);
        });
      },
    }),
    [socket, store, dispatch],
  );
};
