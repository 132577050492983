import React from 'react';
import useForkRef from '@mui/utils/useForkRef';
import { LabelContentDefault } from './components/labelContentDefault';
import { FileInputProps, FileInputStateProps } from './model';
import { FileInputLabel } from './components/label';

export const FileInput = React.forwardRef<HTMLInputElement, FileInputProps>((props, ref) => {
  const { value, onChange, onReset, error, disabled = false, label, labelText, ...otherProps } = props;
  const innerRef = React.useRef<HTMLInputElement | null>(null);
  const inputRef = useForkRef(innerRef, ref);

  React.useEffect(() => {
    if (!value && innerRef.current) {
      innerRef.current.value = '';
    }
  }, [value]);

  const ownerState: FileInputStateProps = {
    disabled,
    error,
    label,
    labelText,
  };

  return (
    <FileInputLabel ownerState={ownerState}>
      {label ?? <LabelContentDefault disabled={disabled} error={error} labelText={labelText} onReset={onReset} value={value} />}
      <input hidden onChange={onChange} ref={inputRef} type="file" {...otherProps} />
    </FileInputLabel>
  );
});
