import React, { FC } from 'react';
import { Box } from '@cp/ds/src/components/box';
import { Typography } from '@cp/ds/src/components/typography';
import { Stack } from '@cp/ds/src/components/stack';
import { MemberPhoto, MemberPhotoProps } from '@cp/entities/dialog';
import { DialogMemberViewModel } from '@common/model/dialog/dialogMemberViewModel';
import { mergeSx } from '@cp/ds/src/utils/sx/merge';
import { SxProps } from '@cp/ds';

export interface ParticipantInfoProps {
  participant: DialogMemberViewModel;
  rightBlockSx?: SxProps;
  lowerBlock?: React.ReactNode;
  avatarSize?: MemberPhotoProps['size'];
  sx?: SxProps;
}

export const DialogParticipantInfo: FC<ParticipantInfoProps> = ({ participant, lowerBlock, avatarSize, rightBlockSx, sx }) => {
  return (
    <Box sx={mergeSx({ display: 'flex', alignItems: 'center' }, sx)}>
      <MemberPhoto member={participant} size={avatarSize} sx={{ mr: 2 }} />
      <Stack sx={rightBlockSx}>
        <Typography component="div" variant="h6 Bold">
          {participant.name}
        </Typography>
        {lowerBlock}
      </Stack>
    </Box>
  );
};
