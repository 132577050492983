import React from 'react';
import { shouldForwardProp } from '@mui/system';
import {
  // CHECKBOX_LABEL_BORDER_WIDTH,
  CHECKBOX_LABEL_HORIZONTAL_SPACING,
  // CHECKBOX_LABEL_TYPOGRAPHY_VARIANT,
  CHECKBOX_LABEL_VERTICAL_SPACING,
} from '../const';
import { CheckboxStateProps } from '../model';
import { styled } from '../../../theme';

type UnstyledLabelComponent = React.ComponentClass<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> & { ownerState: Required<CheckboxStateProps> }
>;

export const StyledLabel = styled('label' as unknown as UnstyledLabelComponent, {
  shouldForwardProp,
  name: 'Checkbox',
  slot: 'Label',
})(({ theme, ownerState }) => ({
  userSelect: 'none',
  display: 'inline-flex',
  padding: theme.spacing(CHECKBOX_LABEL_VERTICAL_SPACING, CHECKBOX_LABEL_HORIZONTAL_SPACING),
  position: 'relative',
  cursor: 'pointer',
  borderRadius: `${theme.borderRadius.small}px`,
  border: `1px solid ${theme.palette['gray light']}`,
  backgroundColor: theme.palette.background.default,
  color: theme.palette['brand gray'],
  transition: theme.transitions.create(['background-color', 'border-color', 'color'], {
    duration: theme.transitions.duration.short,
  }),
  WebkitTapHighlightColor: 'transparent',

  '&.Checkbox_disabled': {
    cursor: 'not-allowed',
    color: theme.palette['gray'],
    borderColor: theme.palette['gray middle'],
  },
  '&.Checkbox_checked': {
    borderColor: theme.palette['brand gray'],
    backgroundColor: theme.palette['brand gray'],
    color: theme.palette.white,
  },
  '&.Checkbox_checked.Checkbox_disabled': {
    opacity: 0.8,
  },
  '@media (pointer: fine)': {
    '&:hover': !ownerState.disabled && {
      borderColor: theme.palette['brand gray'],
    },
  },
}));
