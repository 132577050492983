import React from 'react';
import { declination } from '@cp/ds';
import { DeclinationMnemonic } from '@common/model/resource/declinationMnemonic';
import { coreResourceTranslationApi } from 'src/services/core/area/resource/translation';

export const useDeclination = () => {
  const { data } = coreResourceTranslationApi.useGetDeclinationDictionaryQuery();

  return React.useCallback(
    (number: number, mnemonic: DeclinationMnemonic): string => {
      if (!data?.[mnemonic]) {
        return '';
      }

      return declination(number, data[mnemonic]);
    },
    [data],
  );
};
