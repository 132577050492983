import { Duration } from 'date-fns';
import { AdminCatalogItemsListRequest } from '@common/model/catalog/item/adminList.request';

export type Selection = {
  title: string;
  minAge?: Duration;
  maxAge?: Duration;
  filter?: Partial<AdminCatalogItemsListRequest>;
};

export const selections: Selection[] = [
  {
    title: 'Matching',
    filter: { matchedAt: { isNotEmpty: true }, sorters: { matchedAt: 'desc' } },
  },
  {
    title: 'Новые',
    maxAge: { minutes: 14, seconds: 59 },
  },
  {
    title: 'Больше 15 минут',
    minAge: { minutes: 15 },
    maxAge: { minutes: 59, seconds: 59 },
  },
  {
    title: 'Больше 1 часа',
    minAge: { hours: 1 },
    maxAge: { hours: 5, minutes: 59, seconds: 59 },
  },
  {
    title: 'Больше 6 часов',
    minAge: { hours: 6 },
    maxAge: { hours: 23, minutes: 59, seconds: 59 },
  },
  {
    title: 'Больше 1 дня',
    minAge: { days: 1 },
    maxAge: { days: 2, hours: 23, minutes: 59, seconds: 59 },
  },
  {
    title: 'Больше 3 дней',
    minAge: { days: 3 },
  },
];
