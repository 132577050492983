import { unstable_composeClasses as composeClasses } from '@mui/material';
import { CheckboxProps } from './model';

export const useUtilityClasses = (ownerState: Partial<CheckboxProps>) => {
  const { disabled, value } = ownerState;

  const slots = {
    root: ['root', value && 'checked', disabled && 'disabled'],
  };

  return composeClasses(slots, (s) => `Checkbox_${s}`);
};
