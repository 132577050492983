import styled from '@mui/material/styles/styled';
import React from 'react';
import { FileInputStateProps } from '../model';

type LabelTagProps = {}; // React.InputHTMLAttributes<HTMLLabelElement>;

type UnstyledLabelComponent = React.ComponentClass<React.PropsWithChildren<LabelTagProps & { ownerState: FileInputStateProps }>>;

export const FileInputLabel = styled('label' as unknown as UnstyledLabelComponent, {
  name: 'FileInput',
  slot: 'Label',
})(({ ownerState }) => {
  const { disabled } = ownerState;

  return {
    cursor: disabled ? 'not-allowed' : 'pointer',
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',

    '&:focus, &:active': {
      outline: 'none',
    },
  };
});
