import React from 'react';
import useForkRef from '@mui/utils/useForkRef';
import { TextInput } from '../text';
import { useInputMask } from '../../../../../hooks/useInputMask';
import { DateInputProps } from './model';
import { DATE_INPUT_MASK, dateInputMaskTokens } from './const';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const DateInput = React.forwardRef<HTMLInputElement, DateInputProps & { type: 'date' }>(({ type, ...props }, ref) => {
  const innerRef = React.useRef<HTMLInputElement>(null);
  const fieldRef = useForkRef(innerRef, ref);

  useInputMask(innerRef, DATE_INPUT_MASK, dateInputMaskTokens);

  return <TextInput inputMode="numeric" placeholder="Дата" ref={fieldRef} {...props} />;
});
