import { coreApi } from '@cp/shared/api/core';
import { ApiRoutePathParams } from '@common/api/routePathParams';
import { ApiRoute } from '@common/api/route';
import { generateApiUrl } from '@common/api/configure';
import { AdminCatalogItemsListResponse } from '@common/model/catalog/item/adminList.request';
import { ApiRouteQueryParams } from '@common/api/routeQueryParams';
import { AdminCatalogItemViewModel } from '@common/model/catalog/item/admin.view';
import { AdminCatalogItemApiTag, adminCatalogItemApiTags } from './tags';

export const adminCatalogItemApi = coreApi
  .enhanceEndpoints({
    addTagTypes: adminCatalogItemApiTags,
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCatalogItem: builder.query<AdminCatalogItemViewModel, { params: ApiRoutePathParams[ApiRoute.AdminCatalogItemGetOne] }>({
        query: ({ params }) => {
          return {
            url: generateApiUrl(ApiRoute.AdminCatalogItemGetOne, params),
          };
        },
        providesTags: (_result, _error, { params: { id } }) => [{ type: AdminCatalogItemApiTag.Item, id }],
      }),
      getList: builder.query<AdminCatalogItemsListResponse, ApiRouteQueryParams[ApiRoute.AdminCatalogItemsGet]>({
        query: (params) => ({
          url: generateApiUrl(ApiRoute.AdminCatalogItemsGet),
          params,
        }),
        keepUnusedDataFor: 10,
      }),
    }),
  });
