import useForkRef from '@mui/utils/useForkRef';
import React from 'react';
import { TextAreaLabel } from './components/label';
import { TextAreaRoot } from './components/root';
import { StyledTextAreaContainer } from './components/styledContainer';
import { StyledTextArea } from './components/styledTextArea';
import { TextAreaProps, TextAreaStateProps, TextAreaTagProps } from './model';

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
  const { error = false, disabled = false, placeholder = 'Введите текст', sx, label, size = 'normal', inputSx, ...otherProps } = props;

  const innerRef = React.useRef<HTMLTextAreaElement>(null);
  const inputRef = useForkRef(innerRef, ref);
  const [focused, setFocused] = React.useState(false);
  const id = React.useId();

  React.useEffect(() => {
    if (!innerRef.current) {
      return;
    }

    const textArea = innerRef.current;
    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);

    textArea.addEventListener('focus', onFocus);
    textArea.addEventListener('blur', onBlur);

    return () => {
      textArea.removeEventListener('focus', onFocus);
      textArea.removeEventListener('blur', onBlur);
    };
  }, []);

  const ownerState: Required<TextAreaStateProps & { focused: boolean }> = { error, disabled, focused, size };

  return (
    <TextAreaRoot sx={sx}>
      {label && (
        <TextAreaLabel htmlFor={id} ownerState={ownerState}>
          {label}
        </TextAreaLabel>
      )}
      <StyledTextAreaContainer ownerState={ownerState}>
        <StyledTextArea
          id={id}
          ownerState={ownerState}
          placeholder={placeholder}
          ref={
            inputRef as React.Ref<
              React.Component<
                TextAreaTagProps & {
                  ownerState: Required<TextAreaStateProps>;
                },
                unknown,
                unknown
              >
            >
          }
          rows={4}
          sx={inputSx}
          {...otherProps}
        />
      </StyledTextAreaContainer>
    </TextAreaRoot>
  );
});
