import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Box } from '@cp/ds/src/components/box';
import { useCurrentLanguage } from '@cp/shared/localization';
import { FormContext, createFromValidationResolver } from '@cp/utils/form';
import { Button } from '@cp/ds/src/components/button';
import { useEventCallback } from '@cp/ds/src/hooks/useEventCallback';
import { Stack } from '@cp/ds/src/components/stack';
import { ModeratorMessageField } from '../moderatorMessageField';
import { ModerationMessageFormModel, ModeratorMessageFormHandle, ModeratorMessageFormProps } from './model';

export * from './model';

const resolver = createFromValidationResolver(ModerationMessageFormModel);

export const ModeratorMessageForm = React.forwardRef<ModeratorMessageFormHandle, ModeratorMessageFormProps>(
  ({ onSubmit, fieldProps, hideSubmitButton, sx }, ref) => {
    const languageCode = useCurrentLanguage();
    const onSubmitMemoized = useEventCallback(onSubmit);

    const form = useForm<ModerationMessageFormModel, FormContext>({
      defaultValues: {},
      resolver,
      context: { languageCode },
    });

    const handleSubmit = React.useCallback<SubmitHandler<ModerationMessageFormModel>>(
      async (values) => {
        if (values.message) {
          await onSubmitMemoized?.(values, { form });
        }
      },
      [form, onSubmitMemoized],
    );

    React.useImperativeHandle(
      ref,
      () => ({
        submit: form.handleSubmit(handleSubmit),
        validate: () => form.trigger(),
      }),
      [form, handleSubmit],
    );

    return (
      <FormProvider {...form}>
        <Box component="form" noValidate onSubmit={form.handleSubmit(handleSubmit)} sx={sx}>
          <ModeratorMessageField {...fieldProps} />

          {!hideSubmitButton && (
            <Stack direction="row" justifyContent="center">
              <Button color="gray" sx={{ mt: 5 }} type="submit" variant="primary">
                Сохранить
              </Button>
            </Stack>
          )}
        </Box>
      </FormProvider>
    );
  },
);
