/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import { CareerId } from '@common/const/catalog/career';
import { config } from '@cp/shared/config';
import { CITY_PATH_PARAMETER } from '@cp/entities/clientWebRoutes';
import { PathGenerator } from './generatePermalink';

// FIXME: temporary solution while watiting for client router (resolver) implementation. Do not use this code anywhere else
export const pathGenerator: PathGenerator = ({ pathParams, pageProps }) => {
  let career: string;
  switch (pageProps.careerId) {
    case CareerId.Nanny:
      career = 'nannies';
      break;
    case CareerId.Nurse:
      career = 'nurses';
      break;
    case CareerId.Tutor:
      career = 'tutors';
      break;
    case CareerId.Housekeeper:
      career = 'housekeepers';
      break;
    default:
      career = '';
  }
  const city = pathParams[CITY_PATH_PARAMETER] && pathParams[CITY_PATH_PARAMETER] !== 'moscow' ? `/${pathParams[CITY_PATH_PARAMETER]}` : '';
  return `${config.CLIENT_WEB_ORIGIN}${city}/work-for-${career}/${pathParams.id}/`;
};
