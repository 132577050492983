// Колонки: >3 дня, 24 часа > 3 дн, 6ч > 24ч , 1ч > 6ч, 1ч > 15 м, <=15м.
const THRESHOLD_15_MIN = 900000;
const THRESHOLD_60_MIN = 3.6e6;
const THRESHOLD_6_HOURS = 2.16e7;
const THRESHOLD_24_HOURS = 8.64e7;
const THRESHOLD_3_DAYS = 2.592e8;

export type Threshold = {
  title: string;
  minDuration: number;
};

export const thresholds: Threshold[] = [
  {
    title: 'Новые',
    minDuration: 0,
  },
  {
    title: 'Больше 15 минут',
    minDuration: THRESHOLD_15_MIN,
  },
  {
    title: 'Больше 1 часа',
    minDuration: THRESHOLD_60_MIN,
  },
  {
    title: 'Больше 6 часов',
    minDuration: THRESHOLD_6_HOURS,
  },
  {
    title: 'Больше суток',
    minDuration: THRESHOLD_24_HOURS,
  },
  {
    title: 'Больше 3 дней',
    minDuration: THRESHOLD_3_DAYS,
  },
];
