import React from 'react';
import { EditableSection } from '@cp/ds/src/components/editableSection';
import { ResumeTariffsPreview } from '@cp/entities/resume';
import { CAREER_IDS_WITH_CHILDREN_PARAMETER } from '@common/const/catalog/item';
import { useModal } from 'src/app/shared/hooks/useModal';
import { useResumeData } from 'src/entities/resume';
import { RESUME_TARIFFS_EDIT_MODAL_NAME } from 'src/app/shared/components/modals/resumeTariffsEdit/const';
import { ResumeTariffsEditableSectionProps } from './model';

export * from './model';

export const ResumeTariffsEditableSection: React.FC<ResumeTariffsEditableSectionProps> = ({ resumeId }) => {
  const { openModal } = useModal();
  const { resume, isLoadingResume } = useResumeData({ resumeId });
  const isSeparatedByChildrenCount = resume ? CAREER_IDS_WITH_CHILDREN_PARAMETER.includes(resume.careerId) : false;

  const isEmpty = !isLoadingResume && (isSeparatedByChildrenCount ? !(resume?.tariffs ?? []).length : !resume?.price);

  return (
    <EditableSection
      emptyMessage={isEmpty ? 'Тарифы не указаны' : undefined}
      isLoading={isLoadingResume}
      onEdit={() => {
        if (resume) {
          openModal(RESUME_TARIFFS_EDIT_MODAL_NAME, { data: resume });
        } else {
          throw new Error('Ошибка сервиса: резюме не найдено');
        }
      }}
      title="Тарифы"
    >
      {resume && !isEmpty && <ResumeTariffsPreview {...resume} />}
    </EditableSection>
  );
};
