import React from 'react';
import { Box } from '@cp/ds/src/components/box';
import { BoxProps } from '@cp/ds/src/components/box/model';
import { DialogMemberViewModel } from '@common/model/dialog/dialogMemberViewModel';
import { mergeSx } from '@cp/ds/src/utils/sx/merge';
import { OnlineStatus, OnlineStatusMarker } from '@cp/ds/src/components/onlineStatus';
import { Avatar } from '@cp/ds/src/components/avatar';
import { LogoIcon } from '@cp/ds/src/icons/logo';
import { getFirstLetters } from '@cp/shared/lib/getFirstLetters';
import { AvatarProps } from '@cp/ds/src/components/avatar/model';
import { DialogMemberType } from '@common/model/dialog/dialogMemberType';

const DEFAULT_AVATAR_SIZE = 48;
const DEFAULT_AVATAR_CONTENT_SIZE = 28;

export interface MemberPhotoProps extends BoxProps {
  member: DialogMemberViewModel;
  size?: number;
}

export const MemberPhoto: React.FC<MemberPhotoProps> = ({ member, size, sx, ...props }) => {
  const avatarMemberTypeDependentProps: AvatarProps = [DialogMemberType.Admin, DialogMemberType.Bot].includes(member.type)
    ? {
        content: {
          icon: LogoIcon,
        },
        contentSize: size ? undefined : DEFAULT_AVATAR_CONTENT_SIZE,
        contentBackgroundColor: 'brand gray',
        iconProps: { color: 'white' },
      }
    : {
        content: member.photo ? { image: member.photo } : { letters: getFirstLetters(member.name, member.surname) },
      };

  return (
    <Box sx={mergeSx({ position: 'relative' }, sx)} {...props}>
      <Avatar rounded size={size ?? DEFAULT_AVATAR_SIZE} {...avatarMemberTypeDependentProps} />
      <OnlineStatusMarker
        status={member.isOnline ? OnlineStatus.Online : OnlineStatus.Offline}
        sx={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}
      />
    </Box>
  );
};
