import React from 'react';
import { DeclinationContext, IDeclinationContextValue } from '@cp/shared/declination';
import { resourceTranslationApi } from '@cp/shared/api/core/resource/translation';
import { declination } from '@cp/ds';
import { useStore } from 'react-redux';
import { DeclinationProviderProps } from './model';

export const AppDeclinationProvider: React.FC<DeclinationProviderProps> = ({ children }) => {
  const store = useStore();
  resourceTranslationApi.endpoints.getDeclinationDictionary.useQuerySubscription();

  const value = React.useRef<IDeclinationContextValue>({
    declination(number, mnemonic) {
      const data = resourceTranslationApi.endpoints.getDeclinationDictionary.select(store.getState());

      if (!data?.[mnemonic]) {
        return '';
      }

      return declination(number, data[mnemonic]);
    },
  });

  return <DeclinationContext.Provider value={value.current}>{children}</DeclinationContext.Provider>;
};
