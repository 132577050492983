import { styled } from '../../../../../../theme/styled';
import { TextAreaStateProps } from '../model';

export const TextAreaLabel = styled('label', {
  name: 'TextArea',
  slot: 'Label',
})<{ ownerState: Required<TextAreaStateProps> }>(({ ownerState, theme }) => {
  const { size } = ownerState;
  const fontVariant = { 'small': 'caption', 'normal': 'body bold' }[size];
  const color = { 'small': theme.palette.gray, 'normal': theme.palette['brand gray'] }[size];

  return {
    ...theme.typography[fontVariant],
    color,
    marginBottom: { 'small': 8, 'normal': 16 }[size],
  };
});
