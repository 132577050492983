import React from 'react';
import useForkRef from '@mui/utils/useForkRef';
import { useInputMask } from '../../../../../hooks/useInputMask';
import { TextInput } from '../text';
import { CvcInputProps } from './model';
import { CVC_INPUT_MASK, cvcInputMaskTokens } from './const';

export * from './const';
export * from './model';

export const CvcInput = React.forwardRef<HTMLInputElement, CvcInputProps>(({ inputSx, ...props }, ref) => {
  const innerRef = React.useRef<HTMLInputElement>(null);
  const fieldRef = useForkRef(innerRef, ref);

  useInputMask(innerRef, CVC_INPUT_MASK, cvcInputMaskTokens);

  return (
    <TextInput
      autoComplete="cc-cvc"
      htmlType="text"
      inputMode="numeric"
      inputSx={inputSx}
      placeholder="‒ ‒ ‒"
      size="normal"
      {...props}
      ref={fieldRef}
    />
  );
});
