/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ClassLikeDeclaration } from 'typescript';
import AbortController from 'abort-controller';
import { SerializedError, isPlainObject } from '@reduxjs/toolkit';
import type { BaseQueryApi, BaseQueryFn } from '@reduxjs/toolkit/src/query/baseQueryTypes';
import type { MaybePromise, Override } from '@reduxjs/toolkit/src/query/tsHelpers';
import { isNodeEnv } from '@cp/ds';
import { HttpStatusCode } from '../../const/httpStatusCode';
import { joinUrls } from './utils';
import { isJsonifiable } from './isJsonifiable';
import { ApiServerError } from './model';

interface Headers {
  [key: string]: string;
}

export type ResponseHandler = 'json' | 'text' | ((response: Response) => Promise<any>);

type CustomRequestInit = Override<
  RequestInit,
  {
    headers?: Headers | string[][] | Record<string, string | undefined> | undefined;
  }
>;

export interface FetchArgs extends CustomRequestInit {
  url: string;
  params?: Record<string, any>;
  body?: any;
  responseHandler?: ResponseHandler;
  validateStatus?: (response: Response, body: any) => boolean;
}

/**
 * A mini-wrapper that passes arguments straight through to
 * {@link [fetch](https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API)}.
 * Avoids storing `fetch` in a closure, in order to permit mocking/monkey-patching.
 */
const defaultFetchFn: typeof fetch = (input: RequestInfo | URL, init?: RequestInit) => fetch(input, init);

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const defaultValidateStatus = (response: Response) => response.status >= 200 && response.status <= 299;

const isJsonContentType = (headers: Headers) => headers['content-type']?.trim()?.startsWith('application/json');

const handleResponse = async (response: Response, responseHandler: ResponseHandler) => {
  if (typeof responseHandler === 'function') {
    return responseHandler(response);
  }

  if (responseHandler === 'text') {
    return response.text();
  }

  if (responseHandler === 'json') {
    const text = await response.text();
    return text.length ? JSON.parse(text) : null;
  }
};

const stripUndefined = (obj: any) => {
  if (!isPlainObject(obj)) {
    return obj;
  }
  const copy: Record<string, any> = { ...obj };
  for (const [k, v] of Object.entries(copy)) {
    if (typeof v === 'undefined') {
      delete copy[k];
    }
  }
  return copy;
};

export type FetchBaseQueryArgs<S = unknown> = {
  baseUrl?: string;
  prepareHeaders?: (headers: Headers, api: Pick<BaseQueryApi, 'getState' | 'endpoint' | 'type' | 'forced'>) => MaybePromise<Headers>;
  prepareRequest?: (payload: {
    body: FetchArgs['body'];
    headers: Record<string, string>;
    params: FetchArgs['params'];
    api: Pick<BaseQueryApi, 'endpoint' | 'type' | 'forced'> & { getState: () => S };
  }) => MaybePromise<{ body: FetchArgs['body']; params: FetchArgs['params'] }>;
  pickStatus?: (response: Response, body: any) => number;
  validateStatus?: (response: Response, body: any) => boolean;
  handleError?: (payload: {
    status: HttpStatusCode;
    response: Response;
    body: any;
    api: Pick<BaseQueryApi, 'endpoint' | 'type' | 'forced' | 'dispatch'> & { getState: () => S };
  }) => boolean;
  fetchFn?: (input: RequestInfo, init?: RequestInit | undefined) => Promise<Response>;
  paramsSerializer?: (params: Record<string, any>) => string;
} & RequestInit;

export type FetchBaseQueryMeta = { request: Request; response?: Response };

/**
 * This is a very small wrapper around fetch that aims to simplify requests.
 *
 * @example
 * ```ts
 * const baseQuery = fetchBaseQuery({
 *   baseUrl: 'https://api.your-really-great-app.com/v1/',
 *   prepareHeaders: (headers, { getState }) => {
 *     const token = (getState() as RootState).auth.token;
 *     // If we have a token set in state, let's assume that we should be passing it.
 *     if (token) {
 *       headers.set('authorization', `Bearer ${token}`);
 *     }
 *     return headers;
 *   },
 * })
 * ```
 *
 * @param {string} baseUrl
 * The base URL for an API service.
 * Typically in the format of http://example.com/
 *
 * @param {(headers: Headers, api: { getState: () => unknown }) => Headers} prepareHeaders
 * An optional function that can be used to inject headers on requests.
 * Provides a Headers object, as well as the `getState` function from the
 * redux store. Can be useful for authentication.
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/API/Headers
 *
 * @param {(input: RequestInfo, init?: RequestInit | undefined) => Promise<Response>} fetchFn
 * Accepts a custom `fetch` function if you do not want to use the default on the window.
 * Useful in SSR environments if you need to use a library such as `isomorphic-fetch` or `cross-fetch`
 *
 * @param {(params: Record<string, unknown> => string} paramsSerializer
 * An optional function that can be used to stringify querystring parameters.
 */
export const fetchBaseQuery = <S = unknown>({
  baseUrl,
  prepareHeaders = (x) => x,
  prepareRequest = (x) => x,
  validateStatus = defaultValidateStatus,
  pickStatus = (x) => x.status,
  handleError,
  fetchFn = defaultFetchFn,
  paramsSerializer,
  ...baseFetchOptions
}: FetchBaseQueryArgs<S> = {}): BaseQueryFn<string | FetchArgs, unknown, ApiServerError | SerializedError, {}, FetchBaseQueryMeta> => {
  if (process.env.NODE_ENV === 'development' && typeof fetch === 'undefined' && fetchFn === defaultFetchFn) {
    // eslint-disable-next-line no-console
    console.warn(
      'Warning: `fetch` is not available. Please supply a custom `fetchFn` property to use `fetchBaseQuery` on SSR environments.',
    );
  }

  return async (arg, { signal, getState, endpoint, forced, type, dispatch }) => {
    let meta: FetchBaseQueryMeta | undefined;

    let {
      url,
      method = 'GET' as const,
      headers = {},
      body = undefined,
      params = undefined,
      responseHandler = 'json' as const,
      // validateStatus = defaultValidateStatus,
      ...rest
    } = typeof arg === 'string' ? { url: arg } : arg;

    // Fixing RTK Query error "TypeError: Expected signal to be an instanceof AbortSignal"
    // Error was thrown at `node-fetch`
    if (signal) {
      const signalProto = Object.getPrototypeOf(signal) as ClassLikeDeclaration;
      if (signalProto.constructor.name !== 'AbortSignal') {
        // eslint-disable-next-line no-param-reassign
        signal = new AbortController().signal as typeof signal;
      }
    }

    const config: RequestInit = {
      ...baseFetchOptions,
      method,
      // ToDo: temporary disabled on server side due to unstable error appearing
      // ("TypeError: Expected signal to be an instanceof AbortSignal")
      signal: isNodeEnv() ? undefined : signal,
      body,
      ...rest,
    };

    config.headers = await prepareHeaders(stripUndefined(headers), { getState, endpoint, forced, type });

    const preparedConfigParams = await prepareRequest({
      body,
      headers: config.headers,
      params,
      api: { getState: getState as () => S, endpoint, forced, type },
    });
    body = preparedConfigParams.body;
    params = preparedConfigParams.params;

    // Only set the content-type to json if appropriate. Will not be true for FormData, ArrayBuffer, Blob, etc.
    if (!config.headers['content-type'] && isJsonifiable(body)) {
      (config.headers as unknown as Record<string, string>)['content-type'] = 'application/json';
    }

    if (body && isJsonContentType(config.headers)) {
      config.body = JSON.stringify(body, (key, value) => {
        if (value && value instanceof Date) {
          return value.toISOString();
        }
        return value;
      });
    }

    if (params) {
      const divider = ~url.indexOf('?') ? '&' : '?';
      const query = paramsSerializer ? paramsSerializer(params) : new URLSearchParams(stripUndefined(params));
      url += divider + (query as string);
    }

    url = joinUrls(baseUrl, url);

    const request = new Request(url, config);
    const requestClone = request.clone();
    meta = { request: requestClone };

    let response;
    try {
      response = await fetchFn(url, config);
    } catch (e) {
      const error: SerializedError =
        e instanceof Error
          ? e
          : {
              //
              // * `"FETCH_ERROR"`:
              //    An error that occured during execution of `fetch` or the `fetchFn` callback option
              //
              name: 'FETCH_ERROR',
              message: String(e),
            };
      return { error, meta };
    }
    const responseClone = response.clone();

    meta.response = responseClone;

    let resultData: any;
    try {
      let handleResponseError;
      await Promise.all([
        handleResponse(response, responseHandler).then(
          (r) => (resultData = r),
          (e) => (handleResponseError = e),
        ),
        // see https://github.com/node-fetch/node-fetch/issues/665#issuecomment-538995182
        // we *have* to "use up" both streams at the same time or they will stop running in node-fetch scenarios
        responseClone.text(),
      ]);
      if (handleResponseError) {
        throw handleResponseError;
      }
    } catch (e) {
      const error: SerializedError =
        e instanceof Error
          ? e
          : {
              //
              // * `"PARSING_ERROR"`:
              //    An error happened during parsing.
              //    Most likely a non-JSON-response was returned with the default `responseHandler` "JSON",
              //    or an error occured while executing a custom `responseHandler`.
              //
              name: 'PARSING_ERROR',
              message: String(e),
              code: response.status,
            };
      return {
        error,
        meta,
      };
    }

    if (validateStatus(response, resultData)) {
      return {
        data: resultData,
        meta,
      };
    } else {
      const status = pickStatus(response, resultData);

      const isHandled = handleError?.({
        status,
        response,
        body: resultData,
        api: { getState: getState as () => S, endpoint, forced, type, dispatch },
      });

      const error: ApiServerError = {
        name: 'ServerError',
        message: resultData.message,
        status,
        data: resultData,
        isHandled: Boolean(isHandled),
      };

      return {
        error,
        meta,
      };
    }
  };
};
