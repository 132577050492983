import React from 'react';
import { Stack } from '@cp/ds/src/components/stack';
import { CheckmarkIcon } from '@cp/ds/src/icons';
import { Typography } from '@cp/ds/src/components/typography';
import { Box } from '@cp/ds/src/components/box';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { Link } from '@cp/ds/src/components/link';
import { IHistoryItem, useHistoryItems } from '../../../model';
import { HistoryProps } from './model';

export * from './model';

const CHECKBOX_SIZE = 18;

export const History: React.FC<HistoryProps> = ({ data, sx }) => {
  const theme = useTheme();
  const [isCollapsed, setCollapsed] = React.useState(true);
  const { items, stageToHistoryItem } = useHistoryItems({ data });

  return (
    <Stack direction="column" gap={0.5} sx={sx}>
      {!isCollapsed && (
        <Box>
          <Link onClick={() => setCollapsed(true)} size="small">
            Свернуть
          </Link>
        </Box>
      )}

      {(isCollapsed ? ([stageToHistoryItem[data.stage]].filter(Boolean) as IHistoryItem[]) : items).map(({ label, completedAt }) => (
        <Stack direction="row" key={label} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Box
            sx={{
              borderRadius: '5px',
              border: `1px solid ${theme.palette['gray middle']}`,
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: CHECKBOX_SIZE,
              height: CHECKBOX_SIZE,
              position: 'relative',
              mr: 0.75,
            }}
          >
            {completedAt ? (
              <CheckmarkIcon
                color="brand pink"
                sx={{ fontSize: '18px', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
              />
            ) : null}
          </Box>

          <Typography sx={{ ml: 0, mr: 'auto' }} variant="caption bold">
            {label}
          </Typography>

          {completedAt ? (
            <Typography sx={{ color: 'gray' }} variant="small text">
              {completedAt}
            </Typography>
          ) : null}
        </Stack>
      ))}

      {isCollapsed && (
        <Box>
          <Link component="span" onClick={() => setCollapsed(false)} role="button" size="small">
            Подробнее
          </Link>
        </Box>
      )}
    </Stack>
  );
};
