/* eslint-disable @typescript-eslint/no-magic-numbers */
import { styled } from '../../../../../../theme/styled';
import { RangeInputStateProps } from '../model';

export const RangeInputEdge = styled('div', {
  name: 'RangeInput',
  slot: 'Edge',
})<{ ownerState: RangeInputStateProps }>(({ theme, ownerState }) => {
  const { size } = ownerState;
  const py = size === 'normal' ? 13 : 10;

  return {
    ...theme.typography[size === 'normal' ? 'body bold' : 'caption bold'],
    flex: '0 0 50%',
    paddingTop: py,
    paddingBottom: py,
    paddingLeft: size === 'normal' ? 24 : 16,
  };
});
