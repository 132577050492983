import React from 'react';
import { SvgIcon } from '../../../components/svgIcon';
import { SvgIconProps } from '../../../components/svgIcon/model';

export const ZooNannyIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="career/colored/zooNanny" ref={ref as any} {...props}>
      <path
        d="M11.5 20.1667L11.3279 20.0432C7.62868 17.4506 6.625 16.537 6.625 15.0556C6.625 13.821 7.77206 12.8333 9.20588 12.8333C10.3816 12.8333 11.0412 13.4012 11.5 13.8457C11.9588 13.4012 12.6184 12.8333 13.7941 12.8333C15.2279 12.8333 16.375 13.821 16.375 15.0556C16.375 16.537 15.3713 17.4506 11.6721 20.0432L11.5 20.1667Z"
        fill="#FFCEC3"
      />
      <path
        clipRule="evenodd"
        d="M9.90413 8.86067C10.4773 8.61204 10.9878 7.5895 10.4239 6.28958C9.86007 4.98967 8.76467 4.66368 8.19148 4.91231C7.6183 5.16094 7.10783 6.18348 7.67169 7.4834C8.23554 8.78331 9.33094 9.1093 9.90413 8.86067ZM10.3021 9.77808C11.5688 9.22864 12.034 7.48863 11.3413 5.89164C10.6486 4.29466 9.06023 3.44546 7.79355 3.9949C6.52686 4.54434 6.06157 6.28436 6.75428 7.88134C7.44699 9.47832 9.03539 10.3275 10.3021 9.77808Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M14.1505 8.82728C14.7417 9.02933 15.8076 8.61689 16.2658 7.27609C16.724 5.93529 16.1335 4.95679 15.5423 4.75474C14.9511 4.55269 13.8852 4.96512 13.427 6.30592C12.9688 7.64673 13.5593 8.62523 14.1505 8.82728ZM13.8271 9.77354C15.1336 10.22 16.6491 9.24669 17.2121 7.59948C17.775 5.95227 17.1722 4.25498 15.8657 3.80847C14.5592 3.36197 13.0437 4.33533 12.4807 5.98254C11.9178 7.62974 12.5206 9.32704 13.8271 9.77354Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M18.0804 11.692C18.4618 11.8661 19.3242 11.6828 19.8049 10.6295C20.2856 9.57631 19.8589 8.80468 19.4775 8.63064C19.0962 8.4566 18.2337 8.63989 17.753 9.69313C17.2723 10.7464 17.699 11.518 18.0804 11.692ZM17.6652 12.6018C18.7343 13.0897 20.0996 12.3925 20.7146 11.0447C21.3297 9.6969 20.9617 8.20877 19.8927 7.7209C18.8236 7.23302 17.4584 7.93014 16.8433 9.27796C16.2282 10.6258 16.5962 12.1139 17.6652 12.6018Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M5.82267 11.6834C6.17811 11.4612 6.50064 10.6406 5.88687 9.65892C5.27309 8.67727 4.39408 8.6079 4.03864 8.83014C3.6832 9.05237 3.36067 9.87302 3.97444 10.8547C4.58822 11.8363 5.46723 11.9057 5.82267 11.6834ZM6.35282 12.5313C7.34921 11.9084 7.52021 10.385 6.73477 9.12877C5.94933 7.87256 4.50487 7.35924 3.50849 7.98223C2.5121 8.60522 2.3411 10.1286 3.12654 11.3848C3.91198 12.641 5.35644 13.1543 6.35282 12.5313Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M11.8 21C11.7418 21 11.6835 20.9871 11.63 20.9612C11.5761 20.935 10.2957 20.3102 8.99669 19.2619C8.22543 18.6395 7.60873 17.9962 7.1638 17.3498C6.59062 16.5172 6.29999 15.6752 6.29999 14.8472C6.29999 13.2773 7.61888 12 9.24002 12C10.3365 12 11.2946 12.5841 11.8 13.4481C12.3054 12.5841 13.2635 12 14.36 12C15.9811 12 17.3 13.2773 17.3 14.8472C17.3 15.6752 17.0094 16.5172 16.4362 17.3498C15.9912 17.9962 15.3745 18.6395 14.6033 19.2619C13.3044 20.3102 12.0238 20.9351 11.97 20.9612C11.9165 20.9871 11.8582 21 11.8 21ZM9.24002 13C8.03806 13 7.31556 13.6832 7.31556 14.8472C7.31556 17.5635 10.9338 19.3607 11.8 19.8174C12.6659 19.3601 16.231 17.5596 16.231 14.8472C16.231 13.6832 15.5619 13 14.36 13C13.158 13 12.5 14 12.1801 14.8472C12.1801 14.8472 12.0099 15.2153 11.8 15.2153C11.5901 15.2153 11.4199 14.8472 11.4199 14.8472C11 14 10.442 13 9.24002 13Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});
