import React, { ReactNode } from 'react';
import { Typography, TypographyProps } from '@cp/ds/src/components/typography';
import { styled } from '@cp/ds/src/theme';
import { useAuthorAge } from '../model';

const Root = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  whiteSpace: 'nowrap',
  '&:not(:empty):not(:first-child)::before': {
    content: '",\u00A0"',
  },
}));

export interface AuthorAgeProps extends TypographyProps {
  age?: number;
  before?: ReactNode;
  after?: ReactNode;
}

export const AuthorAge: React.FC<AuthorAgeProps> = ({ age, before, after, ...props }) => {
  const formattedAge = useAuthorAge(age);
  return (
    <Root variant="small text" {...props}>
      {before}
      {formattedAge}
      {after}
    </Root>
  );
};
