import React from 'react';
import { Button, ButtonProps } from '@cp/ds/src/components/button';
import { ShareIcon } from '@cp/ds/src/icons/share';
import { copyTextToClipboard } from '@cp/utils/browser/clipboard';

export interface CopyButtonProps extends ButtonProps {
  copyText: string;
}

export const CopyButton = ({ copyText, ...props }: CopyButtonProps) => {
  return (
    <Button data-clipboard={copyText} onClick={(e) => copyTextToClipboard(e.currentTarget.dataset.clipboard || '')} {...props}>
      <ShareIcon />
    </Button>
  );
};
