import React from 'react';
import useForkRef from '@mui/utils/useForkRef';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { alpha } from '@mui/material';
// eslint-disable-next-line import/no-duplicates
import { format, isValid, parse } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { ru } from 'date-fns/locale';
import { defaultDateFormatter, defaultDateTimeFormatter } from '@cp/shared/lib';
import { SelectButton } from '../../../../selectButton';
import { useTheme } from '../../../../../hooks/useTheme';
import { mergeSx } from '../../../../../utils/sx/merge';
import { useIsMobile } from '../../../../../hooks/useIsMobile';
import { DateTimePickerProps } from './model';
import { DATE_PICKER_VALUE_DATE_FORMAT_DEFAULT, DATE_TIME_PICKER_VALUE_DATE_FORMAT_DEFAULT } from './const';

export const CALENDAR_WIDTH = 320;

const prepareValue = (_value: string | Date | undefined, valueDateFormat: DateTimePickerProps['valueDateFormat']) => {
  let value = _value
    ? typeof _value === 'string'
      ? typeof valueDateFormat === 'string'
        ? parse(_value, valueDateFormat, new Date())
        : new Date(_value)
      : (_value as unknown) instanceof Date
      ? _value
      : null
    : null;

  value = isValid(value) ? value : null;

  return value;
};

export const DateTimePicker = React.forwardRef<HTMLInputElement, DateTimePickerProps & { type: 'dateTimePicker' }>(
  (
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      type,
      pickerType = 'dateTime',
      name,
      value: _value,
      disabled,
      onChange,
      size,
      sx,
      labelDateFormat = pickerType === 'dateTime' ? defaultDateTimeFormatter : defaultDateFormatter,
      valueDateFormat = pickerType === 'dateTime' ? DATE_TIME_PICKER_VALUE_DATE_FORMAT_DEFAULT : DATE_PICKER_VALUE_DATE_FORMAT_DEFAULT,
      placeholder = 'Pick date...',
      disablePast = true,
      disableFuture = false,
    },
    ref,
  ) => {
    const [innerValue, setInnerValue] = React.useState<Date | null>(prepareValue(_value, valueDateFormat));
    const theme = useTheme();
    const isMobile = useIsMobile();
    const innerRef = React.useRef<HTMLInputElement>(null);
    const fieldRef = useForkRef(innerRef, ref);
    const [isOpen, setOpen] = React.useState(false);

    React.useEffect(() => {
      setInnerValue(prepareValue(_value, valueDateFormat));
    }, [_value, valueDateFormat]);

    const Picker = pickerType === 'dateTime' ? MuiDateTimePicker : MuiDatePicker;

    return (
      <LocalizationProvider adapterLocale={ru} dateAdapter={AdapterDateFns}>
        <Picker
          PaperProps={{
            sx: {
              borderRadius: `${theme.borderRadius.normal}px`,
              backgroundColor: 'white',
              // eslint-disable-next-line @typescript-eslint/no-magic-numbers
              boxShadow: `0px 4px 16px ${alpha(theme.palette.black as string, 0.13)}`,
            },
          }}
          PopperProps={{
            anchorEl: () => innerRef.current as HTMLDivElement,
          }}
          disableFuture={disableFuture}
          disablePast={disablePast}
          label="Basic date picker"
          onAccept={(newValue: Date | null) => {
            setOpen(false);
            onChange?.(newValue && newValue instanceof Date ? (valueDateFormat ? format(newValue, valueDateFormat) : newValue) : undefined);
          }}
          onChange={setInnerValue}
          // onChange={(event: Date | null) =>
          //   onChange?.(event && event instanceof Date ? (valueDateFormat ? format(event, valueDateFormat) : event) : undefined)
          // }
          onClose={() => setOpen(false)}
          open={isOpen}
          renderInput={() => {
            return (
              <SelectButton
                active={isOpen}
                disabled={disabled}
                inputName={name}
                inputValue={String(_value)}
                onClick={() => setOpen((prev) => !prev)}
                ref={fieldRef}
                size={size}
                sx={mergeSx({ width: isMobile ? 1 : CALENDAR_WIDTH, maxWidth: isMobile ? 1 : CALENDAR_WIDTH }, sx)}
              >
                {!innerValue
                  ? placeholder
                  : typeof labelDateFormat === 'function'
                  ? labelDateFormat(innerValue)
                  : format(innerValue, labelDateFormat, { locale: ru })}
              </SelectButton>
            );
          }}
          value={innerValue}
        />
      </LocalizationProvider>
    );
  },
);
