import { ApiProperty } from '@nestjs/swagger';
import { IsEnum, IsNumber, IsOptional } from 'class-validator';
import { Type } from 'class-transformer';
import type { ApiServerEntityIDType } from '../../../types';

export enum EntityType {
  CatalogItem = 'catalogItem',
  ClientInteraction = 'interaction',
}

export class MarkViewedRequest {
  @IsOptional()
  @Type(() => Number)
  viewerId?: ApiServerEntityIDType;

  @ApiProperty()
  @IsEnum(EntityType)
  type: EntityType;

  @ApiProperty()
  @Type(() => Number)
  @IsNumber()
  entityId: number;
}
