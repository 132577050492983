import React from 'react';
import Dialog from '@mui/material/Dialog';
import { useTheme } from '../../hooks/useTheme';
import { Box } from '../box';
import { mergeSx } from '../../utils/sx/merge';
import { TRANSITION_DURATION, Z_INDEX } from './const';
import { ModalViewBaseProps } from './model';

export const DialogView: React.FC<ModalViewBaseProps> = ({ children, onClose, isOpen, sx }) => {
  const theme = useTheme();
  return (
    <Dialog
      PaperComponent={Box}
      PaperProps={{
        sx: mergeSx(
          {
            m: 0,
            maxHeight: '100%',
            width: '600px',
            borderRadius: `${theme.borderRadius.large}px`,
            backgroundColor: theme.palette.white,
          },
          sx,
        ),
      }}
      TransitionProps={{ onExited: onClose }}
      disableScrollLock
      onClose={onClose}
      open={isOpen}
      sx={{ zIndex: Z_INDEX }}
      transitionDuration={TRANSITION_DURATION}
    >
      {children}
    </Dialog>
  );
};

export default DialogView;
