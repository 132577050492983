import React from 'react';
import { SvgIcon, SvgIconProps } from '../../../components/svgIcon';

export const VkIconRound = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon /* cacheName="social/round/vkRound" */ ref={ref as any} {...props}>
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
        fill={props.color ?? '#4C6C91'}
      />
      <path
        d="M12.6272 15.9381C12.6272 15.9381 12.8582 15.9129 12.9765 15.7881C13.0848 15.6738 13.081 15.4581 13.081 15.4581C13.081 15.4581 13.0666 14.4509 13.5431 14.3022C14.0126 14.156 14.6155 15.2762 15.2553 15.707C15.7386 16.0327 16.1055 15.9614 16.1055 15.9614L17.8152 15.9381C17.8152 15.9381 18.7092 15.884 18.2854 15.1939C18.2503 15.1374 18.0381 14.6832 17.0145 13.7504C15.9421 12.7739 16.0861 12.9318 17.377 11.2425C18.1633 10.2138 18.4776 9.58572 18.3793 9.31717C18.286 9.0603 17.7075 9.12851 17.7075 9.12851L15.7831 9.14019C15.7831 9.14019 15.6403 9.12114 15.5345 9.18321C15.4312 9.24405 15.3642 9.386 15.3642 9.386C15.3642 9.386 15.06 10.1818 14.6537 10.859C13.7966 12.2872 13.4542 12.3628 13.3139 12.2743C12.9878 12.0672 13.0691 11.4434 13.0691 11.0004C13.0691 9.61583 13.2833 9.03879 12.6528 8.88946C12.4437 8.83969 12.2897 8.80712 11.7544 8.80159C11.0677 8.79483 10.4867 8.80404 10.1574 8.96198C9.93828 9.06706 9.76925 9.30181 9.87254 9.31533C9.99963 9.33192 10.2876 9.39153 10.4404 9.59556C10.6376 9.85919 10.6307 10.4504 10.6307 10.4504C10.6307 10.4504 10.744 12.0801 10.3659 12.2823C10.1067 12.4211 9.75109 12.1379 8.98669 10.8418C8.5954 10.1781 8.29991 9.44438 8.29991 9.44438C8.29991 9.44438 8.24294 9.30734 8.14089 9.2336C8.01756 9.14449 7.8454 9.11684 7.8454 9.11684L6.01671 9.12851C6.01671 9.12851 5.74188 9.13589 5.64108 9.25326C5.55156 9.35712 5.6342 9.57282 5.6342 9.57282C5.6342 9.57282 7.06597 12.8612 8.68743 14.5185C10.1743 16.0376 11.8621 15.9381 11.8621 15.9381H12.6272Z"
        fill="white"
      />
    </SvgIcon>
  );
});
