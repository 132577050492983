import React from 'react';
import matchAll from 'string.prototype.matchall';
import { useTheme } from '../../hooks/useTheme';
import { MessageContentProps, TemplateProps } from './model';
import { messageContentTemplates } from './templates';
export type { MessageContentProps, TemplateProps } from './model';

export const MessageContent: React.FC<MessageContentProps> = React.memo(
  ({ content: _content, onOpenModal, textOnly }) => {
    const theme = useTheme();
    // eslint-disable-next-line prettier/prettier
    const content = _content.replace(/\n/g, '{{\"type\":\"br\"}}');

    const result: ReturnType<React.FC>[] = [];
    let lastProcessedIndex = 0;
    const regexp = /{{.*?}}/g;
    const matches = Array.from(matchAll(content, regexp));

    const lengthMatches = matches.length;

    if (!lengthMatches) {
      return <span>{content}</span>;
    }

    matches.forEach((match, index) => {
      //------------------------------
      // match contains:
      //------------------------------
      // 0     - found string
      // index - index of first char
      //------------------------------
      const templatePropsSerialized = match['0'].substring(1, match['0'].length - 1);

      let templateProps: TemplateProps | undefined;
      try {
        templateProps = JSON.parse(templatePropsSerialized);
      } catch (e) {
        console.warn(e);
        templateProps = {
          type: 'none',
          text: '',
        };
      }

      result.push(<React.Fragment>{content.substring(lastProcessedIndex, match.index)}</React.Fragment>);
      if (templateProps) {
        if (textOnly && templateProps.type === 'link') {
          // eslint-disable-next-line react/jsx-no-useless-fragment
          result.push(<React.Fragment>{templateProps.text}</React.Fragment>);
        } else {
          const template = messageContentTemplates[templateProps.type];
          if (template) {
            result.push(template.render({ templateProps, theme, onOpenModal }));
          }
        }
      }

      lastProcessedIndex = (match.index ?? 0) + match['0'].length;

      if (index === lengthMatches - 1) {
        result.push(<>{content.substring(lastProcessedIndex)}</>);
      }
    });

    return (
      <>
        {result.map((item, index) => (
          <React.Fragment key={index}>{item}</React.Fragment>
        ))}
      </>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.content === nextProps.content && prevProps.textOnly === nextProps.textOnly;
  },
);
