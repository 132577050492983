import { ClickAwayListener } from '@mui/material';
import React from 'react';
import { FieldPath, FieldValues, useController, useFormContext } from 'react-hook-form';
import get from 'lodash/get';
import { getEventValue } from '@cp/utils/form/getEventValue';
import { Box } from '../../../box';
import { Tooltip } from '../../../tooltip';
import { mergeSx } from '../../../../utils/sx/merge';
import { FormFieldDictionaryItem, formFieldDictionary } from './dictionary';
import { FormFieldProps } from './model';
import { FieldLabel } from './base/components/label';

const defaultTransform = (v: unknown) => v;

export const FormField = React.forwardRef<HTMLInputElement | HTMLTextAreaElement, FormFieldProps<string>>(
  (
    {
      type,
      name,
      hideErrorMessage,
      fieldContainerProps: _fieldContainerProps,
      fieldLabel,
      transform: { in: transformIn = defaultTransform, out: transformOut = defaultTransform } = {},
      ...restProps
    },
    ref,
  ): React.ReactElement => {
    const fieldRef = React.useRef<HTMLInputElement>(null);
    const { control, formState, clearErrors } = useFormContext();
    const { field } = useController({ name, control });

    const pickedError = get(formState.errors, name);
    const errorMessage = pickedError?.message ? String(pickedError?.message) : undefined;
    const hasError = Boolean(errorMessage);

    React.useEffect(() => {
      const newErrorVisibleState = Boolean(errorMessage);

      if (newErrorVisibleState && fieldRef.current) {
        fieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, [errorMessage]);

    const { component: FieldComponent } = formFieldDictionary[type] as FormFieldDictionaryItem<string, typeof type>;

    const handleErrorTooltipClose = () => {
      clearErrors(name);
    };

    const fieldContainerProps = {
      ..._fieldContainerProps,
      sx: mergeSx(
        {
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
        },
        _fieldContainerProps?.sx,
      ),
    };

    const { value, onChange, ref: fieldControllerRef, ...restFieldProps } = field;

    return (
      <Tooltip
        arrow
        colorSchema="error"
        disableFocusListener
        disableHoverListener
        disableTouchListener
        onClose={handleErrorTooltipClose}
        open={hasError && !hideErrorMessage}
        placement="bottom-start"
        title={
          <ClickAwayListener onClickAway={handleErrorTooltipClose}>
            <Box component="span" sx={{ p: 1, display: 'block' }}>
              {errorMessage ?? ''}
            </Box>
          </ClickAwayListener>
        }
      >
        <Box ref={fieldRef} {...fieldContainerProps}>
          {fieldLabel && <FieldLabel>{fieldLabel}</FieldLabel>}
          <FieldComponent
            onChange={(e: React.ChangeEvent) => {
              const v = getEventValue(e);
              onChange(transformIn(v));
            }}
            value={transformOut(value)}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(restFieldProps as any)}
            disabled={formState.isSubmitting}
            error={hasError && !hideErrorMessage}
            ref={(el: HTMLInputElement) => {
              fieldControllerRef(el);

              if (!ref) {
                return;
              }

              if (typeof ref === 'function') {
                ref(el);
              } else {
                ref.current = el;
              }
            }}
            type={type}
            {...(restProps as unknown as {})}
          />
        </Box>
      </Tooltip>
    );
  },
) as <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
  props: FormFieldProps<TName> & { ref?: React.Ref<HTMLInputElement | HTMLTextAreaElement> },
) => React.ReactElement;
