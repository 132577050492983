import styled from '@mui/material/styles/styled';
import { AUTOCOMPLETE_LIST_ITEM_HORIZONTAL_SPACING, AUTOCOMPLETE_LIST_ITEM_VERTICAL_SPACING } from './../const';

export const AutocompleteItem = styled('li', {
  name: 'Autocomplete',
  slot: 'Item',
})(({ theme }) => {
  return {
    cursor: 'pointer',
    transition: theme.transitions.create(['background-color', 'color'], {
      duration: theme.transitions.duration.short,
    }),
    color: theme.palette.gray,
    padding: theme.spacing(AUTOCOMPLETE_LIST_ITEM_VERTICAL_SPACING, AUTOCOMPLETE_LIST_ITEM_HORIZONTAL_SPACING),
    '&:hover': {
      color: theme.palette['brand gray'],
      backgroundColor: theme.palette.background.paper,
    },
  };
});
