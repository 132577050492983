import { styled } from '../../../../../../theme/styled';
import { TextInputStateProps } from '../model';

type UnstyledDivComponent = React.ComponentClass<React.ComponentProps<'div'> & { ownerState: Required<TextInputStateProps> }>;

export const TextInputRoot = styled('div' as unknown as UnstyledDivComponent, {
  name: 'TextInput',
  slot: 'Root',
})(({ theme, ownerState }) => {
  const { error, disabled, focused, size, startAdornment, endAdornment } = ownerState;

  const px = { 'small': 13, 'normal': 24 }[size];

  const borderColor = error ? theme.palette['brand pink'] : theme.palette['gray light'];
  const borderRadius = theme.borderRadius.normal;

  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingRight: endAdornment ? 12 : px,
    paddingLeft: startAdornment ? 12 : px,
    border: `1px solid ${borderColor}`,
    borderRadius: `${borderRadius}px`,
    backgroundColor: 'white',
    '&:hover': {
      ...(!error &&
        !disabled && {
          borderColor: theme.palette.gray,
        }),
    },
    ...(focused
      ? {
          outline: 'none',
          ...(!error &&
            !disabled && {
              borderColor: theme.palette['brand gray'],
            }),
        }
      : undefined),
  };
});
