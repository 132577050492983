import React, { useCallback, useEffect } from 'react';
import { useCurrentUser } from '@cp/entities/currentUser';
import { currentDialogModel, dialogModel } from '@cp/entities/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { DialogViewerProps } from 'src/features/dialog/view';
import { DialogDrawer } from './DialogDrawer';

export const DialogViewer = ({ dialogId, onClose }: DialogViewerProps) => {
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();
  const currentDialogId = useSelector(currentDialogModel.selectors.currentDialogIdSelector);

  useEffect(() => {
    if (dialogId && currentDialogId !== dialogId) {
      dispatch(dialogModel.actions.switchToId({ dialogId, currentUser }));
      return;
    }
    if (currentDialogId && !dialogId) {
      dispatch(dialogModel.actions.reset());
      return;
    }
  }, [dialogId, currentDialogId, dispatch, currentUser]);

  const handleDialogClose = useCallback(() => {
    dispatch(dialogModel.actions.reset());
    onClose?.();
  }, [onClose, dispatch]);

  return <DialogDrawer handleDialogClose={handleDialogClose} />;
};
