import React from 'react';
import { EntityId } from '@reduxjs/toolkit';
import { DialogMessageText, DialogStateShape, currentDialogModel } from '@cp/entities/dialog';
import { MessageType } from '@common/model/dialog/message/messageType';
import { DialogMessageViewModel } from '@common/model/dialog/message/dialogMessageViewModel';
import { useSelector } from 'react-redux';

export interface DialogMessageProps {
  messageId: EntityId;
}
export const DialogMessage: React.FC<DialogMessageProps> = ({ messageId }) => {
  const message = useSelector<DialogStateShape, DialogMessageViewModel | undefined>((state) =>
    currentDialogModel.selectors.messageSelector(state, messageId),
  );
  if (!message) {
    return null;
  }
  switch (message.type) {
    case MessageType.Text:
      return <DialogMessageText message={message as DialogMessageViewModel<MessageType.Text>} />;
    default:
      return <DialogMessageText message={{ ...message, type: MessageType.Text, payload: 'Сообщение повреждено' }} />;
  }
};
