import React from 'react';
import { Button, ButtonProps } from '@cp/ds/src/components/button';
import { useVacancyDelete } from '../model';

export interface DeleteButtonProps extends ButtonProps {
  itemId: number;
}
export const DeleteButton = ({ itemId, ...props }: DeleteButtonProps) => {
  const [trigger, { isLoading }] = useVacancyDelete();
  return (
    <Button color="white" disabled={isLoading} onClick={() => trigger(itemId)} {...props}>
      Удалить
    </Button>
  );
};
