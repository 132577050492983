import { DependencyToken } from '@cp/shared/di';
import { Container } from './container.interface';

export class DiContainer implements Container {
  #storage: Map<DependencyToken, unknown>;

  constructor() {
    this.#storage = new Map();
  }

  bind<T>(token: DependencyToken<T>, v: T): void {
    this.#storage.set(token, v);
  }

  get<T>(token: DependencyToken<T>): T {
    if (!this.#storage.has(token)) {
      throw new Error(`${String(token.id)} not found in container`);
    }
    return this.#storage.get(token) as T;
  }
}
