import MuiSlider from '@mui/material/Slider';
import { styled } from '../../../../../../theme/styled';

export const Slider = styled(MuiSlider, {
  name: 'RangeInput',
  slot: 'Root',
})(({ theme }) => {
  return {
    '&.MuiSlider-root': {
      color: theme.palette['brand pink'],
      height: 2,
      width: 'calc(100% + 4px)',
      padding: '0',
      position: 'absolute',
      left: -2,
      right: -2,
      bottom: 0,
    },
    '& .MuiSlider-thumb': {
      height: 16,
      width: 16,
      backgroundColor: theme.palette['brand pink'],
      border: `2px solid ${theme.palette.white}`,
      transition: 'background .3s ease-out',
      '& .Mui-disabled': {
        height: 16,
        width: 16,
        backgroundColor: theme.palette['gray light'],
        marginTop: -8,
        marginLeft: -8,
        border: `2px solid ${theme.palette.white}`,
      },
    },
    '& .MuiSlider-track': {
      height: 2,
      borderWidth: 0,
    },
    '& .MuiSlider-rail': {
      height: 2,
      opacity: 1,
      backgroundColor: theme.palette['gray light'],
    },
    '& .MuiSlider-mark': {
      backgroundColor: theme.palette.white,
      height: 2,
      width: 2,
      borderRadius: 'unset',
    },
    '& .MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: theme.palette.white,
    },
  };
});
