import React from 'react';
import { declination } from '@cp/ds';
import { Declination } from '@cp/shared/declination';
import { coreCareerApi } from '../api';

export const useCareerDeclination = () => {
  const { data: careerDictionary } = coreCareerApi.endpoints.getCareers.useQuery();

  return React.useCallback(
    (number: number, careerId: number, declinationName: Declination = Declination.Nominative): string => {
      if (!careerDictionary) {
        return '';
      }

      const { translation } = careerDictionary[careerId];
      if (number !== 1) {
        switch (declinationName) {
          case Declination.Genitive:
            return translation.namePluralGenitive;
          case Declination.Prepositional:
            return translation.namePluralPrepositional;
          default:
            return declination(number, [translation.nameDeclination1, translation.nameDeclination2, translation.nameDeclination3]);
        }
      }

      switch (declinationName) {
        case Declination.Nominative:
          return translation.nameSingle;
        case Declination.Accusative:
          return translation.nameSingleAccusative;
        case Declination.Dative:
          return translation.nameSingleDative;
        case Declination.Genitive:
          return translation.nameSingleGenitive;
        case Declination.Instrumental:
          return translation.nameSingleInstrumental;
        case Declination.Prepositional:
          return translation.nameSinglePrepositional;
        default:
          return translation.nameSingle;
      }
    },
    [careerDictionary],
  );
};
