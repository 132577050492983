import { InputMaskTokenDictionary } from './../../../../../hooks/useInputMask/model';

export const PHONE_INPUT_MASK = '+7 (F##) ###-##-##';

export const phoneInputMaskTokens: InputMaskTokenDictionary = {
  F: {
    pattern: /(?!8)\d/,
  },
  '#': {
    pattern: /\d/,
  },
  '*': {
    pattern: /./,
  },
};
