import React from 'react';
import { SvgIcon, SvgIconProps } from '../../components/svgIcon';

export const PaymentVisaIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    <SvgIcon /* cacheName="visa" */ ref={ref} {...props}>
      <path
        d="M10.4 15.392H8.456l1.216-7.26h1.944l-1.216 7.26ZM17.448 8.31A4.941 4.941 0 0 0 15.704 8c-1.92 0-3.272.989-3.28 2.402-.016 1.043.968 1.622 1.704 1.97.752.355 1.008.587 1.008.904-.008.486-.608.71-1.168.71-.776 0-1.192-.115-1.824-.386l-.256-.116-.272 1.63c.456.2 1.296.378 2.168.386 2.04 0 3.368-.973 3.384-2.48.008-.826-.512-1.46-1.632-1.977-.68-.332-1.097-.556-1.097-.896.008-.309.353-.625 1.12-.625a3.403 3.403 0 0 1 1.448.278l.176.077.265-1.568ZM20.032 12.82l.776-2.032c-.008.016.16-.424.256-.695l.136.626s.368 1.738.448 2.1h-1.616Zm2.4-4.688h-1.504c-.464 0-.816.13-1.016.602l-2.888 6.658h2.04l.408-1.09h2.496c.056.255.232 1.09.232 1.09H24l-1.568-7.26ZM6.832 8.132l-1.904 4.95-.208-1.004c-.352-1.158-1.456-2.417-2.688-3.043l1.744 6.35h2.056l3.056-7.253H6.832Z"
        fill="#00579F"
      />
      <path d="M3.16 8.132H.032L0 8.278c2.44.603 4.056 2.055 4.72 3.8l-.68-3.336c-.112-.464-.456-.595-.88-.61Z" fill="#FAA61A" />
    </SvgIcon>
  );
});
