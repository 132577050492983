import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const ReadIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="read" ref={ref as any} {...props}>
      <path
        clipRule="evenodd"
        d="M16.3579 5.68726C16.5721 5.86152 16.6045 6.17644 16.4303 6.39065L6.46695 18.6389C6.29283 18.853 5.97821 18.8855 5.76397 18.7116L1.91583 15.5881C1.70143 15.4141 1.6687 15.0992 1.84273 14.8848C2.01676 14.6704 2.33164 14.6376 2.54605 14.8117L6.00638 17.6204L15.6545 5.75962C15.8288 5.5454 16.1437 5.513 16.3579 5.68726Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M21.552 6.11785C21.7662 6.29211 21.7986 6.60703 21.6243 6.82124L11.661 19.0695C11.4868 19.2837 11.1718 19.3161 10.9576 19.1419C10.7434 18.9676 10.711 18.6527 10.8853 18.4385L20.8486 6.19021C21.0228 5.97599 21.3377 5.94359 21.552 6.11785Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
});
