import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const RubSolidIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="rub-solid" ref={ref as any} {...props}>
      <circle cx="12" cy="12" fill="currentColor" r="9" />
      <path
        d="M10.8644 8.0166H12.5409C14.0303 8.0166 14.828 8.78501 14.828 10.21C14.828 11.6515 14.0386 12.3081 12.2561 12.3081H10.8644V8.0166ZM10.8644 17.4075V15.9504H13.0705C13.4038 15.9504 13.6076 15.7745 13.6076 15.4786C13.6076 15.1738 13.4121 15.0061 13.0697 15.0061H10.8652V13.309H12.5735C14.6735 13.309 16 12.0919 16 10.1787C16 8.25659 14.6568 7 12.6061 7H10.8727C10.0833 7 9.72576 7.32048 9.72576 8.04045V12.3081H8.57803C8.21212 12.3081 8 12.4922 8 12.8045C8 13.1249 8.21212 13.309 8.57803 13.309H9.72576V15.0061H8.54545C8.19545 15.0061 8 15.1738 8 15.4786C8 15.7745 8.19545 15.9504 8.54545 15.9504H9.72576V17.4082C9.72576 17.7839 9.93712 18 10.2955 18C10.653 18 10.8644 17.7764 10.8644 17.4082V17.4075Z"
        fill="white"
      />
    </SvgIcon>
  );
});
