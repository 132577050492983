import React, { ReactNode } from 'react';
import { AdminClientProfileViewModel } from '@common/model/client/profile/adminClientProfileViewModel';
import { Typography } from '@cp/ds/src/components/typography';
import { TypographyProps } from '@cp/ds/src/components/typography/model';
import { getClientName } from '../../lib';

export interface ClientNameProps extends TypographyProps {
  client: AdminClientProfileViewModel;
  generateName?: (client: AdminClientProfileViewModel) => ReactNode;
}

export const ClientName = ({ client, generateName = getClientName, ...props }: ClientNameProps) => (
  <Typography {...props}>{generateName(client)}</Typography>
);
