import React from 'react';
import useForkRef from '@mui/utils/useForkRef';
import { TextInput } from '../text';
import { useInputMask } from '../../../../../hooks/useInputMask';
import { SmsCodeInputProps } from './model';
import { SMS_CODE_INPUT_MASK, smsCodeInputMaskTokens } from './const';

export * from './const';
export * from './model';

export const SmsCodeInput = React.forwardRef<HTMLInputElement, SmsCodeInputProps>(({ inputSx, ...props }, ref) => {
  const innerRef = React.useRef<HTMLInputElement>(null);
  const fieldRef = useForkRef(innerRef, ref);

  useInputMask(innerRef, SMS_CODE_INPUT_MASK, smsCodeInputMaskTokens);

  return (
    <TextInput
      autoComplete="one-time-code"
      htmlType="text"
      inputMode="numeric"
      inputSx={{ letterSpacing: 6, textAlign: 'center', ...inputSx }}
      placeholder="____"
      {...props}
      ref={fieldRef}
    />
  );
});
