import styled from '@mui/material/styles/styled';
import { Box } from '../../../../../box';
import { TextAreaStateProps } from '../model';

export const StyledTextAreaContainer = styled(Box, {
  name: 'TextArea',
  slot: 'Container',
})<{ ownerState: Required<TextAreaStateProps & { focused: boolean }> }>(({ theme, ownerState }) => {
  const { error, focused, size } = ownerState;

  let borderColor = theme.palette['gray light'];
  const borderRadius = { 'small': theme.borderRadius.normal, 'normal': theme.borderRadius.medium }[size];

  if (error) {
    borderColor = theme.palette['brand pink'];
  } else {
    if (focused) {
      borderColor = theme.palette['brand gray'];
    }
  }

  return {
    display: 'flex',
    backgroundColor: 'white',
    width: '100%',
    margin: 0,
    padding: 0,
    border: `1px solid ${borderColor}`,
    borderRadius: `${borderRadius}px`,
    overflow: 'hidden',
    '&:hover': {
      ...(!error &&
        !focused && {
          borderColor: theme.palette.gray,
        }),
    },
  };
});
