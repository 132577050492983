import React from 'react';
import { format } from 'date-fns';
import { Stack } from '@cp/ds/src/components/stack';
import { mergeSx } from '@cp/ds/src/utils/sx/merge';
import { MOBILE_BREAKPOINT_THRESHOLD } from '@cp/ds/src/theme';
import { Typography } from '@cp/ds/src/components/typography';
import { ClientType } from '@common/model/client/clientType';
import { DateFormat } from '@common/const/date';
import { PersonalInformationProps } from './model';
import { NotFilled } from './notFilled';
import { NameAndSurname } from './nameAndSurname';

export * from './model';
export * from './nameAndSurname';

export const PersonalInformation: React.FC<PersonalInformationProps> = ({ clientProfile, disableTopRow, topRightContent, sx }) => {
  if (!clientProfile) {
    return null;
  }

  return (
    <Stack
      direction="column"
      gap={1.5}
      sx={mergeSx(
        {
          overflow: 'hidden',
        },
        sx,
      )}
    >
      {!disableTopRow && (
        <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <NameAndSurname clientProfile={clientProfile} />

          {topRightContent}
        </Stack>
      )}

      {clientProfile.type === ClientType.Specialist && (
        <Typography variant="body">
          <Typography sx={{ color: { xs: 'gray dark', [MOBILE_BREAKPOINT_THRESHOLD]: 'gray' } }} variant="body">
            Дата рождения:
          </Typography>{' '}
          {clientProfile.birthDate ? (
            format(new Date(clientProfile.birthDate), DateFormat.DateDefault)
          ) : (
            <NotFilled>дата рождения не указана</NotFilled>
          )}
        </Typography>
      )}

      {/* <Typography variant="body">{clientProfile.email || <NotFilled>email не указан</NotFilled>}</Typography> */}

      <Typography variant="body">{clientProfile.phone || <NotFilled>номер телефона не указан</NotFilled>}</Typography>

      <Typography variant="body">{clientProfile.city?.translation.name || <NotFilled>город не указан</NotFilled>}</Typography>

      <Typography variant="body">{clientProfile.address || <NotFilled>адрес не указан</NotFilled>}</Typography>
    </Stack>
  );
};
