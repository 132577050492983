export enum MessageType {
  Text = 1,
  DealProposition = 2,
  VacancyResponse = 3,

  // Notification
  NotificationPremiumExpiredInSevenDays = 100,
  NotificationPremiumExpiredInThreeDays = 101,
  NotificationPremiumExpiredInOneDay = 102,
  NotificationPremiumExpired = 103,
  // Client
  NotificationFavorite = 200,
  NotificationUnFavorite = 201,
}
