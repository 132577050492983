import React from 'react';
import { RouteConfigComponentProps, renderRoutes } from 'react-router-config';
import loadable from '@loadable/component';
import { Box } from '@cp/ds/src/components/box';
import { authenticationTokenSelector } from '@cp/shared/api/core';
import { Stack } from '@cp/ds/src/components/stack';
import { useSelector } from 'react-redux';
import { ScrollContainerContext } from 'src/shared/ui/scrollContainer';
import { PageSidebar } from './components/sidebar';
import { PageHeader } from './components/header';
import { HEADER_HEIGHT } from './const';

const Login = loadable(() => import('./components/login'));

export const Layout: React.FC<RouteConfigComponentProps> = ({ route }) => {
  const isAdminAuthenticated = Boolean(useSelector(authenticationTokenSelector));
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);

  const scrollContainerContextValue = React.useMemo(
    () => ({
      ref: scrollContainerRef,
    }),
    [],
  );

  if (!isAdminAuthenticated) {
    return <Login />;
  }

  return (
    <ScrollContainerContext.Provider value={scrollContainerContextValue}>
      <Box>
        <PageHeader />
        <Stack direction="row" sx={{ flexWrap: 'nowrap', position: 'relative', backgroundColor: 'background.paper' }}>
          <PageSidebar />
          <Box
            ref={scrollContainerRef}
            sx={{ position: 'relative', flex: '1 1 0px', pt: 2.5, pb: 5, height: `calc(100vh - ${HEADER_HEIGHT}px)`, overflowX: 'auto' }}
          >
            {renderRoutes(route?.routes)}
          </Box>
        </Stack>
      </Box>
    </ScrollContainerContext.Provider>
  );
};
