import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const CloseThinIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="closeThin" ref={ref as any} {...props}>
      <path
        clipRule="evenodd"
        d="M5.64645 5.64645C5.84171 5.45118 6.15829 5.45118 6.35355 5.64645L11.75 11.0429L17.1464 5.64645C17.3417 5.45118 17.6583 5.45118 17.8536 5.64645C18.0488 5.84171 18.0488 6.15829 17.8536 6.35355L12.4571 11.75L17.8536 17.1464C18.0488 17.3417 18.0488 17.6583 17.8536 17.8536C17.6583 18.0488 17.3417 18.0488 17.1464 17.8536L11.75 12.4571L6.35355 17.8536C6.15829 18.0488 5.84171 18.0488 5.64645 17.8536C5.45118 17.6583 5.45118 17.3417 5.64645 17.1464L11.0429 11.75L5.64645 6.35355C5.45118 6.15829 5.45118 5.84171 5.64645 5.64645Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
});
