export enum DialogEventType {
  MemberOnline = 'online',
  MemberOffline = 'offline',
  MemberJoined = 'joined',
  MemberLeaved = 'leaved',
  DialogCreated = 'dialog',
  NewMessage = 'new-message',
  MessageCreated = 'message-created',
  MessageDelivered = 'message-delivered',
  MessageRead = 'message-read',
  DeliveryReport = 'delivered',
  ReadReport = 'read',
}
