import React from 'react';
import { RubSolidIcon } from '@cp/ds/src/icons/rubSolid';
import { coreSubscriptionsStats } from '../api';
import { StatItem } from './statItem';

export const ActiveSubscriptions = () => {
  const { data, isLoading } = coreSubscriptionsStats.endpoints.getSubscriptionStats.useQuery();
  return (
    <StatItem Icon={RubSolidIcon} iconSxProps={{ color: 'green' }} isLoading={isLoading} title="Подписки" value={data?.active.totalCount} />
  );
};
