import { Grid } from '@cp/ds/src/components/grid';
import { Trademark } from '@cp/ds/src/components/trademark';
import React from 'react';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { useMediaQuery } from '@mui/material';
import { LogoIcon } from '@cp/ds/src/icons/logo';
import { CommonStats } from 'src/widgets/commonStats';
import {
  HEADER_HEIGHT,
  LAYOUT_SIDE_INDENT_DESKTOP,
  LAYOUT_SIDE_INDENT_MOBILE,
  MAIN_CONTENT_WIDTH_RATE_LG_SCREEN,
  SIDEBAR_WIDTH_RATE_LG_SCREEN,
} from '../../const';

export const PageHeader: React.FC = () => {
  const theme = useTheme();
  const isLGScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const isMDScreen = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Grid
      container
      sx={{
        position: 'relative',
        zIndex: 4,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.white,
        height: `${HEADER_HEIGHT}px`,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
      }}
    >
      {isMDScreen && (
        <Grid
          item
          lg={SIDEBAR_WIDTH_RATE_LG_SCREEN}
          md={1}
          sx={{ display: 'flex', alignItems: 'center', pl: { xs: LAYOUT_SIDE_INDENT_MOBILE, md: LAYOUT_SIDE_INDENT_DESKTOP } }}
        >
          {isLGScreen ? <Trademark size={1.7} /> : <LogoIcon fontSize="large" sx={{ color: theme.palette['brand pink'] }} />}
        </Grid>
      )}
      <Grid
        item
        lg={MAIN_CONTENT_WIDTH_RATE_LG_SCREEN}
        md={11}
        sx={{ pr: { xs: LAYOUT_SIDE_INDENT_MOBILE, md: LAYOUT_SIDE_INDENT_DESKTOP } }}
        xs={12}
      >
        <CommonStats />
      </Grid>
    </Grid>
  );
};
