import React from 'react';
import useForkRef from '@mui/utils/useForkRef';
import { TextInput } from '../text';
import { useInputMask } from '../../../../../hooks/useInputMask';
import { PhoneInputProps } from './model';
import { PHONE_INPUT_MASK, phoneInputMaskTokens } from './const';

export * from './const';
export * from './model';

export const PhoneInput = React.forwardRef<HTMLInputElement, PhoneInputProps>((props, ref) => {
  const innerRef = React.useRef<HTMLInputElement>(null);
  const fieldRef = useForkRef(innerRef, ref);

  useInputMask(innerRef, PHONE_INPUT_MASK, phoneInputMaskTokens);

  return <TextInput autoComplete="tel" htmlType="tel" inputMode="numeric" placeholder="+7 (999) 777-12-34" {...props} ref={fieldRef} />;
});
