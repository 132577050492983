import styled from '@mui/material/styles/styled';
import { alpha } from '@mui/system';
import { selectSizeDict } from '../const';
import { DivTagProps, SelectButtonStateProps } from '../model';

type UnstyledDivComponent = React.ComponentClass<DivTagProps & { ownerState: SelectButtonStateProps }>;

export const SelectButtonRoot = styled('div' as unknown as UnstyledDivComponent, {
  name: 'Select',
  slot: 'Button',
})(({ theme, ownerState }) => {
  const { size, value } = ownerState;

  const commonStyles = {
    borderColor: theme.palette['gray light'],
    color: theme.palette['brand gray'],
  };
  const { buttonVerticalSpacing, buttonLeftSpacing, buttonRightSpacing } = selectSizeDict[ownerState.size];

  if (ownerState.disabled) {
    commonStyles.color = theme.palette['gray light'];
  } else if (!value) {
    commonStyles.color = theme.palette.gray;
  }

  if (ownerState.error) {
    commonStyles.borderColor = theme.palette['brand pink'];
  }

  return {
    ...commonStyles,
    ...theme.typography[size === 'small' ? (value ? 'caption bold' : 'caption') : 'body'],
    backgroundColor: theme.palette.white,
    cursor: ownerState.disabled ? 'not-allowed' : 'pointer',
    transition: theme.transitions.create(['background-color', 'border-color', 'color', 'box-shadow'], {
      duration: theme.transitions.duration.short,
    }),
    border: `1px solid ${commonStyles.borderColor}`,
    borderRadius: `${theme.borderRadius.normal}px`,
    ...(ownerState.active && {
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      boxShadow: `0px 4px 16px ${alpha(theme.palette.black as string, 0.13)}`,
    }),
    width: '100%',
    height: '100%',
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${buttonVerticalSpacing}px ${buttonRightSpacing}px ${buttonVerticalSpacing}px ${buttonLeftSpacing}px`,
    // ...(ownerState.open && {
    //   zIndex: 1400,
    //   position: 'relative',
    // }),

    '&:focus, &:active': {
      outline: 'none',
    },

    WebkitTapHighlightColor: 'transparent',

    '@media (pointer: fine)': {
      '&:hover': !ownerState.disabled && {
        borderColor: theme.palette.gray,
      },
    },
  };
});
