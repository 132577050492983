import { PersistConfig } from 'redux-persist';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { coreApi } from '@cp/shared/api/core';
import { createDialogMiddleware } from '@cp/entities/dialog';
import type { IoContainer } from '@cp/shared/di';
import { CONFIG_MANAGER } from 'src/shared/config';
import { NotPersistedRootState, createRootReducer } from './../slices';

interface ConfigureStoreOptions<S> {
  container: IoContainer;
  preloadedState?: S;
  persistConfig: PersistConfig<S>;
}

export const configureAppStore = ({ preloadedState, persistConfig, container }: ConfigureStoreOptions<NotPersistedRootState>) => {
  const configManager = container.get(CONFIG_MANAGER);

  const store = configureStore({
    reducer: createRootReducer(persistConfig),
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      })
        .prepend(createDialogMiddleware({ configManager }))
        .concat(coreApi.middleware),
    devTools: true,
  });

  setupListeners(store.dispatch);

  return store;
};

/**
 * Store typings
 */
export type StoreType = Awaited<ReturnType<typeof configureAppStore>>;

export type RootState = ReturnType<StoreType['getState']>;
