import React from 'react';
import { useHistory } from 'react-router-dom';
import { useUnreadMessagesCount } from '@cp/entities/dialog';
import { MessageIcon } from '@cp/ds/src/icons/message';
import { generateAdminPath } from 'src/app/shared/utils/generateAdminPath';
import { AdminRoutePath } from 'src/app/routes/model';
import { SidebarSectionWithoutItems } from '../../section';

export const MessagesSidebarSection: React.FC = () => {
  const unreadMessagesCount = useUnreadMessagesCount();
  const history = useHistory();
  const url = generateAdminPath(AdminRoutePath.Messages);

  return (
    <SidebarSectionWithoutItems
      badgeValue={unreadMessagesCount}
      href={url}
      icon={MessageIcon}
      onClick={(e) => {
        e.preventDefault();
        history.push(url);
      }}
      sxIcon={{ color: 'gray' }}
      title="Сообщения"
    />
  );
};
