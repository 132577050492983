import React from 'react';
import { Typography } from '@cp/ds/src/components/typography';
import { MOBILE_BREAKPOINT_THRESHOLD } from '@cp/ds/src/theme';
import { mergeSx } from '@cp/ds/src/utils/sx/merge';
import { NotFilled } from '../notFilled';
import { NameAndSurnameProps } from './model';

export * from './model';

export const NameAndSurname: React.FC<NameAndSurnameProps> = ({ clientProfile, asPlainText, sx }) => {
  const clientName = [clientProfile.name, clientProfile.surname].filter(Boolean).join(' ');

  const content = clientName || <NotFilled>Имя и фамилия не указаны</NotFilled>;

  if (asPlainText) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{content}</>;
  }

  return <Typography sx={mergeSx({ typography: { xs: 'button', [MOBILE_BREAKPOINT_THRESHOLD]: 'h2' } }, sx)}>{content}</Typography>;
};
