import loadable from '@loadable/component';
import { RouteConfig } from 'react-router-config';
import { Layout } from 'src/app/shared/components/layout';
import { AdminRoutePath } from './model';

export const routesConfig: RouteConfig[] = [
  {
    exact: true,
    component: Layout,
    routes: [
      {
        path: AdminRoutePath.Home,
        exact: true,
        component: loadable(() => import('../../pages/home')),
      },
      {
        path: AdminRoutePath.ModerationClientPatronage,
        exact: true,
        component: loadable(() => import('../../pages/moderationClientPatronage')),
      },
      {
        path: AdminRoutePath.ServiceCaching,
        exact: true,
        component: loadable(() => import('../../pages/service')),
      },
      {
        path: AdminRoutePath.Messages,
        component: loadable(() => import('../../pages/messages')),
      },
      {
        path: AdminRoutePath.ResponseDelay,
        component: loadable(() => import('../../pages/responseDelay')),
      },
      {
        path: AdminRoutePath.Specialists,
        component: loadable(() => import('../../pages/specialists')),
      },
      {
        path: AdminRoutePath.Employers,
        component: loadable(() => import('../../pages/employers')),
      },
      {
        path: AdminRoutePath.Vacancies,
        component: loadable(() => import('../../pages/vacancies')),
      },
      {
        path: '*',
        exact: true,
        component: loadable(() => import('../../pages/notFound')),
      },
    ],
  },
];
