import { useEffect, useMemo } from 'react';
import { ResponseDelayService } from 'src/features/responseDelay/model/service';
import { useApiProvider } from '../lib';
import { thresholds } from './thresholds';
import { useStorage } from './useStorage';

export const useService = () => {
  const api = useApiProvider();
  const storage = useStorage();
  const service = useMemo(() => {
    return new ResponseDelayService(api, storage, thresholds);
  }, [api, storage]);
  useEffect(() => {
    return () => service.tearDown();
  }, [service]);
  return service;
};
