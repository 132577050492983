import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const PenIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="pen" ref={ref as any} {...props}>
      <path
        d="M15.3759 5.47451L14.4561 6.40286L6.21764 14.6032L5.00002 19L9.40424 17.7106L18.4718 8.68505C18.8104 8.3405 19 7.87767 19 7.39568C19 6.91368 18.8104 6.45085 18.4718 6.10631L17.9148 5.53898C17.5811 5.20541 17.1311 5.0126 16.6583 5.0006C16.1855 4.98859 15.7262 5.15831 15.3759 5.47451V5.47451Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9.50002 17.5L6.50002 14.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.5 6.5L17.5 9.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
});
