import { EntityId, EntityState, PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { ClientInteractionViewModel } from '@common/model/stats/clientInteractionViewModel';
import { Threshold } from './thresholds';

const cardsAdapter = createEntityAdapter<ClientInteractionViewModel>({
  selectId: (m) => m.id,
});

const { selectById } = cardsAdapter.getSelectors();

export type MonitorState = {
  columns: Record<string, number[]>;
  cards: EntityState<ClientInteractionViewModel>;
  baseDate: string;
  pagination: Record<string, { totalCount: number; page: number }>;
};

const initialPagination = { totalCount: 0, page: 1 };

const initialState: MonitorState = {
  columns: {},
  pagination: {},
  cards: cardsAdapter.getInitialState(),
  baseDate: new Date().toISOString(),
};
const reducerPath = 'responseDelay';

export type ReducerStateShape = { [reducerPath]: MonitorState };

export const slice = createSlice({
  name: reducerPath,
  initialState,
  reducers: {
    fill: (state, action: PayloadAction<{ threshold: Threshold; items: ClientInteractionViewModel[] }>) => {
      const key = String(action.payload.threshold.minDuration);
      cardsAdapter.addMany(state.cards, action.payload.items);
      const itemsIds: number[] = state.columns[key] || [];
      for (const item of action.payload.items) {
        if (itemsIds.includes(item.id)) {
          continue;
        }
        itemsIds.push(item.id);
      }
      state.columns[key] = itemsIds;
    },
    add: (state, action: PayloadAction<{ threshold: Threshold; item: ClientInteractionViewModel }>) => {
      const key = String(action.payload.threshold.minDuration);
      if (!state.columns[key]) {
        state.columns[key] = [];
      }
      if (!state.pagination[key]) {
        state.pagination[key] = { ...initialPagination };
      }
      state.columns[key].unshift(action.payload.item.id);
      state.pagination[key].totalCount++;
      cardsAdapter.upsertOne(state.cards, action.payload.item);
    },
    move: (state, action: PayloadAction<{ threshold: Threshold; item: ClientInteractionViewModel }>) => {
      const itemId = action.payload.item.id;
      const targetKey = String(action.payload.threshold.minDuration);
      if (!state.pagination[targetKey]) {
        state.pagination[targetKey] = { ...initialPagination };
      }
      let itemsArray: number[];
      for (const key of Object.keys(state.columns)) {
        itemsArray = state.columns[key] || [];
        if (key === targetKey) {
          itemsArray.unshift(itemId);
          state.columns[key] = itemsArray;
          state.pagination[key].totalCount++;
          continue;
        }
        if (!itemsArray.includes(itemId)) {
          continue;
        }
        state.columns[key] = itemsArray.filter((id) => id !== itemId);
        state.pagination[key].totalCount--;
      }
    },
    del: (state, action: PayloadAction<{ itemId: number }>) => {
      const { itemId } = action.payload;
      cardsAdapter.removeOne(state.cards, itemId);
      for (const key of Object.keys(state.columns)) {
        if (!state.columns[key].includes(itemId)) {
          continue;
        }
        state.columns[key] = state.columns[key].filter((id) => id !== itemId);
        state.pagination[key].totalCount--;
        break;
      }
    },
    clear: () => {
      return initialState;
    },
    setDate: (state, action: PayloadAction<Date>) => {
      state.baseDate = action.payload.toISOString();
    },
    updatePagination: (state, action: PayloadAction<{ threshold: Threshold; page?: number; totalCount?: number }>) => {
      const key = String(action.payload.threshold.minDuration);
      const page = action.payload.page;
      const totalCount = action.payload.totalCount;
      if (!state.pagination[key]) {
        state.pagination[key] = { ...initialPagination };
      }
      if (page !== undefined) {
        state.pagination[key].page = page;
      }
      if (totalCount !== undefined) {
        state.pagination[key].totalCount = totalCount;
      }
    },
    markViewed: (state, action: PayloadAction<{ id: EntityId }>) => {
      cardsAdapter.updateOne(state.cards, { id: action.payload.id, changes: { viewedByModerator: true } });
    },
  },
});

export const reducerConfig = {
  [reducerPath]: slice.reducer,
};

export const selectThresholdItems = (state: ReducerStateShape, threshold: Threshold): ClientInteractionViewModel[] => {
  const sliceState = state[reducerPath];
  const ids = sliceState.columns[threshold.minDuration] || [];
  const items: ClientInteractionViewModel[] = [];
  let item: ClientInteractionViewModel | undefined;
  for (const id of ids) {
    item = selectById(sliceState.cards, id);
    if (!item) {
      continue;
    }
    items.push(item);
  }
  return items;
};

export const selectThresholdItemsCount = (state: ReducerStateShape, threshold: Threshold) => {
  return state[reducerPath].columns[threshold.minDuration]?.length || 0;
};

export const selectThresholdPagination = (rootState: ReducerStateShape, threshold: Threshold) => {
  const state = rootState[reducerPath];
  return {
    loadedCount: selectThresholdItemsCount(rootState, threshold),
    totalCount: state.pagination[threshold.minDuration]?.totalCount || 0,
    page: state.pagination[threshold.minDuration]?.page || 1,
  };
};

export const selectItemById = (state: ReducerStateShape, itemId: number) => selectById(state[reducerPath].cards, itemId);

export const selectBaseDate = (state: ReducerStateShape) => new Date(state[reducerPath].baseDate);

export const selectItemThreshold = (state: ReducerStateShape, itemId: number): number | undefined => {
  const sliceState = state[reducerPath].columns;
  for (const [key, values] of Object.entries(sliceState)) {
    if (values.includes(itemId)) {
      return Number(key);
    }
  }
};
