import { CatalogItemViewModel } from '@common/model/catalog/item/view';
import { ClientWebPage } from '@common/model/meta/clientWebPage';
import { ClientWebPageProps } from '@common/model/meta/clientWebPageProps';
import { ClientWebPagePathParams } from '@common/model/meta/clientWebPagePathParams';

export type PathGenerator = (p: {
  page: ClientWebPage;
  pageProps: ClientWebPageProps[ClientWebPage.CatalogItem];
  pathParams: ClientWebPagePathParams[ClientWebPage.CatalogItem] & Record<string, string | number | undefined>;
}) => string;
type PathGeneratorOptions = { CITY_PATH_PARAMETER: string };

export const generateCatalogItemPermalink = (
  model: CatalogItemViewModel,
  generatePath: PathGenerator,
  opts: PathGeneratorOptions,
): string =>
  generatePath({
    page: ClientWebPage.CatalogItem,
    pageProps: { catalogType: model.type, careerId: model.careerId },
    pathParams: { id: model.id, [opts.CITY_PATH_PARAMETER]: model.city?.alias },
  });
