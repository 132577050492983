import React from 'react';
import { Box } from '@cp/ds/src/components/box';
import { mergeSx } from '@cp/ds/src/utils/sx/merge';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { TASK_BOARD_COLUMN_WIDTH_DEFAULT, TaskBoardProps } from './model';
import { Column } from './column';

export * from './model';

export const TaskBoard = <T, S extends {} = {}>({ config, sx }: TaskBoardProps<T, S>): ReturnType<React.FC> => {
  const theme = useTheme();

  return (
    <Box component="table" sx={mergeSx({ borderSpacing: 0 }, sx)}>
      <thead>
        <tr>
          {config.columns.map(({ key, width = TASK_BOARD_COLUMN_WIDTH_DEFAULT, title }, index) => (
            <Box
              component="th"
              key={key}
              sx={{
                minWidth: width,
                width,
                maxWidth: width,
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                pl: index ? 1.5 : 0,
                pb: 1.5,
                pr: 1.5,
                borderBottom: `1px solid ${theme.palette.gray}`,
                borderRight: `1px solid ${theme.palette.gray}`,
                typography: 'button',
                textAlign: 'left',
              }}
            >
              {title}
            </Box>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          {config.columns.map((columnConfig, index) => (
            <Column<T, S> {...columnConfig} index={index} key={columnConfig.key} />
          ))}
        </tr>
      </tbody>
    </Box>
  );
};
