import { styled } from '../../../../../../theme/styled';
import { RangeInputStateProps } from '../model';

export const RangeInputRoot = styled('div', {
  name: 'RangeInput',
  slot: 'Root',
})<{ ownerState: RangeInputStateProps }>(({ theme, ownerState }) => {
  const borderRadius = `${theme.borderRadius.normal}px`;

  return {
    position: 'relative',
    backgroundColor: 'inherit',
    margin: 0,
    border: `1px solid ${theme.palette[ownerState.error ? 'brand pink' : 'gray light']}`,
    borderBottom: 'none',
    borderTopLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius,

    '@media (pointer: fine)': {
      '&:hover': !ownerState.disabled &&
        !ownerState.error && {
          borderColor: theme.palette.gray,
        },
    },
  };
});
