import { AdminModerationViewModel } from '@common/model/admin/moderation/adminModerationViewModel';
import { ModerationListRequest, ModerationListResponse, ModerationUpdateRequest } from '@common/model/moderation/request';
import { coreApi } from '@cp/shared/api/core';
import { ModerationApiTags, moderationApiTagTypes } from './tags';

export const coreModerationApi = coreApi.enhanceEndpoints({ addTagTypes: moderationApiTagTypes }).injectEndpoints({
  endpoints: (builder) => ({
    getDashboardList: builder.query<AdminModerationViewModel[], void>({
      query: () => ({
        url: '/admin/moderations/dashboard',
      }),
      providesTags: (_result, _error, _request) => [ModerationApiTags.Dashboard],
    }),
    getModerationsList: builder.query<ModerationListResponse, ModerationListRequest>({
      query: (params) => ({
        url: '/admin/moderations',
        params,
      }),
      providesTags: (_result, _error, _request) => [ModerationApiTags.ModerationsList],
    }),
    getModerationItem: builder.query<AdminModerationViewModel, { id: number }>({
      query: ({ id }) => ({
        url: `/admin/moderations/${id}`,
      }),
      providesTags: (_result, _error, { id }) => [{ type: ModerationApiTags.Moderation, id }],
    }),
    takeInWork: builder.mutation<AdminModerationViewModel, { id: number }>({
      query: ({ id }) => ({
        url: `/admin/moderations/${id}/take-in-work`,
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, _request) => [
        ModerationApiTags.ModerationsList,
        { type: ModerationApiTags.Moderation, id: _result?.id },
      ],
    }),
    moderationApprove: builder.mutation<AdminModerationViewModel, ModerationUpdateRequest & { id: number }>({
      query: ({ id, ...body }) => ({
        url: `/admin/moderations/${id}/approve`,
        body,
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, _request) => [
        ModerationApiTags.ModerationsList,
        { type: ModerationApiTags.Moderation, id: _result?.id },
      ],
    }),
    moderationDecline: builder.mutation<AdminModerationViewModel, ModerationUpdateRequest & { id: number }>({
      query: ({ id, ...body }) => ({
        url: `/admin/moderations/${id}/decline`,
        body,
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, _request) => [
        ModerationApiTags.ModerationsList,
        { type: ModerationApiTags.Moderation, id: _result?.id },
      ],
    }),
  }),
});
