import React from 'react';
import { EditableSection } from '@cp/ds/src/components/editableSection';
import { useModal } from 'src/app/shared/hooks/useModal';
import { CLIENT_FEEDBACKS_EDIT_MODAL_NAME } from 'src/app/shared/components/modals/clientFeedbacksEdit/const';
import { ClientFeedbacksEditableSectionProps } from './model';

export const ClientFeedbacksEditableSection: React.FC<ClientFeedbacksEditableSectionProps> = ({ clientId, mainResumeId }) => {
  const { openModal } = useModal();

  return (
    <EditableSection
      onEdit={() => {
        if (!clientId) {
          throw new Error('clientId is required');
        }
        openModal(CLIENT_FEEDBACKS_EDIT_MODAL_NAME, { clientId, mainResumeItemId: mainResumeId ?? undefined });
      }}
      title={<>Отзывы</>}
    ></EditableSection>
  );
};
