import { encodeQueryParams } from '@cp/shared/queryParams';
import { generatePath } from 'react-router';
import { AdminRoutePath } from 'src/app/routes/model';
import { AdminWebPagePathParams, AdminWebPageQueryParams } from '../const/meta';

export const generateAdminPath: <T extends AdminRoutePath>(
  path: T,
  options?: { pathParams?: AdminWebPagePathParams<T>; queryParams?: AdminWebPageQueryParams<T> },
) => string = (path, { pathParams, queryParams } = {}) => {
  const queryString = queryParams ? encodeQueryParams(queryParams) : '';

  return `${generatePath<AdminRoutePath>(path, pathParams)}${queryString ? `?${queryString}` : ''}`;
};
