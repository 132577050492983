import { Manager } from 'socket.io-client';
import { IConfigManager, useConfig } from '../../config';
import { createSocketManager } from './createManager';

let manager: Manager | undefined;

export const getManager = (cm: IConfigManager): Manager => {
  if (manager) {
    return manager;
  }
  manager = createSocketManager({ CORE_SERVER_WEBSOCKET_ORIGIN: cm.getOrThrow('CORE_SERVER_WEBSOCKET_ORIGIN') });
  return manager;
};

export const useSocketManager = () => {
  const config = useConfig();
  return getManager(config);
};
