import React from 'react';
import { SvgIcon } from '../../../components/svgIcon';
import { SvgIconProps } from '../../../components/svgIcon/model';

export const TutorIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="career/common/tutor" ref={ref as any} {...props}>
      <path
        d="M20.9759 16.3695V16.1506V15.3697V8.02093V8.00633C20.9759 7.99174 20.9689 7.97714 20.9689 7.96255C20.9689 7.95525 20.9689 7.94795 20.9689 7.94065V7.92606C20.9689 7.69983 20.8425 7.49549 20.6529 7.41522L11.4032 3.53284C11.2978 3.48905 11.1784 3.48905 11.0731 3.53284L1.81633 7.42252C1.6267 7.50279 1.50028 7.70713 1.50028 7.93336C1.49326 8.15958 1.61968 8.36392 1.80228 8.45149L4.49221 9.64832V17.3474C4.49924 17.5736 4.62566 17.778 4.80826 17.8582C8.95202 19.617 13.5102 19.617 17.6539 17.8582C17.8435 17.778 17.97 17.5736 17.97 17.3474V9.64102L20.0137 8.7361V15.9681V16.3695C19.1288 16.5884 18.4686 17.4204 18.4686 18.4056C18.4686 19.5586 19.3746 20.5 20.4843 20.5C21.594 20.5 22.5 19.5586 22.5 18.4056C22.5 17.4204 21.8468 16.5957 20.9759 16.3695ZM17.0148 16.9606C13.3135 18.4566 9.21188 18.4566 5.44738 16.9606V14.691C9.18379 16.0995 13.2784 16.0995 17.0148 14.691V16.9606ZM17.0148 13.5307C13.2854 15.0267 9.18379 15.0267 5.44738 13.5307V10.0716L11.052 12.5601C11.1082 12.582 11.1714 12.5966 11.2276 12.5966C11.2908 12.5966 11.347 12.582 11.4032 12.5601L17.0078 10.0716V13.5307H17.0148ZM17.3449 8.7434C17.3238 8.7507 17.3027 8.758 17.2817 8.77259L11.2346 11.4581L5.18752 8.77259C5.16645 8.758 5.14538 8.7507 5.12431 8.7434L3.34039 7.94795L11.2346 4.63479L19.1288 7.94795L17.3449 8.7434ZM20.4843 19.3908C19.9576 19.3908 19.5221 18.9456 19.5221 18.391C19.5221 17.8363 19.9505 17.3912 20.4843 17.3912C21.0181 17.3912 21.4465 17.8363 21.4465 18.391C21.4465 18.9456 21.0181 19.3908 20.4843 19.3908Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});
