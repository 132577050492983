import { ApiServerEntityIDType } from '@cp/utils/apiServer/types';
import { adminCatalogItemApi } from 'src/entities/catalog/item';

export const useResumeData = ({ resumeId }: { resumeId?: ApiServerEntityIDType | null }) => {
  const { data: resume, isLoading: isLoadingResume } = adminCatalogItemApi.endpoints.getCatalogItem.useQuery(
    { params: { id: resumeId as number } },
    { skip: !resumeId },
  );

  return { resume, isLoadingResume };
};
