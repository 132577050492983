import React, { FC, useMemo } from 'react';
import { DialogMessageViewModel } from '@common/model/dialog/message/dialogMessageViewModel';
import { Box } from '@cp/ds/src/components/box';
import { ReadIcon } from '@cp/ds/src/icons/read';
import { SentIcon } from '@cp/ds/src/icons/sent';
import { useIsDelivered } from '../../model/useIsDelivered';
import { useIsRead } from '../../model/useIsRead';

export interface MessageDeliveryProps {
  message: DialogMessageViewModel;
}

export const MessageDelivery: FC<MessageDeliveryProps> = ({ message }) => {
  const isDelivered = useIsDelivered(message);
  const isRead = useIsRead(message);

  const icon = useMemo(() => {
    if (isRead) {
      return <ReadIcon sx={{ fontSize: '14px' }} />;
    }
    if (isDelivered) {
      return <SentIcon sx={{ fontSize: '14px' }} />;
    }
    return null;
  }, [isDelivered, isRead]);

  return (
    <Box component="span" sx={{ display: 'inline-flex' }}>
      {icon}
    </Box>
  );
};
