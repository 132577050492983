/* eslint-disable space-in-parens */
import { InputMaskTokenDictionary } from './model';

export const triggerEvent = (type: string) => {
  return new Event(type, { bubbles: true, cancelable: true });
};

// ToDo: refactor >>>
export const validateInput = (inputValue: string, mask: string, tokens: InputMaskTokenDictionary) => {
  const _inputValue = inputValue || '';
  let s = '';
  let i = 0;

  for (let o = 0; i < mask.length && o < _inputValue.length; ) {
    let currentMaskChar = mask[i];
    const a = tokens[currentMaskChar];
    const c = _inputValue[o];

    if (a && !a.escape) {
      if (a.pattern.test(c)) {
        s += a.transform ? a.transform(c) : c;
        i++;
      }

      o++;
    } else {
      if (a && a.escape) {
        currentMaskChar = mask[++i];
      }

      s += currentMaskChar;

      if (c === currentMaskChar) {
        o++;
      }
      i++;
    }
  }

  let u = '';
  for (; i < mask.length; i++) {
    const currentMaskChar = mask[i];
    if (tokens[currentMaskChar]) {
      u = '';
      break;
    }
    u += currentMaskChar;
  }

  return s + u;
};
// ToDo: refactor <<<
