import React from 'react';
import { Box } from '../box';
import { useTheme } from '../../hooks/useTheme';
import { mergeSx } from '../../utils/sx/merge';
import { CardProps } from './model';
import { cardStyles } from './styles';
export type { CardProps } from './model';

export const Card: React.FC<React.PropsWithChildren<CardProps>> = ({ width, onClick, children, sx, variant = 'one', ...restProps }) => {
  const theme = useTheme();

  const mergedSx = React.useMemo(() => mergeSx(cardStyles[variant](theme, { hover: Boolean(onClick) }), sx), [onClick, sx, theme]);

  return (
    <Box onClick={onClick} style={{ width }} sx={mergedSx} {...restProps}>
      {children}
    </Box>
  );
};

export default Card;
