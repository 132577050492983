import { coreApi } from '@cp/shared/api/core';
import { generateApiUrl } from '@common/api/configure';
import { ApiRoute } from '@common/api/route';
import { StatsVacanciesRequest, StatsVacanciesResponse } from '@common/model/stats/vacancies.request';

export const coreCatalogStats = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getVacanciesStats: builder.query<StatsVacanciesResponse, StatsVacanciesRequest>({
      query: () => ({
        url: generateApiUrl(ApiRoute.StatsVacancies),
      }),
    }),
  }),
});
