import React from 'react';
import { authenticationSlice } from '@cp/shared/api/core';
import { useDispatch } from 'react-redux';
import { coreAdminApi } from 'src/services/core/area/admin';

export const useLogout = () => {
  const [logout, { isLoading }] = coreAdminApi.useLogoutMutation();
  const dispatch = useDispatch();

  return {
    logout: React.useCallback(async () => {
      await logout();
      dispatch(authenticationSlice.actions.setToken(null));
    }, [dispatch, logout]),
    isLoading,
  };
};
