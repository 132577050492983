import React from 'react';
import { Button } from '@cp/ds/src/components/button';
import { EditIcon } from '@cp/ds/src/icons';
import { Skeleton } from '@cp/ds/src/components/skeleton';
import { useModal } from 'src/app/shared/hooks/useModal';
import { CLIENT_PHOTO_PICK_MODAL_NAME } from 'src/app/shared/components/modals/clientPhotoPick/const';
import { ClientPhotoEditButtonProps } from './model';

export const ClientPhotoEditButton: React.FC<ClientPhotoEditButtonProps> = ({ sx, isLoading, clientProfile }) => {
  const { openModal } = useModal();

  const handleEdit = React.useCallback(() => {
    if (!clientProfile) {
      throw new Error('ClientProfile should be provided');
    }
    openModal(CLIENT_PHOTO_PICK_MODAL_NAME, { clientProfile });
  }, [clientProfile, openModal]);

  if (isLoading) {
    return <Skeleton height={40} width={120} />;
  }

  return (
    <Button onClick={handleEdit} size="small" sx={sx} variant="text">
      <EditIcon sx={{ mr: 0.75 }} />
      <span>Редактировать</span>
    </Button>
  );
};
