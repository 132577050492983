import React from 'react';
import { setInputElementNativeValue } from '../../utils';
import { defaultTokens } from './defaultTokens';
import { InputMaskTokenDictionary } from './model';
import { triggerEvent, validateInput } from './utils';

const handledEvents: (keyof HTMLElementEventMap)[] = ['blur', 'change', 'paste', 'keydown', 'input'];

export const useInputMask = (
  inputRef: React.MutableRefObject<HTMLInputElement | null>,
  mask: string,
  tokens: InputMaskTokenDictionary = defaultTokens,
) => {
  React.useEffect(() => {
    if (!inputRef.current) {
      return;
    }

    const input = inputRef.current;

    const handleInput = (event: HTMLElementEventMap[keyof HTMLElementEventMap]) => {
      if (!event.isTrusted) {
        return;
      }

      let position = input.selectionEnd as number;
      const char = input.value[position - 1];
      for (
        setInputElementNativeValue(input, validateInput(input.value, mask, tokens));
        position < input.value.length && input.value.charAt(position - 1) !== char;
        position++
      ) {}

      if (input === document.activeElement) {
        input.setSelectionRange(position, position);
        setTimeout(() => {
          input.setSelectionRange(position, position);
        }, 0);
      }

      input.dispatchEvent(triggerEvent('input'));
    };

    for (const event of handledEvents) {
      input.addEventListener(event, handleInput);
    }

    setTimeout(() => {
      const maskedValue = validateInput(input.value, mask, tokens);
      if (maskedValue !== input.value) {
        setInputElementNativeValue(input, maskedValue);
        input.dispatchEvent(triggerEvent('input'));
      }
    }, 0);

    return () => {
      for (const event of handledEvents) {
        input.removeEventListener(event, handleInput);
      }
    };
  }, [inputRef, mask, tokens]);
};
