import React from 'react';
import { Button, ButtonProps } from '../../../button';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import { CloseIcon, CloseThinIcon } from '../../../../icons';

export const CloseButton: React.FC<ButtonProps> = (props) => {
  const isMobile = useIsMobile();

  const spacing = isMobile ? 10 : 20;

  return (
    <Button
      size="small"
      sx={{
        position: 'absolute',
        top: spacing,
        right: spacing,
        zIndex: 10,
      }}
      variant="text"
      {...props}
    >
      {isMobile ? <CloseIcon sx={{ fontSize: '1.75rem' }} /> : <CloseThinIcon sx={{ fontSize: '2rem' }} />}
    </Button>
  );
};
