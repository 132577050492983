import React from 'react';
import { ApiServerEntityIDType } from '@cp/utils/apiServer/types';
import { ResponseList, coreResponsesApi } from '@cp/entities/response';
import { ResponseView } from '@common/model/catalog/item/response';
import { ResponseCardFeature } from './responseCard';

export interface ResponseListFeatureProps {
  catalogItemId: ApiServerEntityIDType;
  onItemClick?: (model: ResponseView) => void;
}

export const ResponsesListFeature: React.FC<ResponseListFeatureProps> = ({ catalogItemId, onItemClick }) => {
  const { data } = coreResponsesApi.endpoints.getCatalogItemResponses.useQuery({ catalogItemId });
  return (
    <ResponseList>
      {data?.items.map((responseModel) => <ResponseCardFeature key={responseModel.id} model={responseModel} onActivate={onItemClick} />)}
    </ResponseList>
  );
};
