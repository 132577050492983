import React, { PropsWithChildren } from 'react';
import { Box } from '@cp/ds/src/components/box';
import { BoxProps } from '@cp/ds/src/components/box/model';
import { styled } from '@cp/ds/src/theme';

const StyledBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette['gray middle']}`,
  borderRadius: theme.borderRadius.normal,
  overflow: 'hidden',
  backgroundColor: theme.palette.background.default,
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  padding: theme.spacing(1.5),
}));

export type CardProps = BoxProps & PropsWithChildren;

export const Card = React.forwardRef<HTMLDivElement, CardProps>((props, ref) => <StyledBox {...props} ref={ref} />);
