import React from 'react';
import { SvgIcon, SvgIconProps } from '../components/svgIcon';

export const CreditCardIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="creditCard" ref={ref as any} {...props}>
      <path
        d="M20.5943 4.66675H3.40567C2.63067 4.66675 2 5.29741 2 6.07241V17.9277C2 18.7027 2.63067 19.3334 3.40567 19.3334H20.5943C21.3693 19.3334 22 18.7027 22 17.9277V6.07241C22 5.29741 21.3693 4.66675 20.5943 4.66675ZM21.3333 17.9277C21.3333 18.3351 21.002 18.6667 20.5943 18.6667H3.40567C2.998 18.6667 2.66667 18.3351 2.66667 17.9277V6.07241C2.66667 5.66508 2.998 5.33341 3.40567 5.33341H20.5943C21.002 5.33341 21.3333 5.66508 21.3333 6.07241V17.9277Z"
        fill="currentColor"
      />
      <path
        d="M3.66669 10C3.66669 10.5523 4.1144 11 4.66669 11H8.66669C9.21897 11 9.66669 10.5523 9.66669 10V8C9.66669 7.44772 9.21897 7 8.66669 7H4.66669C4.1144 7 3.66669 7.44772 3.66669 8V10Z"
        fill="currentColor"
      />
      <path
        d="M3.66669 15.1668C3.66669 15.443 3.89054 15.6668 4.16669 15.6668H6.83335C7.1095 15.6668 7.33335 15.443 7.33335 15.1668V14.8335C7.33335 14.5574 7.1095 14.3335 6.83335 14.3335H4.16669C3.89055 14.3335 3.66669 14.5574 3.66669 14.8335V15.1668Z"
        fill="currentColor"
      />
      <path
        d="M8 15.1668C8 15.443 8.22386 15.6668 8.5 15.6668H11.1667C11.4428 15.6668 11.6667 15.443 11.6667 15.1668V14.8335C11.6667 14.5574 11.4428 14.3335 11.1667 14.3335H8.5C8.22386 14.3335 8 14.5574 8 14.8335V15.1668Z"
        fill="currentColor"
      />
      <path
        d="M12.3333 15.1668C12.3333 15.443 12.5572 15.6668 12.8333 15.6668H15.5C15.7761 15.6668 16 15.443 16 15.1668V14.8335C16 14.5574 15.7761 14.3335 15.5 14.3335H12.8333C12.5572 14.3335 12.3333 14.5574 12.3333 14.8335V15.1668Z"
        fill="currentColor"
      />
      <path
        d="M16.6667 15.1668C16.6667 15.443 16.8905 15.6668 17.1667 15.6668H19.8334C20.1095 15.6668 20.3334 15.443 20.3334 15.1668V14.8335C20.3334 14.5574 20.1095 14.3335 19.8334 14.3335H17.1667C16.8905 14.3335 16.6667 14.5574 16.6667 14.8335V15.1668Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});
