import React, { useState } from 'react';
import { Stack } from '@cp/ds/src/components/stack';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { useMediaQuery } from '@mui/material';
import { Box } from '@cp/ds/src/components/box';
import { HEADER_HEIGHT } from '../../const';
import { Logout } from './components/logout';
import { sidebarConfig } from './config';
import { SidebarSection } from './components/section';
import { HidingButton } from './components/hidingButton';

const SIDEBAR_WIDTH = 270;

export const PageSidebar: React.FC = React.memo(() => {
  const theme = useTheme();
  const isLGScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const [isHidden, setIsHidden] = useState(true);

  return (
    <Box
      sx={{
        zIndex: 3,
        position: isLGScreen ? 'relative' : 'absolute',
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: `calc(100vh - ${HEADER_HEIGHT}px)`,
        backgroundColor: 'gray ultra light',
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        transform: `translateX(-${isLGScreen ? 0 : isHidden ? 100 : 0}%)`,
        width: SIDEBAR_WIDTH,
      }}
    >
      <Stack sx={{ width: SIDEBAR_WIDTH, overflowX: 'auto' }}>
        {sidebarConfig.map((props, i) => (
          <SidebarSection {...props} key={i} />
        ))}
      </Stack>
      <Logout />

      {!isLGScreen && (
        <HidingButton
          handleClick={() => setIsHidden(!isHidden)}
          sx={{
            position: 'absolute',
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            transform: `rotate(${isHidden ? 0 : 180}deg)`,
            bottom: 16,
            right: -60,
          }}
        />
      )}
    </Box>
  );
});
