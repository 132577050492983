import { HousekeeperIcon as HousekeeperColoredIcon } from '@cp/ds/src/icons/career/colored/housekeeper';
import { NannyIcon as NannyColoredIcon } from '@cp/ds/src/icons/career/colored/nanny';
import { NurseIcon as NurseColoredIcon } from '@cp/ds/src/icons/career/colored/nurse';
import { TutorIcon as TutorColoredIcon } from '@cp/ds/src/icons/career/colored/tutor';
import { PsychologistIcon as PsychologistColoredIcon } from '@cp/ds/src/icons/career/colored/psychologist';
import { ZooNannyIcon as ZooNannyColoredIcon } from '@cp/ds/src/icons/career/colored/zooNanny';
import { NannyIcon } from '@cp/ds/src/icons/career/common/nanny';
import { NurseIcon } from '@cp/ds/src/icons/career/common/nurse';
import { TutorIcon } from '@cp/ds/src/icons/career/common/tutor';
import { HousekeeperIcon } from '@cp/ds/src/icons/career/common/housekeeper';
import { PsychologistIcon } from '@cp/ds/src/icons/career/common/psychologist';
import { ZooNannyIcon } from '@cp/ds/src/icons/career/common/zooNanny';
import { SvgIconComponent } from '@cp/ds/src/components/svgIcon';

interface CareerLayoutData {
  colorLight: string;
  iconComponent: SvgIconComponent;
  iconCommonComponent: SvgIconComponent;
}

export const careerIdToLayoutData: Record<number, CareerLayoutData> = {
  1: {
    colorLight: '#FFE0E4',
    iconComponent: NannyColoredIcon,
    iconCommonComponent: NannyIcon,
  },
  2: {
    colorLight: '#FFF7EB',
    iconComponent: NurseColoredIcon,
    iconCommonComponent: NurseIcon,
  },
  3: {
    colorLight: '#E9F7FD',
    iconComponent: TutorColoredIcon,
    iconCommonComponent: TutorIcon,
  },
  4: {
    colorLight: '#E1F4EC',
    iconComponent: HousekeeperColoredIcon,
    iconCommonComponent: HousekeeperIcon,
  },
  5: {
    colorLight: '#fff',
    iconComponent: PsychologistColoredIcon,
    iconCommonComponent: PsychologistIcon,
  },
  6: {
    colorLight: '#fff',
    iconComponent: ZooNannyColoredIcon,
    iconCommonComponent: ZooNannyIcon,
  },
};
