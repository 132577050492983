import { CareerId } from '@common/const/catalog/career';
import { AdminRoutePath } from 'src/app/routes/model';
import { generateAdminPath } from 'src/app/shared/utils/generateAdminPath';
import { SidebarSectionProps } from './components/section/model';
import { MessagesSidebarSection } from './components/customSections/messages';

export const sidebarConfig: SidebarSectionProps[] = [
  {
    component: MessagesSidebarSection,
  },
  {
    title: 'Скорость ответа',
    href: generateAdminPath(AdminRoutePath.ResponseDelay),
  },
  {
    title: 'Объявления',
    href: generateAdminPath(AdminRoutePath.Vacancies),
  },
  {
    title: 'Модерация',
    isExpanded: true,
    sectionItems: [
      {
        title: 'Няни',
        href: generateAdminPath(AdminRoutePath.ModerationClientPatronage, { queryParams: { careerId: CareerId.Nanny } }),
      },
      {
        title: 'Сиделки',
        href: generateAdminPath(AdminRoutePath.ModerationClientPatronage, { queryParams: { careerId: CareerId.Nurse } }),
      },
      {
        title: 'Домработницы',
        href: generateAdminPath(AdminRoutePath.ModerationClientPatronage, { queryParams: { careerId: CareerId.Housekeeper } }),
      },
      {
        title: 'Репетиторы',
        href: generateAdminPath(AdminRoutePath.ModerationClientPatronage, { queryParams: { careerId: CareerId.Tutor } }),
      },
    ],
  },
  {
    title: 'Метрики',
    // TODO add href
    href: '#',
  },
  {
    title: 'Все специалисты',
    href: generateAdminPath(AdminRoutePath.Specialists),
  },
  {
    title: 'Все наниматели',
    href: generateAdminPath(AdminRoutePath.Employers),
  },
  {
    title: 'Сервис',
    sectionItems: [
      {
        title: 'Настройки',
        // TODO add href
        href: '#',
      },
      {
        title: 'Кеширование',
        // TODO add href
        href: generateAdminPath(AdminRoutePath.ServiceCaching),
      },
    ],
  },
];
