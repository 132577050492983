import React from 'react';
import useForkRef from '@mui/utils/useForkRef';
import { useInputMask } from '../../../../../hooks/useInputMask';
import { TextInput } from '../text';
import { CardExpirationInputProps } from './model';
import { CARD_EXPIRATION_INPUT_MASK, cardExpirationInputMaskTokens } from './const';

export * from './const';
export * from './model';

export const CardExpirationInput = React.forwardRef<HTMLInputElement, CardExpirationInputProps>(({ inputSx, ...props }, ref) => {
  const innerRef = React.useRef<HTMLInputElement>(null);
  const fieldRef = useForkRef(innerRef, ref);

  useInputMask(innerRef, CARD_EXPIRATION_INPUT_MASK, cardExpirationInputMaskTokens);
  return (
    <TextInput
      autoComplete="cc-expiration"
      htmlType="text"
      inputMode="numeric"
      inputSx={inputSx}
      placeholder="‒ ‒ / ‒ ‒"
      size="normal"
      {...props}
      ref={fieldRef}
    />
  );
});
