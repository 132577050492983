var MappedTypes = require('@nestjs/mapped-types');

var mockedDecoratorNames = ['ApiProperty'];

function MockedDecorator() {
  return function () {};
}

module.exports = new Proxy({}, {
  get: function(target, name) {
    if (mockedDecoratorNames.includes(name)) {
      return MockedDecorator;
    }
    return MappedTypes[name];
  }
});
