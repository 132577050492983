import React from 'react';
import { Stack } from '@cp/ds/src/components/stack';
import { Typography } from '@cp/ds/src/components/typography';
import {
  CATALOG_ITEM_ADDITIONAL_PARAMETERS_DICT,
  CATALOG_ITEM_ADDITIONAL_PARAMETERS_SEQUENCE,
} from '@common/model/catalog/item/additionalParameters';
import { CheckmarkIcon } from '@cp/ds/src/icons';
import { AdditionalProps } from './model';

export * from './model';

export const Additional: React.FC<AdditionalProps> = ({ data, sx }) => {
  const renderParameter = (key: React.Key, value: string) => {
    return (
      <Stack direction="row" key={key} sx={{ alignItems: 'center' }}>
        <CheckmarkIcon fontSize="1rem" sx={{ color: 'green', mr: 0.75 }} />
        <Typography variant="body">{value}</Typography>
      </Stack>
    );
  };

  return (
    <Stack component="div" direction="column" gap={0.5} sx={sx}>
      {CATALOG_ITEM_ADDITIONAL_PARAMETERS_SEQUENCE.filter((key) => Boolean(data[key])).map((key) => {
        return renderParameter(key, CATALOG_ITEM_ADDITIONAL_PARAMETERS_DICT[key]?.labelShort ?? '');
      })}
      {data.translation?.attitudeTowardsPets
        ? renderParameter('attitudeTowardsPets', `Отношение к животным: ${data.translation?.attitudeTowardsPets}`)
        : null}
    </Stack>
  );
};
