import { coreApi } from '@cp/shared/api/core';
import { generateApiUrl } from '@common/api/configure';
import { CatalogOptionsResponse } from '@common/model/catalog/options/options.response';
import { ApiRouteQueryParams } from '@common/api/routeQueryParams';
import { ApiRoute } from '@common/api/route';
import { apiRouteMethodDict } from '@common/api/routeMethod';
import { CatalogOptionsDictionary } from '@common/model/catalog/options/dictionary';
import { CatalogOptionGroupViewModel } from '@common/model/catalog/options/group.view';
import { catalogOptionsDictionaryDataMapper } from '../lib';
import { CoreCatalogOptionTags, coreCatalogOptionTagTypes } from './tags';

export const catalogOptionCoreApi = coreApi.enhanceEndpoints({ addTagTypes: coreCatalogOptionTagTypes }).injectEndpoints({
  endpoints: (builder) => ({
    getOptions: builder.query<CatalogOptionsResponse, ApiRouteQueryParams[ApiRoute.CatalogOptionsGet]>({
      query: (params) => ({
        url: generateApiUrl(ApiRoute.CatalogOptionsGet),
        method: apiRouteMethodDict[ApiRoute.CatalogOptionsGet],
        params,
      }),
    }),
    getOptionsDictionary: builder.query<CatalogOptionsDictionary, void>({
      query: () => ({
        url: 'catalog/option-groups',
      }),
      transformResponse: (response: CatalogOptionGroupViewModel[]) => {
        return catalogOptionsDictionaryDataMapper(response);
      },
      providesTags: [{ type: CoreCatalogOptionTags.CatalogOptionsDictionary }],
    }),
  }),
});
