import { InputMaskTokenDictionary } from '../../../../../hooks/useInputMask/model';

export const CARD_EXPIRATION_INPUT_MASK = '## / ##';
export const CARD_EXPIRATION_LENGTH = CARD_EXPIRATION_INPUT_MASK.length;

export const cardExpirationInputMaskTokens: InputMaskTokenDictionary = {
  '#': {
    pattern: /\d/,
  },
};
