import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const SentIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="sent" ref={ref as any} {...props}>
      <path
        d="M5.29582 15.3151L9.41387 18.6577L20.076 5.55034"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
});
