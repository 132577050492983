import React, { useMemo } from 'react';
import { CloseThinIcon } from '@cp/ds/src/icons/closeThin';
import { Drawer } from '@cp/ds/src/components/drawer';
import { DrawerProps } from '@cp/ds/src/components/drawer/model';
import { Button } from '@cp/ds/src/components/button';
import { Box } from '@cp/ds/src/components/box';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { Dialog, DialogContextProvider, DialogContextType, coreDialogApi, currentDialogModel, dialogModel } from '@cp/entities/dialog';
import { CircularProgress, SxProps } from '@mui/material';
import { mergeSx } from '@cp/ds/src/utils/sx/merge';
import { useDispatch, useSelector } from 'react-redux';
import { DialogSendMessageForm } from 'src/features/dialog/sendMessage';
import { DialogHeader } from '../dialogHeader';
import { DialogMessage } from './dialogMessage';

export interface DialogDrawerProps extends DrawerProps {
  handleDialogClose: () => void;
}

const MessageListWrapper = React.forwardRef<HTMLElement>(({ sx, ...props }: { sx?: SxProps }, ref) => (
  <Box ref={ref} sx={mergeSx(sx, { px: 5 })} {...props} />
));

export const DialogDrawer: React.FC<DialogDrawerProps> = ({ handleDialogClose, sx, ...props }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const dialog = useSelector(currentDialogModel.selectors.currentDialogSelector);
  const page = useSelector(currentDialogModel.selectors.currentDialogMessagesPageSelector);
  const dialogId = useSelector(currentDialogModel.selectors.currentDialogIdSelector);
  const { data: dialogData, isLoading: isLoadingDialog } = coreDialogApi.useGetDialogQuery({ id: dialogId as number }, { skip: !dialogId });
  const { data: messagesData, isLoading: isLoadingMessages } = coreDialogApi.useGetMessagesQuery(
    { id: dialogId as number, page },
    { skip: !dialogId },
  );
  const loadedMessages = useSelector(currentDialogModel.selectors.currentDialogMessagesSelector);
  const totalCount = useSelector(currentDialogModel.selectors.currentDialogMessagesTotalCountSelector);
  const headerContainerRef = React.useRef<HTMLDivElement>(null);
  const sendFormContainerRef = React.useRef<HTMLFormElement>(null);
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  const messageBlocks = useSelector(currentDialogModel.selectors.currentDialogBlocksSelector);
  const hasMoreMessages = loadedMessages.length < totalCount;

  React.useEffect(() => {
    if (!dialogData || isLoadingDialog || dialogId !== dialogData.id) {
      return;
    }
    dispatch(dialogModel.actions.dialogLoaded(dialogData));
  }, [dialogData, isLoadingDialog, dispatch, dialogId]);

  React.useEffect(() => {
    if (!messagesData?.items?.length || isLoadingMessages || dialogId !== messagesData.items[0].dialogId) {
      return;
    }
    dispatch(dialogModel.actions.messagesLoaded(messagesData));
  }, [dialogId, dispatch, isLoadingMessages, messagesData]);

  const handleLoadMore = React.useCallback(() => {
    if (isLoadingMessages) {
      return;
    }

    dispatch(dialogModel.actions.increaseCurrentDialogMessagesPage());
  }, [dispatch, isLoadingMessages]);

  const dialogContextValue: DialogContextType = useMemo(
    () => ({
      MessageComponent: DialogMessage,
    }),
    [],
  );

  return (
    <DialogContextProvider value={dialogContextValue}>
      <Drawer
        BackdropProps={{ sx: { zIndex: 10 } }}
        ModalProps={{
          components: { Root: 'div' },
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            backgroundColor: theme.palette.white,
            overflowY: 'hidden',
            zIndex: 10,
            maxWidth: 720,
            width: 720,
            minWidth: 320,
          },
        }}
        anchor="right"
        onClose={handleDialogClose}
        open={Boolean(dialog)}
        sx={mergeSx({ zIndex: 11 }, sx)}
        variant="temporary"
        {...props}
      >
        <Dialog
          Loader={() => (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
              <CircularProgress sx={{ color: 'gray' }} />
            </Box>
          )}
          MessageListWrapper={MessageListWrapper}
          footer={<DialogSendMessageForm ref={sendFormContainerRef} sx={{ flexShrink: 0 }} />}
          hasMore={hasMoreMessages}
          header={<DialogHeader dialog={dialog} />}
          headerContainerRef={headerContainerRef}
          isLoadingMessages={isLoadingMessages}
          loadMore={handleLoadMore}
          loadedMessagesLength={loadedMessages.length}
          messageBlocks={messageBlocks}
          scrollContainerRef={scrollContainerRef}
          sx={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            userSelect: 'none',
          }}
        />
        <Button
          color="white"
          onClick={handleDialogClose}
          size="small"
          square
          sx={{ position: 'absolute', top: { xs: 5, md: 20 }, right: { xs: 5, md: 20 }, zIndex: 5 }}
          variant="primary"
        >
          <CloseThinIcon sx={{ fontSize: '2rem' }} />
        </Button>
      </Drawer>
    </DialogContextProvider>
  );
};
