import styled from '@mui/material/styles/styled';
import { shouldForwardProp } from '@mui/system';

export const StyledRadioInput = styled('input', {
  shouldForwardProp,
  name: 'Radio',
  slot: 'Input',
})(() => ({
  cursor: 'inherit',
  position: 'absolute',
  opacity: 0,
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  margin: 0,
  padding: 0,
  zIndex: 1,
}));
