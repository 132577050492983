import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const ClipIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="clip" ref={ref as any} {...props}>
      <path
        d="M10.2947 18.5095L17.8691 10.8421C19.1272 9.56618 19.2331 7.96382 18.1322 6.84937C17.0324 5.73491 15.4471 5.84094 14.1878 7.11442L7.37515 14.0107C7.14424 14.2457 7.14424 14.6288 7.37515 14.8625C7.60725 15.0975 7.98571 15.0975 8.21661 14.8625L15.0293 7.96621C15.6399 7.34816 16.5539 6.95418 17.2907 7.69994C18.0274 8.44453 17.6371 9.37099 17.0265 9.98908L9.45323 17.6553C7.27756 19.8552 6.27541 20.4034 4.98045 19.0926C3.67242 17.7661 3.99736 16.3613 6.08495 14.2481L14.5032 5.7301C15.8625 4.35424 18.0822 3.39039 19.8675 5.19878C21.9884 7.34573 19.9032 9.79753 19.2117 10.4963L11.4778 18.3227C11.2456 18.5565 11.2456 18.9396 11.4778 19.1745C11.7099 19.4083 12.0871 19.4083 12.3204 19.1745L20.0531 11.3481C22.3907 8.98187 22.6406 6.29879 20.7089 4.34578C18.7606 2.3723 15.9279 2.58557 13.6618 4.87831L5.24708 13.3975C4.52938 14.1216 3 15.6721 3 17.4553C3 18.2553 3.30943 19.1046 4.14138 19.9468C6.50747 22.3407 8.69865 20.1263 10.2947 18.5095Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});
