import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const CloseIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="close" ref={ref as any} {...props}>
      <path
        d="M14.3625 12.4999L17.6141 9.24847C18.1286 8.73397 18.1286 7.89992 17.6141 7.38568C17.1002 6.87144 16.2656 6.87144 15.7516 7.38568L12.5 10.6376L9.24838 7.38568C8.73441 6.87144 7.89984 6.87144 7.38587 7.38568C6.87138 7.90018 6.87138 8.73424 7.38587 9.24847L10.6372 12.4999L7.38587 15.7513C6.87138 16.2658 6.87138 17.0998 7.38587 17.6141C7.64272 17.8714 7.98019 17.9997 8.31713 17.9997C8.6538 17.9997 8.99153 17.8714 9.24838 17.6141L12.5 14.3627L15.7516 17.6143C16.0087 17.8717 16.3459 18 16.6829 18C17.0198 18 17.3573 17.8717 17.6141 17.6143C18.1286 17.0998 18.1286 16.2658 17.6141 15.7515L14.3625 12.4999Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});
