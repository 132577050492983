import styled from '@mui/material/styles/styled';

export const AutocompleteList = styled('ul', {
  name: 'Autocomplete',
  slot: 'List',
})(() => ({
  padding: 0,
  listStyleType: 'none',
  margin: 0,
  '&:focus, &:active': {
    outline: 'none',
  },
}));
