import React from 'react';
import { Box } from '@cp/ds/src/components/box';
import { useIsMobile } from '@cp/ds/src/hooks/useIsMobile';
import { Container } from '@cp/ds/src/components/container';
import { ModalView } from '@cp/ds/src/components/modalView';
import { SubjectModalViewProps } from './model';

export * from './model';

const HORIZONTAL_SPACING_DESKTOP = 5;

export const SubjectModalView: React.FC<SubjectModalViewProps> = ({ isOpen, onClose, buttons, children }) => {
  const isMobile = useIsMobile();

  const ViewContainer = isMobile ? Container : Box;
  const FooterContainer = isMobile ? Container : Box;

  return (
    <ModalView
      buttons={
        buttons ? <FooterContainer sx={isMobile ? undefined : { px: HORIZONTAL_SPACING_DESKTOP }}>{buttons}</FooterContainer> : undefined
      }
      config={{
        desktop: 'drawerRight',
        mobile: 'fullscreen',
      }}
      containerSx={{ width: { xs: 1, md: 780 } }}
      isOpen={isOpen}
      onClose={onClose}
      scrollableContainerSx={{
        pt: { xs: 1.5, md: HORIZONTAL_SPACING_DESKTOP },
        px: { xs: 0, md: HORIZONTAL_SPACING_DESKTOP },
        pb: 2.5,
      }}
    >
      <ViewContainer>{children}</ViewContainer>
    </ModalView>
  );
};
