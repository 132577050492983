import React, { FC } from 'react';
import { Box } from '../box';
import { SxProps } from '../../theme';
import { mergeSx } from '../../utils/sx/merge';
import { DotMarkSizes, defaultDotMarkSizes } from './sizes';

export interface DotMarkProps {
  color: string;
  size?: DotMarkSizes;
  sx?: SxProps;
}

export const DotMark: FC<DotMarkProps> = ({ color, size = 'normal', sx }) => {
  return (
    <Box
      sx={mergeSx(
        {
          width: defaultDotMarkSizes[size],
          height: defaultDotMarkSizes[size],
          borderRadius: '50%',
          backgroundColor: color,
        },
        sx,
      )}
    />
  );
};
