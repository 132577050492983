import { CLIENT_CONTACTS_EDIT_MODAL_NAME } from '../modals/clientContactsEdit/const';
import { CLIENT_PERSONAL_INFORMATION_EDIT_MODAL_NAME } from '../modals/clientPersonalInformationEdit/const';
import { CLIENT_PHOTO_PICK_MODAL_NAME } from '../modals/clientPhotoPick/const';
import { RESUME_ABOUT_ME_EDIT_MODAL_NAME } from '../modals/resumeAboutMeEdit/const';
import { RESUME_ADDITIONAL_EDIT_MODAL_NAME } from '../modals/resumeAdditionalEdit/const';
import { RESUME_SCHEDULE_EDIT_MODAL_NAME } from '../modals/resumeScheduleEdit/const';
import { RESUME_TARIFFS_EDIT_MODAL_NAME } from '../modals/resumeTariffsEdit/const';
import { RESUME_OPTIONS_EDIT_MODAL_NAME } from '../modals/resumeOptionsEdit/const';
import { CLIENT_FEEDBACKS_EDIT_MODAL_NAME } from '../modals/clientFeedbacksEdit/const';
import { ModalDictionary } from './model';

export const modalsWithFullscreenMobileView: (keyof ModalDictionary)[] = [
  CLIENT_PHOTO_PICK_MODAL_NAME,
  RESUME_ABOUT_ME_EDIT_MODAL_NAME,
  RESUME_SCHEDULE_EDIT_MODAL_NAME,
  RESUME_TARIFFS_EDIT_MODAL_NAME,
  RESUME_ADDITIONAL_EDIT_MODAL_NAME,
  RESUME_OPTIONS_EDIT_MODAL_NAME,
  CLIENT_CONTACTS_EDIT_MODAL_NAME,
  CLIENT_PERSONAL_INFORMATION_EDIT_MODAL_NAME,
  CLIENT_FEEDBACKS_EDIT_MODAL_NAME,
];
