import { BoxProps } from '@cp/ds/src/components/box/model';
import { Typography } from '@cp/ds/src/components/typography';
import React from 'react';
import { IAdvantage } from '../../model';
import { AdvantageRoot } from './styled';

export interface AdvantageProps extends IAdvantage, BoxProps {
  icon?: string | React.ReactNode;
  variant?: 'white' | 'gray';
  bordered?: boolean;
  checked?: boolean;
}

export const Advantage: React.FC<AdvantageProps> = ({ icon, title, name: _, variant = 'white', bordered, ...props }) => {
  const ownerState = { variant, bordered };
  return (
    <AdvantageRoot ownerState={ownerState} {...props}>
      {icon ? (
        <Typography sx={{ display: 'inline-flex' }} variant="h2">
          {icon}
        </Typography>
      ) : null}
      <Typography variant="caption">{title}</Typography>
    </AdvantageRoot>
  );
};
