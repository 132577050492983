import { SxProps } from '@mui/material';
import React from 'react';
import { LogoIcon } from '../../icons/logo';
import { Stack } from '../stack';
import { Typography } from '../typography';
import { TrademarkProps } from './model';
export type { TrademarkProps } from './model';

const TITLE_FONT_SIZE_RATE = 0.651787477779972;
const TITLE_MARGIN_LEFT_SIZE_RATE = 0.145565870037527;

const clickableSx: SxProps = {
  WebkitTapHighlightColor: 'transparent',
  '&:focus, &:active': {
    outline: 'none',
  },
};

export const Trademark: React.FC<TrademarkProps> = (props) => {
  const { size = 1, color = 'default', sx, onClick } = props;

  return (
    <Stack
      onClick={onClick}
      {...(onClick ? { tabIndex: 0, role: 'button' } : undefined)}
      alignItems="center"
      direction="row"
      flexWrap="nowrap"
      sx={{ fontSize: `${size}rem`, ...(onClick ? clickableSx : undefined), ...sx }}
    >
      <LogoIcon color="brand pink" fontSize="inherit" />
      <Typography
        sx={{
          fontSize: `${size * TITLE_FONT_SIZE_RATE}rem`,
          ml: `${size * TITLE_MARGIN_LEFT_SIZE_RATE}rem`,
          fontWeight: 800,
          lineHeight: 1,
          color: color === 'white' ? 'white' : undefined,
        }}
      >
        YouHelp
      </Typography>
    </Stack>
  );
};
