import { CatalogItemScheduleIntervalViewModel } from '@common/model/catalog/item/schedule/interval.view';
import { CatalogItemViewModel } from '@common/model/catalog/item/view';
import { DayOfWeek } from '@common/model/dayOfWeek';
import React from 'react';

const DAY_OF_WEEK_SEQUENCE: DayOfWeek[] = [
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
  DayOfWeek.Saturday,
  DayOfWeek.Sunday,
];

export interface UseScheduleDataReturn {
  sections: ScheduleSectionDataProps[];
}

export interface ScheduleSectionDataProps {
  dayOfWeek: DayOfWeek;
  intervals: CatalogItemScheduleIntervalViewModel[];
}

export const useScheduleData = (props: {
  data: CatalogItemViewModel['schedule'];
  excludeEmptySections?: boolean;
}): UseScheduleDataReturn => {
  const { data, excludeEmptySections } = props;

  return React.useMemo(() => {
    const acc: Partial<Record<DayOfWeek, ScheduleSectionDataProps>> = {};

    // Assign intervals to appropriate section
    for (const interval of data?.intervals ?? []) {
      const { dayOfWeek } = interval;
      const section = acc[dayOfWeek];

      if (!section) {
        acc[dayOfWeek] = {
          dayOfWeek,
          intervals: [interval],
        };
      } else {
        section.intervals.push(interval);
      }
    }

    // Create sections
    let sections = DAY_OF_WEEK_SEQUENCE.map(
      (dayOfWeek) =>
        acc[dayOfWeek] ?? {
          dayOfWeek,
          intervals: [],
        },
    );

    if (excludeEmptySections) {
      sections = sections.filter((s) => s.intervals.length > 0);
    }

    // Sort intervals in sections
    for (const section of sections) {
      section.intervals.sort((a, b) => a.fromInMsFromDayStart - b.fromInMsFromDayStart);
    }

    return { sections };
  }, [data?.intervals, excludeEmptySections]);
};
