import React from 'react';

export interface IScrollContainerContextValue {
  ref: React.RefObject<Element>;
}

export const ScrollContainerContext = React.createContext<IScrollContainerContextValue>({
  ref: { current: null },
});

export const useScrollContainer = () => React.useContext(ScrollContainerContext);
