import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const ChevronDownIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="chevronDown" ref={ref as any} {...props}>
      <path
        d="M6 10.4301C6 10.0759 6.13585 9.72156 6.40913 9.44467C6.97496 8.87233 7.91629 8.84951 8.51159 9.3935L12.0104 12.5933L15.485 9.39676C16.0782 8.85076 17.0195 8.87082 17.5874 9.44166C18.1554 10.0127 18.1342 10.9179 17.5408 11.4639L13.042 15.6034C12.4683 16.1311 11.5648 16.1321 10.9893 15.6067L6.46232 11.4674C6.15541 11.1858 6 10.8088 6 10.4301Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});
