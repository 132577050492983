import { coreApi } from '@cp/shared/api/core';
import { MarkViewedRequest } from '@common/model/admin/entityView';

export const coreEntityViewApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    markViewed: builder.mutation<void, MarkViewedRequest>({
      query: (params) => ({
        url: '/admin/entity-view',
        method: 'POST',
        params,
      }),
    }),
  }),
});
