import React from 'react';
import { Box } from '@cp/ds/src/components/box';
import { Typography } from '@cp/ds/src/components/typography';
import { SxProps } from '@cp/ds';
import { DialogMessageBlockPlain } from '../../model/dialogMessageDateBlock';
import { useDialogContext } from '../context';
import { useDialogTheme } from '../theme';

export interface DialogMessagesDateBlockProps {
  block: DialogMessageBlockPlain;
  sx?: SxProps;
}
export const DialogMessagesDateBlock = ({ block, sx }: DialogMessagesDateBlockProps) => {
  const dialogTheme = useDialogTheme();
  const { MessageComponent } = useDialogContext();

  return (
    <Box sx={{ display: 'flex', flexFlow: 'column', gap: dialogTheme.listGap, ...sx }}>
      <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: 0,
            right: 0,
            zIndex: 1,
            width: 1,
            height: '1px',
            backgroundColor: dialogTheme.blockHeaderLine,
          }}
        />
        <Typography
          sx={{
            position: 'relative',
            zIndex: 2,
            display: 'inline-block',
            px: 2,
            color: dialogTheme.blockHeaderText,
            backgroundColor: dialogTheme.listBg,
          }}
          variant="caption"
        >
          {block.title}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexFlow: 'column', gap: dialogTheme.listGap }}>
        {block.messages.map((messageId) => {
          return <MessageComponent messageId={messageId} key={messageId} />;
        })}
      </Box>
    </Box>
  );
};
