import { DialogMessageViewModel } from '@common/model/dialog/message/dialogMessageViewModel';
import { MessageType } from '@common/model/dialog/message/messageType';

type MessageDescriber = (m: DialogMessageViewModel) => string;
export type MessageDescriptionConfig = Partial<Record<MessageType, MessageDescriber | string>>;

/**
 * Get description of message content
 */
export const getMessageDescription = (message: DialogMessageViewModel, config: MessageDescriptionConfig = {}): string => {
  const configured = config[message.type];
  if (typeof configured === 'string') {
    return configured;
  }
  if (typeof configured === 'function') {
    return configured(message);
  }

  switch (message.type) {
    case MessageType.Text:
      return message.payload as string;
    case MessageType.DealProposition:
      return 'Предложение сделки';
    case MessageType.VacancyResponse:
      return 'Специалист откликнулся на вакансию';
    case MessageType.NotificationFavorite:
      return 'Добавлен в избранное';
    case MessageType.NotificationUnFavorite:
      return 'Удален из избранного';
    case MessageType.NotificationPremiumExpired:
    case MessageType.NotificationPremiumExpiredInSevenDays:
    case MessageType.NotificationPremiumExpiredInThreeDays:
    case MessageType.NotificationPremiumExpiredInOneDay:
      return 'Уведомление';
    default:
      return 'Невозможно отобразить сообщение';
  }
};
