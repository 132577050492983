import { useMemo } from 'react';
import { ClientInteractionViewModel } from '@common/model/stats/clientInteractionViewModel';
import { useDispatch, useStore } from 'react-redux';
import { Threshold } from 'src/features/responseDelay';
import { IResponseDelayStorage } from '../lib';
import { selectBaseDate, selectItemById, selectThresholdItems, selectThresholdItemsCount, selectThresholdPagination, slice } from './slice';

export const useStorage = (): IResponseDelayStorage => {
  const dispatch = useDispatch();
  const store = useStore();
  return useMemo(
    () => ({
      setBaseDate: (date) => dispatch(slice.actions.setDate(date)),
      getBaseDate: () => selectBaseDate(store.getState()),
      fillThreshold: (threshold: Threshold, items: ClientInteractionViewModel[]) => dispatch(slice.actions.fill({ threshold, items })),
      addItemToThreshold: (threshold: Threshold, item: ClientInteractionViewModel) => {
        dispatch(slice.actions.add({ threshold, item }));
      },
      moveToThreshold: (threshold: Threshold, item: ClientInteractionViewModel) => {
        dispatch(slice.actions.move({ threshold, item }));
      },
      getThresholdItems: (threshold: Threshold): ClientInteractionViewModel[] => {
        return selectThresholdItems(store.getState(), threshold);
      },
      getCount: (threshold: Threshold) => selectThresholdItemsCount(store.getState(), threshold),
      deleteItem: (itemId) => {
        dispatch(slice.actions.del({ itemId }));
      },
      getItemById: (itemId) => selectItemById(store.getState(), itemId),
      getPagination: (threshold) => selectThresholdPagination(store.getState(), threshold),
      setPagination: (threshold, { totalCount, page }) => dispatch(slice.actions.updatePagination({ threshold, page, totalCount })),
      clear: () => dispatch(slice.actions.clear()),
    }),
    [dispatch, store],
  );
};
