export const getEventValue = (event: any): any => {
  if (
    typeof event !== 'object'
    || !event
    || !('target' in event && event.target)
    || typeof event.target !== 'object'
    || !event.target
  ) {
    return event;
  }

  if ('type' in event.target && event.target.type === 'checkbox' && 'checked' in event.target) {
    return event.target.checked;
  } else if ('value' in event.target) {
    return event.target.value;
  }

  return event;
};
