import React from 'react';
import { SvgIcon, SvgIconProps } from '../../../components/svgIcon';

export const TelegramIconRound = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon /* cacheName="social/round/telegramRound" */ ref={ref as any} {...props}>
      <g clipPath="url(#clip0_318_22858)">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill={props.color ?? 'url(#paint0_linear_318_22858)'}
        />
        <path
          d="M9.81539 17.5269C9.42604 17.5269 9.4922 17.3799 9.35791 17.0092L8.21295 13.241L17.0264 8.01245"
          fill={props.color ?? '#C8DAEA'}
        />
        <path
          d="M9.81448 17.5266C10.1149 17.5266 10.2477 17.3892 10.4154 17.2262L12.0178 15.668L10.019 14.4626"
          fill={props.color ?? '#A9C9DD'}
        />
        <path
          d="M10.0194 14.463L14.8628 18.0413C15.4155 18.3463 15.8144 18.1884 15.952 17.5282L17.9235 8.23776C18.1254 7.42851 17.6151 7.06147 17.0863 7.30152L5.50972 11.7654C4.71951 12.0824 4.72411 12.5232 5.36568 12.7197L8.3365 13.6469L15.2143 9.3078C15.5389 9.11091 15.8369 9.21676 15.5924 9.43383"
          fill="url(#paint1_linear_318_22858)"
        />
      </g>
      <defs>
        <linearGradient gradientUnits="userSpaceOnUse" id="paint0_linear_318_22858" x1="9.0012" x2="3.0012" y1="1.0008" y2="15">
          <stop stopColor="#37AEE2" />
          <stop offset="1" stopColor="#1E96C8" />
        </linearGradient>
        <linearGradient gradientUnits="userSpaceOnUse" id="paint1_linear_318_22858" x1="10.514" x2="11.7981" y1="12.7565" y2="16.9103">
          <stop stopColor="#EFF7FC" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
});
