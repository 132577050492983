import React from 'react';
import { SvgIcon, SvgIconProps } from '../../components/svgIcon';

export const PaymentMirIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    <SvgIcon /* cacheName="mir" */ ref={ref} {...props}>
      <path
        clipRule="evenodd"
        d="M2.22 9.001c.214-.001.851-.056 1.12.816.183.588.472 1.55.87 2.887h.162c.426-1.41.719-2.372.878-2.887.273-.881.955-.816 1.227-.816h2.104v6.267H6.437v-3.693h-.144l-1.196 3.693H3.484l-1.196-3.696h-.143v3.696H0V9h2.22Zm9.441 0v3.696h.171l1.454-3.04c.283-.604.884-.656.884-.656h2.076v6.267h-2.19v-3.696h-.17l-1.427 3.04c-.282.601-.912.656-.912.656H9.472V9h2.189Zm12.152 2.978c-.305.829-1.264 1.422-2.326 1.422H19.19v1.867h-2.082v-3.289h6.704Z"
        fill="#0F754E"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M21.587 9.001H17c.109 1.395 1.364 2.59 2.663 2.59h4.295c.248-1.16-.605-2.59-2.37-2.59Z"
        fill="url(#a)"
        fillRule="evenodd"
      />
      <defs>
        <linearGradient gradientUnits="userSpaceOnUse" id="a" x1={24} x2={17} y1={10.63} y2={10.63}>
          <stop stopColor="#1F5CD7" />
          <stop offset={1} stopColor="#02AEFF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
});
