import React from 'react';
import { MOBILE_BREAKPOINT_THRESHOLD } from '@cp/ds/src/theme';
import { EditableSection } from '@cp/ds/src/components/editableSection';
import { ApiServerEntityIDType } from '@cp/utils/apiServer/types';
import { useModal } from 'src/app/shared/hooks/useModal';
import { RESUME_OPTIONS_EDIT_MODAL_NAME } from 'src/app/shared/components/modals/resumeOptionsEdit/const';
import { useResumeData } from 'src/entities/resume';
import { OptionsList } from '../optionsList';

export interface ResumeOptionsEditableSectionProps {
  resumeId: ApiServerEntityIDType | null;
}

export const ResumeOptionsEditableSection: React.FC<ResumeOptionsEditableSectionProps> = ({ resumeId }) => {
  const { openModal } = useModal();
  const { resume, isLoadingResume: isLoading } = useResumeData({ resumeId });

  const isEmpty = !isLoading && resume?.options.length === 0;

  return (
    <EditableSection
      emptyMessage={isEmpty ? 'Преимущества не заполнены' : undefined}
      isLoading={isLoading}
      onEdit={() => {
        if (resume) {
          openModal(RESUME_OPTIONS_EDIT_MODAL_NAME, { data: resume });
        } else {
          throw new Error('Ошибка сервиса: резюме не найдено');
        }
      }}
      title="Преимущества"
    >
      {!isEmpty && resume && <OptionsList data={resume} sx={{ mt: { xs: 1, [MOBILE_BREAKPOINT_THRESHOLD]: 1.5 } }} />}
    </EditableSection>
  );
};
