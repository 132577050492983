import React from 'react';
import { OnlineStatus, OnlineStatusTag } from '@cp/ds/src/components/onlineStatus';
import { FormattingStrategy, approximateStrategy, getClientLastSeen } from '../../model';

export interface ClientOnlineStatusTagProps {
  clientProfile: { lastActivityAt: string; isOnline: boolean };
  strategy?: FormattingStrategy;
  now?: Date;
}

export const ClientOnlineStatusTag: React.FC<ClientOnlineStatusTagProps> = ({
  clientProfile,
  strategy = approximateStrategy,
  now = new Date(),
}) => {
  const onlineStatus = clientProfile.isOnline ? OnlineStatus.Online : OnlineStatus.Offline;
  const statusText = clientProfile.isOnline ? 'Онлайн' : getClientLastSeen(strategy, clientProfile.lastActivityAt, now) ?? 'Не в сети';

  return (
    <OnlineStatusTag colors={{ [OnlineStatus.Offline]: 'brand pink' }} status={onlineStatus}>
      {statusText}
    </OnlineStatusTag>
  );
};
