import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const SendIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="send" ref={ref as any} {...props}>
      <path
        d="M19.5794 11.274C19.4203 10.9369 19.1551 10.6648 18.8266 10.5016L6.58742 4.42513C5.78116 4.02435 4.81094 4.37009 4.42033 5.19732C4.22133 5.6188 4.2046 6.10682 4.37429 6.54159L6.50254 11.9995L4.37429 17.4579C4.0428 18.3142 4.45062 19.2841 5.28517 19.6241C5.47387 19.701 5.67496 19.741 5.87801 19.7419C6.12368 19.7419 6.36618 19.6851 6.58742 19.5755L18.8265 13.4974C19.6329 13.0967 19.9699 12.1013 19.5794 11.274ZM6.11646 18.5752C5.84766 18.7088 5.52428 18.5934 5.39416 18.3176C5.32784 18.1771 5.32233 18.0145 5.37892 17.8696L7.45093 12.5542H18.2442L6.11646 18.5752ZM7.45096 11.4447L5.37895 6.12937C5.29271 5.9175 5.34625 5.67282 5.51249 5.51913C5.67341 5.35826 5.91638 5.32052 6.11646 5.42538L18.2442 11.4447H7.45096Z"
        fill="currentColor"
      />
      <path
        clipRule="evenodd"
        d="M6.70229 4.19404C6.70233 4.19406 6.70225 4.19402 6.70229 4.19404L18.9413 10.2704C19.3227 10.4599 19.6293 10.7752 19.8128 11.1638C20.2617 12.1147 19.8769 13.2636 18.9414 13.7285L6.7022 19.8066C6.44554 19.9337 6.1636 20 5.87801 20C5.64186 19.9989 5.40693 19.9524 5.18778 19.8631C4.21879 19.4683 3.75293 18.3481 4.13363 17.3648L6.22555 11.9995L4.13389 6.63542C4.13388 6.63539 4.1339 6.63544 4.13389 6.63542C3.93847 6.13467 3.95765 5.57283 4.18696 5.08714C4.63757 4.13287 5.76303 3.72723 6.70229 4.19404ZM4.65369 5.30751C4.48502 5.66475 4.47076 6.07898 4.6147 6.44776L6.77953 11.9995L4.61495 17.5511C4.61491 17.5512 4.615 17.551 4.61495 17.5511C4.33291 18.2802 4.68255 19.0999 5.38256 19.3852C5.54064 19.4496 5.7089 19.483 5.87867 19.4839C6.08419 19.4838 6.28729 19.4362 6.4729 19.3442L18.7117 13.2663C18.7117 13.2663 18.7117 13.2663 18.7117 13.2663C19.3888 12.9298 19.6782 12.0878 19.346 11.3842C19.2113 11.0987 18.9874 10.8697 18.7118 10.7327C18.7118 10.7327 18.7118 10.7327 18.7118 10.7327L6.47266 4.65627C5.79942 4.32162 4.9843 4.60737 4.65369 5.30751ZM5.99665 5.65395C5.89761 5.60204 5.77687 5.61973 5.69495 5.70163L5.68781 5.70876C5.6016 5.78847 5.57169 5.91837 5.61797 6.03209L5.61942 6.03564L7.62734 11.1866H17.1438L5.99665 5.65395ZM6.23349 5.19537C5.93441 5.04013 5.57282 5.09695 5.33361 5.33308C5.09078 5.56044 5.01468 5.91667 5.13913 6.22472L7.27458 11.7028H18.2442L18.3589 11.2135L6.23349 5.19537ZM7.27455 12.2962H18.2442L18.3589 12.7854L6.23125 18.8064C5.82944 19.0059 5.35089 18.8307 5.16078 18.4278M7.62731 12.8123L5.61936 17.9633C5.58852 18.0423 5.59156 18.1313 5.62753 18.2075C5.69768 18.3562 5.86589 18.4116 6.00166 18.3441L17.1441 12.8123H7.62731ZM5.13853 17.7757C5.13854 17.7757 5.13851 17.7758 5.13853 17.7757V17.7757ZM5.13853 17.7757C5.05625 17.9865 5.06416 18.2231 5.16078 18.4278L5.13853 17.7757Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
});
