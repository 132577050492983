import React from 'react';
import { SxProps } from '@cp/ds';

export const TASK_BOARD_COLUMN_WIDTH_DEFAULT = 305;

export interface TaskBoardColumnConfig<T, S extends {} = {}> {
  key: React.Key;
  title: React.ReactNode;
  width?: number;
  itemKeyGenerator: (data: T) => React.Key;
  itemsDataSelector: (state: S) => T[];
  canLoadMoreSelector: (state: S) => boolean;
  renderItem: (data: T, index: number) => React.ReactNode;
  onLoadMore: () => Promise<void> | void;
}

export interface TaskBoardConfig<T, S extends {} = {}> {
  columns: TaskBoardColumnConfig<T, S>[];
}

export interface TaskBoardProps<T, S extends {} = {}> {
  config: TaskBoardConfig<T, S>;
  sx?: SxProps;
}
