import { DialogMemberViewModel } from '@common/model/dialog/dialogMemberViewModel';
import { CurrentUser } from '../../currentUser';
import { getDialogMemberForUser } from './getDialogMemberForUser';

/**
 * Exclude current client from members list
 */
export const getParticipants = (members: DialogMemberViewModel[], currentUser: CurrentUser): DialogMemberViewModel[] => {
  const currentUserMember = getDialogMemberForUser(members, currentUser);
  return members.filter((member) => member !== currentUserMember);
};

/**
 * Returns initial dialog participant.
 * Dialog may contain many members, but here we get member with whom dialog was started
 */
export const getInitialParticipant = (members: DialogMemberViewModel[], currentUser: CurrentUser): DialogMemberViewModel | undefined => {
  const participants = getParticipants(members, currentUser);
  const initialParticipants = participants.filter((member) => member.isInitial);

  // Current user was initial member and filtered by getParticipants
  if (initialParticipants.length === 1) {
    return initialParticipants[0];
  }

  // Current user not initial dialog member, so return dialog initiator
  const initiator = initialParticipants.find((member) => member.isInitiator);
  if (initiator) {
    return initiator;
  }

  // We have no information about members (legacy data)
  return participants[0];
};
