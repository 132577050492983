import React from 'react';
import { Box, BoxProps } from '@cp/ds/src/components/box';
import { ApiServerEntityIDType } from '@cp/utils/apiServer/types';
import { CatalogItemViewModel } from '@common/model/catalog/item/view';
import { coreCatalogItemApi } from '@cp/entities/catalogItem';
import { styled } from '@cp/ds/src/theme/styled';
import { ResumeCard } from 'src/entities/resume';

const ListRoot = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  gap: theme.spacing(1.5),
  marginBlock: 0,
  paddingInline: 0,
}));

export interface ProposalsListProps extends BoxProps {
  catalogItemId: ApiServerEntityIDType;
  onItemClick?: (m: CatalogItemViewModel) => void;
}

export const ProposalsList: React.FC<ProposalsListProps> = ({ catalogItemId, onItemClick, ...props }) => {
  const { data } = coreCatalogItemApi.endpoints.getProposals.useQuery({ catalogItemId });
  return (
    <ListRoot component="ul" {...props}>
      {data?.items.map((model) => <ResumeCard component="li" key={model.id} model={model} onActivate={onItemClick} />)}
    </ListRoot>
  );
};
