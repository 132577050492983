import { UseFormReturn } from 'react-hook-form';
import { SxProps } from '@cp/ds';
// import { IsNotEmpty, IsString } from 'class-validator';
import { ModeratorMessageFieldProps } from '../moderatorMessageField';

export class ModerationMessageFormModel {
  // @IsString()
  // @IsNotEmpty({ message: 'Требуется сообщение от модератора' })
  message: string;
  sendCopyBySms?: boolean;
}

export interface ModeratorMessageFormHandle {
  submit: () => void | Promise<void>;
  validate: () => boolean | Promise<boolean>;
}

export interface ModeratorMessageFormProps {
  onSubmit: (values: ModerationMessageFormModel, parameters: { form: UseFormReturn<ModerationMessageFormModel> }) => void | Promise<void>;
  fieldProps?: ModeratorMessageFieldProps;
  hideSubmitButton?: boolean;
  sx?: SxProps;
}
