import React, { ReactNode } from 'react';
import { Tag } from '../../../tag';
import { OnlineStatus } from '../../model';
import { OnlineStatusText } from '../onlineStatusText';
import { OnlineStatusMarker, OnlineStatusMarkerProps } from '../onlineStatusMarker';

export interface OnlineStatusTagProps {
  status: OnlineStatus;
  children?: ReactNode;
  colors?: OnlineStatusMarkerProps['colors'];
}

export const OnlineStatusTag: React.FC<OnlineStatusTagProps> = ({ status, children, colors }) => {
  return (
    <Tag color="white" iconLeft={<OnlineStatusMarker colors={colors} size="small" status={status} />}>
      <OnlineStatusText status={status}>{children}</OnlineStatusText>
    </Tag>
  );
};
