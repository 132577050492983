import React from 'react';
import { MOBILE_BREAKPOINT_THRESHOLD } from '@cp/ds/src/theme';
import { CATALOG_ITEM_ADDITIONAL_PARAMETERS_SEQUENCE } from '@common/model/catalog/item/additionalParameters';
import { EditableSection } from '@cp/ds/src/components/editableSection';
import { ResumeAdditional } from '@cp/entities/resume';
import { RESUME_ADDITIONAL_EDIT_MODAL_NAME } from 'src/app/shared/components/modals/resumeAdditionalEdit/const';
import { useModal } from 'src/app/shared/hooks/useModal';
import { useResumeData } from 'src/entities/resume';
import { ResumeAdditionalEditableSectionProps } from './model';

export const ResumeAdditionalEditableSection: React.FC<ResumeAdditionalEditableSectionProps> = ({ resumeId }) => {
  const { openModal } = useModal();
  const { resume, isLoadingResume } = useResumeData({ resumeId });

  const isEmpty =
    !isLoadingResume &&
    !resume?.translation?.attitudeTowardsPets &&
    !CATALOG_ITEM_ADDITIONAL_PARAMETERS_SEQUENCE.some((parameter) => typeof resume?.[parameter] === 'boolean');

  return (
    <EditableSection
      emptyMessage={isEmpty ? 'Доп. сведения не указаны' : undefined}
      isLoading={isLoadingResume}
      onEdit={() => {
        if (resume) {
          openModal(RESUME_ADDITIONAL_EDIT_MODAL_NAME, { data: resume });
        } else {
          throw new Error('Ошибка сервиса: резюме не найдено');
        }
      }}
      title="Дополнительно"
    >
      {!isEmpty && resume && <ResumeAdditional data={resume} sx={{ mt: { xs: 1, [MOBILE_BREAKPOINT_THRESHOLD]: 1.5 } }} />}
    </EditableSection>
  );
};
