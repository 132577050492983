import { Link } from '../link';
import { Typography } from '../typography';
import { MessageContentTemplate, TemplateRenderFunction } from './model';

export const messageContentTemplates: Record<MessageContentTemplate, { render: TemplateRenderFunction }> = {
  none: {
    render: () => null,
  },
  link: {
    render: ({ onOpenModal, templateProps: { href, hrefType, text, modalName, modalProps } }) => {
      if (href === '<modal>') {
        return (
          <Link color="pink" onClick={() => onOpenModal?.(modalName ?? '', modalProps)}>
            {text}
          </Link>
        );
      }

      return (
        <Link color="pink" external={hrefType !== 'inner'} href={href} target={hrefType === 'inner' ? undefined : '_blank'}>
          {text}
        </Link>
      );
    },
  },
  color: {
    render: ({ templateProps: { color, text } }) => <span style={{ color }}>{text}</span>,
  },
  br: {
    render: () => <br />,
  },
  bold: {
    render: ({ templateProps: { text } }) => <Typography variant="body bold">{text}</Typography>,
  },
};
