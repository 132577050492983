import React, { useState } from 'react';
import { ModalView, ModalViewProps } from '@cp/ds/src/components/modalView';
import { ApiServerEntityIDType } from '@cp/utils/apiServer/types';
import { Box } from '@cp/ds/src/components/box';
import { Link } from '@cp/ds/src/components/link';
import { Typography } from '@cp/ds/src/components/typography';
import { AdminCatalogItemViewModel } from '@common/model/catalog/item/admin.view';
import { formatVacancyDates } from '@cp/entities/vacancy';
import { ButtonGroup } from '@cp/ds/src/components/buttonGroup';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { Declination } from '@cp/shared/declination';
import { useCareerDeclination } from '@cp/entities/career';
import { Container } from '@cp/ds/src/components/container';
import { Button } from '@cp/ds/src/components/button';
import { Skeleton } from '@cp/ds/src/components/skeleton';
import { ResponsesListFeature } from 'src/features/responseList';
import { ProposalsList } from 'src/features/vacancyProposal';
import { useDialogViewContext, useOpenDialog } from 'src/features/dialog/view';
import { DeleteButton } from 'src/features/vacancy/delete';
import { generateCatalogItemPermalink, pathGenerator } from '../lib';
import { CopyButton } from './copyButton';

export interface MatchingModalProps {
  catalogItemId: ApiServerEntityIDType;
  vacancy?: AdminCatalogItemViewModel;
}

export const MatchingModal: React.FC<MatchingModalProps & Pick<ModalViewProps, 'isOpen' | 'onClose'>> = ({
  catalogItemId,
  isOpen,
  vacancy,
  onClose,
}) => {
  const [activeTab, setActiveTab] = useState<'responses' | 'proposals'>(vacancy?.matchedAt ? 'responses' : 'proposals');
  const { openDialog, ...viewerProps } = useOpenDialog();
  const { Viewer } = useDialogViewContext();
  const theme = useTheme();
  const declination = useCareerDeclination();
  const permalink = vacancy ? generateCatalogItemPermalink(vacancy, pathGenerator, { CITY_PATH_PARAMETER: 'city' }) : '';
  const profile = vacancy?.clientProfile;
  return (
    <ModalView
      buttons={
        <Container sx={{ display: 'flex', gap: 2 }}>
          <Button color="white" disabled>
            Редактировать
          </Button>
          <DeleteButton itemId={catalogItemId} />
          <CopyButton color="white" copyText={permalink} />
        </Container>
      }
      config={{ desktop: 'drawerRight', mobile: 'drawerRight' }}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box sx={{ display: 'flex', width: '560px', flexDirection: 'column', gap: 2.5, m: 5 }}>
        <Box>
          {vacancy ? (
            <Typography component="h2" sx={{ mb: 1, mt: 0 }} variant="h2">
              Найти {declination(1, vacancy.careerId, Declination.Accusative)} в {vacancy.city?.translation.namePrepositional}
            </Typography>
          ) : (
            <Skeleton>
              <Typography sx={{ mb: 1 }} variant="h2">
                Найти няню в Москве
              </Typography>
            </Skeleton>
          )}
          {vacancy ? (
            <Typography variant="body bold">{formatVacancyDates(vacancy)}</Typography>
          ) : (
            <Skeleton>
              <Typography variant="body bold">Vacancy dates</Typography>
            </Skeleton>
          )}
          <Box>
            {profile && (
              <Link href={`tel://${profile.phone}`}>
                {profile.phone} ({profile.name})
              </Link>
            )}
          </Box>
        </Box>
        <ButtonGroup
          items={[
            { label: 'Отклики', value: 'responses' },
            { label: 'Могут подойти', value: 'proposals' },
          ]}
          name="show"
          onChange={setActiveTab}
          size="small"
          sx={{ background: theme.palette.background.default }}
          value={activeTab}
        />
        <Box sx={{ display: activeTab === 'responses' ? 'block' : 'none' }}>
          <ResponsesListFeature catalogItemId={catalogItemId} onItemClick={(m) => openDialog({ clientId: m.respondentId })} />
        </Box>
        <Box sx={{ display: activeTab === 'proposals' ? 'block' : 'none' }}>
          <ProposalsList catalogItemId={catalogItemId} onItemClick={(m) => openDialog({ clientId: m.clientProfile?.id as number })} />
        </Box>
      </Box>
      <Viewer {...viewerProps} />
    </ModalView>
  );
};
