import React, { useId } from 'react';
import Popover from '@mui/material/Popover';
import { alpha } from '@mui/system';
import { /* ClickAwayListener, */ Collapse } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useTheme } from '../../hooks/useTheme';
import { SelectButton } from '../selectButton';
import { MenuItem, MenuList } from '../menuList';
import { SelectComponent, SelectItemModel, SelectStateProps } from './model';
import { SelectRoot } from './components/root';
import { SELECT_DEFAULT_PLACEHOLDER, SELECT_ITEM_LIST_HEIGHT_MAX_DEFAULT } from './const';
import { SelectDefaultValueRenderer } from './components/value';
export type { SelectStateProps } from './model';

export const Select: SelectComponent = React.forwardRef((props, ref) => {
  const {
    disabled = false,
    error = false,
    size = 'normal',
    name,
    value,
    items,
    itemListHeightMax = SELECT_ITEM_LIST_HEIGHT_MAX_DEFAULT,
    // defaultLabelId,
    placeholder = SELECT_DEFAULT_PLACEHOLDER,
    renderValue = SelectDefaultValueRenderer,
    onChange,
    renderItem,
    buttonSx,
    sx,
    keepItemsMounted,
    disablePortal,
  } = props;
  const theme = useTheme();
  const labelId = useId();
  const anchorEl = React.useRef<HTMLDivElement>();
  const [open, setOpen] = React.useState(false);

  const ariaLabelledBy = labelId;

  const valueLabel = React.useMemo(() => {
    const selectedItem = items?.find(({ value: itemValue }) => itemValue === value);
    return selectedItem?.label ?? placeholder;
  }, [items, placeholder, value]);

  React.useEffect(() => {
    if (disabled) {
      setOpen(false);
    }
  }, [disabled]);

  const getItemId = (item: SelectItemModel): string => {
    return `${labelId}-${item.value}`;
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (disabled) {
      return;
    }
    anchorEl.current = event.currentTarget;
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ownerState: SelectStateProps = {
    disabled,
    error,
    value,
    open,
    size,
  };

  return (
    <SelectRoot ownerState={ownerState} sx={sx}>
      <SelectButton
        active={open}
        ariaExpanded={open}
        ariaHasPopup="listbox"
        ariaLabelledBy={ariaLabelledBy}
        disabled={disabled}
        id={labelId}
        inputName={name}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputValue={[null, undefined].includes(value as any) ? (value as undefined | null) : String(value)}
        onClick={handleClick}
        ref={ref as unknown as undefined}
        size={size}
        sx={buttonSx}
      >
        {renderValue({ value: valueLabel })}
      </SelectButton>
      <Popover
        PaperProps={{
          tabIndex: -1,
          sx: {
            width: anchorEl.current?.getBoundingClientRect().width,
            maxHeight: itemListHeightMax,
            // top: `${anchorEl.current?.getBoundingClientRect().bottom}px !important`,
            backgroundColor: theme.palette.background.default,
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            boxShadow: `0px 4px 16px ${alpha(theme.palette.black as string, 0.13)}`,
            borderRadius: `${theme.borderRadius.normal}px`,
            '&:focus, &:active': {
              outline: 'none',
            },
          },
        }}
        TransitionComponent={Collapse}
        TransitionProps={{ sx: { '&:focus, &:active': { outline: 'none' } } } as TransitionProps}
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: 'bottom', // 10,
          horizontal: 'left',
        }}
        disablePortal={disablePortal}
        keepMounted={keepItemsMounted}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuList
          aria-activedescendant={value ? getItemId({ value, label: valueLabel }) : undefined}
          aria-labelledby={labelId}
          role="listbox"
          size={size}
          tabIndex={-1}
        >
          {items?.map((item) => {
            const itemId = getItemId(item);
            return (
              <MenuItem
                key={itemId}
                onClick={() => {
                  onChange?.(item.value);
                  handleClose();
                }}
                // ownerState={ownerState}
              >
                {renderItem?.({ item }) ?? SelectDefaultValueRenderer({ value: item.label })}
              </MenuItem>
            );
          }) ?? <MenuItem onClick={handleClose}>{placeholder}</MenuItem>}
        </MenuList>
      </Popover>
    </SelectRoot>
  );
});
