import styled from '@mui/material/styles/styled';
import { TextInputStateProps, TextInputTagProps } from '../model';

type UnstyledInputComponent = React.ComponentClass<TextInputTagProps & { ownerState: Required<TextInputStateProps> }>;

export const StyledTextInput = styled('input' as unknown as UnstyledInputComponent, {
  name: 'TextInput',
  slot: 'Input',
})(({ theme, ownerState }) => {
  const { size, disabled, startAdornment, endAdornment } = ownerState;

  const py = { 'small': 9, 'normal': 12 }[size];

  return {
    ...theme.typography[size === 'small' ? 'caption bold' : 'body'],
    color: theme.palette.text[disabled ? 'disabled' : 'primary'],
    flex: '1 1 0px',
    backgroundColor: 'inherit',
    width: '100%',
    margin: 0,
    paddingTop: py,
    paddingBottom: py,
    paddingRight: endAdornment ? 12 : 0,
    paddingLeft: startAdornment ? 12 : 0,
    border: 'none',
    '&::placeholder': {
      color: theme.palette.gray,
      ...theme.typography[size === 'small' ? 'caption' : 'body'],
    },
    '&:focus': {
      outline: 'none',
    },
  };
});
