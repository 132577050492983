import React from 'react';
import { CheckboxProps } from '@cp/ds/src/components/checkbox';
import { BoxProps } from '@cp/ds/src/components/box/model';
import { StyledAdvantage, StyledBox, StyledInput } from './styled';

export interface CheckableAdvantageProps extends CheckboxProps, BoxProps {}

export const CheckableAdvantage: React.FC<CheckableAdvantageProps> = ({ value, name, onChange, iconLeft, label, ...props }) => {
  return (
    <StyledBox component="label" {...props}>
      <StyledInput checked={value} name={name} onChange={(e) => onChange?.(e.target.checked)} type="checkbox" />
      <StyledAdvantage checked={value} icon={iconLeft} name={name as string} title={label as string} />
    </StyledBox>
  );
};
