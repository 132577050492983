import React from 'react';
import { Typography } from '@cp/ds/src/components/typography';
import { MOBILE_BREAKPOINT_THRESHOLD } from '@cp/ds/src/theme';
import { EditableSection } from '@cp/ds/src/components/editableSection';
import { useModal } from 'src/app/shared/hooks/useModal';
import { useResumeData } from 'src/entities/resume';
import { RESUME_ABOUT_ME_EDIT_MODAL_NAME } from 'src/app/shared/components/modals/resumeAboutMeEdit/const';
import { ResumeAboutMeEditableSectionProps } from './model';

export * from './model';

export const ResumeAboutMeEditableSection: React.FC<ResumeAboutMeEditableSectionProps> = ({ resumeId }) => {
  const { openModal } = useModal();
  const { resume, isLoadingResume } = useResumeData({ resumeId });

  const isEmpty = !isLoadingResume && !resume?.translation?.description;

  return (
    <EditableSection
      emptyMessage={isEmpty ? 'Обо мне' : undefined}
      isLoading={isLoadingResume}
      onEdit={() => {
        if (resume) {
          openModal(RESUME_ABOUT_ME_EDIT_MODAL_NAME, { data: resume });
        } else {
          throw new Error('Ошибка сервиса: резюме не найдено');
        }
      }}
      title="Обо мне"
    >
      {!isEmpty && (
        <Typography
          component="pre"
          sx={{
            whiteSpace: 'pre-wrap',
            mt: { xs: 1, [MOBILE_BREAKPOINT_THRESHOLD]: 1.5, wordBreak: 'break-word' },
          }}
          variant="body"
        >
          {resume?.translation?.description}
        </Typography>
      )}
    </EditableSection>
  );
};
