import { coreApi } from '@cp/shared/api/core';
import { ApiRoutePathParams } from '@common/api/routePathParams';
import { ApiRouteBody } from '@common/api/routeBody';
import { ApiRoute } from '@common/api/route';
import { generateApiUrl } from '@common/api/configure';
import { apiRouteMethodDict } from '@common/api/routeMethod';
import { AdminCatalogItemApiTag, adminCatalogItemApiTags } from 'src/entities/catalog/item';
import { AdminResumeApiTags, adminResumeApiTagTypes } from './tags';

export const adminResumeApi = coreApi
  .enhanceEndpoints({
    addTagTypes: [...adminResumeApiTagTypes, ...adminCatalogItemApiTags],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      updateResumeById: builder.mutation<
        void,
        { params: ApiRoutePathParams[ApiRoute.AdminResumesUpdate]; body: ApiRouteBody[ApiRoute.AdminResumesUpdate] }
      >({
        query: ({ params, body }) => ({
          url: generateApiUrl(ApiRoute.AdminResumesUpdate, params),
          method: apiRouteMethodDict[ApiRoute.AdminResumesUpdate],
          body,
        }),
        invalidatesTags: (_result, _error, { params: { id } }) => [
          { type: AdminResumeApiTags.Resume, id },
          { type: AdminCatalogItemApiTag.Item, id },
        ],
      }),

      createScheduleIntervalForResume: builder.mutation<
        void,
        {
          params: ApiRoutePathParams[ApiRoute.AdminResumesScheduleIntervalsCreate];
          body: ApiRouteBody[ApiRoute.AdminResumesScheduleIntervalsCreate];
        }
      >({
        query: ({ params, body }) => ({
          url: generateApiUrl(ApiRoute.AdminResumesScheduleIntervalsCreate, params),
          method: apiRouteMethodDict[ApiRoute.AdminResumesScheduleIntervalsCreate],
          body,
        }),
        invalidatesTags: (_result, _error, { params: { id } }) => [
          { type: AdminResumeApiTags.Resume, id },
          { type: AdminCatalogItemApiTag.Item, id },
        ],
      }),

      deleteScheduleIntervalForResume: builder.mutation<
        void,
        {
          params: ApiRoutePathParams[ApiRoute.AdminResumesScheduleIntervalsDelete];
          body: ApiRouteBody[ApiRoute.AdminResumesScheduleIntervalsDelete];
        }
      >({
        query: ({ params, body }) => ({
          url: generateApiUrl(ApiRoute.AdminResumesScheduleIntervalsDelete, params),
          method: apiRouteMethodDict[ApiRoute.AdminResumesScheduleIntervalsDelete],
          body,
        }),
        invalidatesTags: (_result, _error, { params: { id } }) => [
          { type: AdminResumeApiTags.Resume, id },
          { type: AdminCatalogItemApiTag.Item, id },
        ],
      }),
    }),
  });
