import React from 'react';
import { SxProps } from '@cp/ds';
import { IAdvantage } from '../../model';
import { Advantage, AdvantageProps } from '../advantage';
import { StyledList } from './styled';

export interface AdvantageListProps {
  advantages: IAdvantage[];
  advantageProps?: Partial<AdvantageProps>;
  sx?: SxProps;
}
export const AdvantageList: React.FC<AdvantageListProps> = ({ advantages, advantageProps, ...props }) => {
  return (
    <StyledList {...props}>
      {advantages.map((advantage) => (
        <Advantage component="li" key={advantage.title} {...advantage} {...advantageProps} />
      ))}
    </StyledList>
  );
};
