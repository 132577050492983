import { Popper } from '@mui/material';
import { alpha } from '@mui/system';
import useForkRef from '@mui/utils/useForkRef';
import React from 'react';
import { useTheme } from '../../../../../hooks/useTheme';
import { Paper } from '../../../../paper';
import { TextInput } from '../text';
import { useEventCallback } from '../../../../../hooks/useEventCallback';
import { AutocompleteItem } from './components/item';
import { AutocompleteList } from './components/list';
import { AUTOCOMPLETE_POPPER_BOX_SHADOW_OPACITY, AUTOCOMPLETE_QUERY_MIN_LENGTH_TO_REQUEST_SUGGESTIONS_DEFAULT } from './const';
import { AutocompleteInputProps, AutocompleteListItem } from './model';

export * from './model';

export const AUTOCOMPLETE_FIELD_BLUR_LATENCY = 100;
export const AUTOCOMPLETE_FIELD_SUGGESTIONS_LIST_MAX_HEIGHT = 200;

// eslint-disable-next-line prettier/prettier, @typescript-eslint/no-explicit-any
const defaultGetListItemKey = function<V = any>(value: V) {
  return String(value);
};

export const AutocompleteInput = React.forwardRef<HTMLInputElement, AutocompleteInputProps>((props, ref) => {
  // Value is not used yet, but we don't want to replace this prop in TextInput, so use it in spread...
  const {
    getLabel,
    onQueryChanged,
    onRequestSuggestions,
    onChange,
    value,
    onFocus,
    onBlur,
    getKey = defaultGetListItemKey,
    queryMinLenthToRequestSuggestions = AUTOCOMPLETE_QUERY_MIN_LENGTH_TO_REQUEST_SUGGESTIONS_DEFAULT,
    resetQueryOnFocus = false,
    rollbackQueryOnNotSelected = false,
    ...restProps
  } = props;
  const theme = useTheme();
  const [list, setList] = React.useState<AutocompleteListItem[]>([]);
  const [query, setQuery] = React.useState(value ? getLabel(value) : '');
  const [isOpen, setOpen] = React.useState(false);
  const [isFocused, setFocused] = React.useState(false);
  const anchorElRef = React.useRef<HTMLDivElement>();
  const inputRef = useForkRef(anchorElRef, ref);
  const anchorEl = anchorElRef.current;
  const getLabelMemoized = useEventCallback(getLabel);
  const onQueryChangedMemoized = useEventCallback(onQueryChanged);

  const valueRef = React.useRef(value);
  React.useEffect(() => {
    valueRef.current = value;

    const label = value ? getLabelMemoized?.(value) ?? '' : '';
    setQuery(label);
    onQueryChangedMemoized?.(label);
  }, [getLabelMemoized, onQueryChangedMemoized, value]);

  React.useEffect(() => {
    // Preload list
    if (!queryMinLenthToRequestSuggestions) {
      onRequestSuggestions('');
    }
  }, [onRequestSuggestions, queryMinLenthToRequestSuggestions]);

  React.useEffect(() => {
    setTimeout(() => {
      setOpen(Boolean(list?.length) && isFocused);
    }, AUTOCOMPLETE_FIELD_BLUR_LATENCY);
  }, [isFocused, list]);

  const updateList = async (queryForUpdate: string) => {
    try {
      if (queryForUpdate.length < queryMinLenthToRequestSuggestions) {
        throw new Error('Query is too short');
      }
      const newList = await onRequestSuggestions(queryForUpdate);
      setList(newList);
    } catch {
      setList([]);
    }
  };

  const handleFocus: React.FocusEventHandler<HTMLInputElement> = (event) => {
    onFocus?.(event);
    setFocused(true);

    let currentQuery = query;

    if (resetQueryOnFocus) {
      setQuery('');
      currentQuery = '';
    }

    updateList(currentQuery);
  };

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
    // Use deferred focus state update due to list item click event not processed instead
    setTimeout(() => {
      onBlur?.(event);
      setFocused(false);

      setTimeout(() => {
        if (valueRef.current) {
          const label = getLabelMemoized?.(valueRef.current) ?? '';

          if (event.target.value === label) {
            return;
          }

          if (rollbackQueryOnNotSelected) {
            setQuery(label);
          } else {
            onChange?.(null);
          }
        }
      }, AUTOCOMPLETE_FIELD_BLUR_LATENCY);
    }, AUTOCOMPLETE_FIELD_BLUR_LATENCY);
  };

  const handleQueryChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    anchorElRef.current = event?.target;

    const newQuery = event?.target?.value;
    setQuery(newQuery);
    onQueryChangedMemoized?.(newQuery);

    if (newQuery.length < queryMinLenthToRequestSuggestions) {
      setList([]);
      return;
    }

    updateList(newQuery);
  };

  const handleValueChange = (selectedItem: AutocompleteListItem) => {
    onChange?.(selectedItem);
  };

  return (
    <React.Fragment>
      <TextInput
        autoComplete="off"
        htmlType="text"
        onBlur={handleBlur}
        onChange={handleQueryChange}
        onFocus={handleFocus}
        placeholder="Text"
        rootRef={inputRef}
        spellCheck="false"
        value={query}
        {...restProps}
      />
      {isOpen && (
        <Popper
          anchorEl={anchorEl}
          open
          role="presentation"
          style={{
            width: anchorEl ? anchorEl.clientWidth : undefined,
            zIndex: 100,
          }}
        >
          <Paper
            sx={{
              width: anchorEl?.getBoundingClientRect().width,
              backgroundColor: theme.palette.background.default,
              boxShadow: `0px 4px 16px ${alpha(theme.palette.black as string, AUTOCOMPLETE_POPPER_BOX_SHADOW_OPACITY)}`,
              borderRadius: `${theme.borderRadius.normal}px`,
              overflow: 'hidden',
              '&:focus, &:active': {
                outline: 'none',
              },
            }}
          >
            <AutocompleteList
              // ToDo: adjust aria attributes
              // aria-activedescendant={value ? getItemId({ value, label: valueLabel }) : undefined}
              // aria-labelledby={labelId}
              role="listbox"
              sx={{
                overflowX: 'hidden',
                overflowY: 'auto',
                maxHeight: AUTOCOMPLETE_FIELD_SUGGESTIONS_LIST_MAX_HEIGHT,
              }}
            >
              {list?.map((item) => {
                return (
                  <AutocompleteItem key={getKey(item)} onClick={() => handleValueChange(item)} role="button" tabIndex={0}>
                    {getLabel(item)}
                  </AutocompleteItem>
                );
              })}
            </AutocompleteList>
          </Paper>
        </Popper>
      )}
    </React.Fragment>
  );
});
