import React from 'react';
import { DotMark, DotMarkProps } from '../../../dotMark';
import { OnlineStatus } from '../../model';
import { useTheme } from '../../../../hooks/useTheme';
import { mergeSx } from '../../../../utils/sx/merge';

export interface OnlineStatusMarkerProps extends Omit<DotMarkProps, 'color'> {
  status: OnlineStatus;
  colors?: Partial<Record<OnlineStatus, string>>;
}

export const OnlineStatusMarker: React.FC<OnlineStatusMarkerProps> = ({ status, colors, sx, ...props }) => {
  const theme = useTheme();
  const colorMap = { [OnlineStatus.Online]: theme.palette.green, [OnlineStatus.Offline]: 'transparent', ...colors };
  return (
    <DotMark
      color={colorMap[status]}
      sx={mergeSx(
        {
          border: `1px solid ${status === OnlineStatus.Online ? theme.palette.white : 'transparent'}`,
        },
        sx,
      )}
      {...props}
    />
  );
};
