import { createListenerMiddleware } from '@reduxjs/toolkit';
import { AuthenticationSliceShape, authenticationTokenSelector } from '@cp/shared/api/core';
import { DialogEventType } from '@common/model/dialog/dialogEventType';
import { DialogMessageEvent } from '@common/model/dialog/events/dialogMessage.event';
import { DialogMemberJoinedEvent } from '@common/model/dialog/events/dialogMemberJoined.event';
import { DialogMemberLeavedEvent } from '@common/model/dialog/events/dialogMemberLeaved.event';
import { DialogMemberOnlineEvent } from '@common/model/dialog/events/dialogMemberOnline.event';
import { DialogMemberOfflineEvent } from '@common/model/dialog/events/dialogMemberOffline.event';
import { DialogMessageDeliveredEvent } from '@common/model/dialog/events/dialogMessageDelivered.event';
import { DialogMessageReadEvent } from '@common/model/dialog/events/dialogMessageRead.event';
import { DialogMessageReceivedEvent } from '@common/model/dialog/events/dialogMessageReceivedEvent';
import { IConfigManager } from '@cp/shared/config';
import { CoreDialogApiTags, coreDialogApi, createSocket } from '../../api';
import { dialogSlice } from './dialog';

export const createDialogMiddleware = (extra: { configManager: IConfigManager }) => {
  const dialogMiddleware = createListenerMiddleware<AuthenticationSliceShape>({ extra });
  dialogMiddleware.startListening({
    predicate: (_, currentState) => Boolean(authenticationTokenSelector(currentState as AuthenticationSliceShape)),
    effect: (_, { unsubscribe, dispatch, getState }) => {
      unsubscribe();
      const authToken = authenticationTokenSelector(getState()) as string;
      const socket = createSocket({ authToken }, extra.configManager);
      socket.connect();
      socket.on(DialogEventType.NewMessage, (event: DialogMessageEvent) => {
        dispatch(
          coreDialogApi.util.invalidateTags([
            CoreDialogApiTags.UnreadMessagesCount,
            { type: CoreDialogApiTags.Messages, id: event.payload.dialogId },
            { type: CoreDialogApiTags.Dialog, id: event.payload.dialogId },
          ]),
        );
        dispatch(dialogSlice.actions.messageAdded(event.payload));
        const deliveryReport = new DialogMessageReceivedEvent({
          dialogId: event.payload.dialogId,
          messageId: event.payload.message.id,
        });
        socket.emit(deliveryReport.type, deliveryReport);
      });
      socket.on(DialogEventType.MessageDelivered, (event: DialogMessageDeliveredEvent) => {
        dispatch(dialogSlice.actions.messageDelivered(event));
      });
      socket.on(DialogEventType.MessageRead, (event: DialogMessageReadEvent) => {
        dispatch(coreDialogApi.util.invalidateTags([CoreDialogApiTags.UnreadMessagesCount]));
        dispatch(dialogSlice.actions.messageRead(event));
      });
      socket.on(DialogEventType.MemberJoined, (event: DialogMemberJoinedEvent) => {
        dispatch(dialogSlice.actions.memberJoined(event));
      });
      socket.on(DialogEventType.MemberLeaved, (event: DialogMemberLeavedEvent) => {
        dispatch(dialogSlice.actions.memberLeaved(event));
      });
      socket.on(DialogEventType.MemberOnline, (event: DialogMemberOnlineEvent) => {
        dispatch(dialogSlice.actions.memberOnline(event));
      });
      socket.on(DialogEventType.MemberOffline, (event: DialogMemberOfflineEvent) => {
        dispatch(dialogSlice.actions.memberOffline(event));
      });
      socket.on(DialogEventType.DialogCreated, () => {
        dispatch(coreDialogApi.util.invalidateTags([{ type: CoreDialogApiTags.DialogList }]));
      });
    },
  });
  return dialogMiddleware.middleware;
};
