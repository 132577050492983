import React from 'react';
import { useDispatch } from 'react-redux';
import { useIsMobile } from '../../hooks/useIsMobile';
import { Box } from '../box';
import { mergeSx } from '../../utils/sx/merge';
import { ModalViewBaseProps, ModalViewProps, ModalViewVariant } from './model';
import { CloseButton } from './components/closeButton';
import { Buttons } from './components/buttons';
import { modalViewSlice } from './store';
import DrawerBottomView from './view.drawerBottom';
import DrawerRightView from './view.drawerRight';
import FullscreenView from './view.fullscreen';
import DialogView from './view.dialog';

const VARIANT_TO_VIEW: Record<ModalViewVariant, React.ComponentType<ModalViewBaseProps>> = {
  drawerBottom: DrawerBottomView,
  drawerRight: DrawerRightView,
  fullscreen: FullscreenView,
  dialog: DialogView,
};

/**
 * ModalView
 *
 * Component, which renders children inside modal window (drawer, fullscreen).
 * Modal window variant is based on passed `config` property.
 *
 * @param config Object with device types (based on screen width) as keys and appropriate modal variants as values
 * @param showCloseButton Boolean, if false specified - close button will not be rendered
 * @param buttons ReactNode, which will be rendered inside `ButtonsPanel` design system component
 * with absolute bottom position. Bottom spacing for `ModalView` children will be added automatically in that case,
 * which will be equal to `buttons` container height.
 */
export const ModalView: React.FC<ModalViewProps> = ({
  config,
  children,
  showCloseButton = true,
  buttons,
  buttonsSx,
  containerSx,
  scrollableContainerSx,
  ...restProps
}) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const id = React.useId();

  React.useEffect(() => {
    if (restProps.isOpen) {
      dispatch(modalViewSlice.actions.trackViewOpened(id));
    } else {
      dispatch(modalViewSlice.actions.trackViewClosed(id));
    }
  }, [dispatch, restProps.isOpen, id]);

  React.useEffect(
    () => () => {
      dispatch(modalViewSlice.actions.trackViewClosed(id));
    },
    [dispatch, id],
  );

  const variant = isMobile ? config.mobile : config.desktop;
  const View = VARIANT_TO_VIEW[variant];

  return (
    <View {...restProps}>
      <Box
        sx={mergeSx(
          {
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            height: '100%',
            maxHeight: '100%',
            overflow: 'hidden',
            backgroundColor: 'background.paper',
          },
          containerSx,
        )}
      >
        {showCloseButton && <CloseButton onClick={restProps.onClose} />}
        <Box
          sx={mergeSx(
            {
              flexGrow: 1,
              height: '100%',
              maxHeight: '100%',
              overflowY: 'auto',
            },
            scrollableContainerSx,
          )}
        >
          {children}
          {buttons ? <Buttons sx={buttonsSx}>{buttons}</Buttons> : null}
        </Box>
      </Box>
    </View>
  );
};
