import { coreApi } from '@cp/shared/api/core';
import { generateApiUrl } from '@common/api/configure';
import { ApiRoute } from '@common/api/route';
import { ClientSubscriptionsRequest, ClientSubscriptionsResponse } from '@common/model/stats/clientSubscriptions.request';

export const coreSubscriptionsStats = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getSubscriptionStats: builder.query<ClientSubscriptionsResponse, ClientSubscriptionsRequest>({
      query: () => ({
        url: generateApiUrl(ApiRoute.StatsClientSubscriptions),
      }),
    }),
  }),
});
