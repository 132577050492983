import React from 'react';
import { TextareaAutosize as MuiTextArea } from '@mui/base/TextareaAutosize';
import { TextAreaAutoSizeProps } from '../model';
import { styled } from '../../../../../../theme';

const TextAreaUnstyled = React.forwardRef<HTMLTextAreaElement, TextAreaAutoSizeProps>((props: TextAreaAutoSizeProps, ref) => (
  <MuiTextArea ref={ref} size="normal" {...props} />
));

export const StyledTextAreaAutoSize = styled(TextAreaUnstyled, {
  name: 'TextArea',
  slot: 'Input',
})(({ theme, disabled, size = 'normal' }) => {
  const py = { 'small': 8, 'normal': 12 }[size];
  const px = { 'small': 16, 'normal': 24 }[size];

  return {
    ...theme.typography['body'],
    resize: 'none',
    color: theme.palette.text[disabled ? 'disabled' : 'primary'],
    backgroundColor: 'inherit',
    width: '100%',
    paddingTop: py,
    paddingBottom: py,
    paddingRight: px,
    paddingLeft: px,
    border: 'none',
    borderRadius: 0,
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: theme.palette.secondary,
    },
  };
});
