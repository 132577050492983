import React from 'react';
import { PaymentSystem } from '@youhelp/common/const/payment';
import { SvgIconProps } from '../../components/svgIcon';
import { PaymentMastercardIcon } from './mastercard';
import { PaymentMirIcon } from './mir';
import { PaymentVisaIcon } from './visa';

const paymentSystemToIcon: Partial<
  Record<PaymentSystem, React.ForwardRefExoticComponent<SvgIconProps & React.RefAttributes<SVGSVGElement>>>
> = {
  [PaymentSystem.MasterCard]: PaymentMastercardIcon,
  [PaymentSystem.Mir]: PaymentMirIcon,
  [PaymentSystem.Visa]: PaymentVisaIcon,
};

export const PaymentSystemIcon = React.forwardRef<
  SVGSVGElement,
  SvgIconProps & {
    paymentSystem?: PaymentSystem;
    defaultIcon?: React.ForwardRefExoticComponent<SvgIconProps & React.RefAttributes<SVGSVGElement>>;
  }
>(({ paymentSystem, defaultIcon, ...restProps }, ref) => {
  const Icon = paymentSystemToIcon[paymentSystem ?? PaymentSystem.Unknown] ?? defaultIcon;

  if (Icon) {
    return <Icon ref={ref} {...restProps} />;
  }

  return null;
});
