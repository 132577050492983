import React from 'react';
import { Checkbox } from '../../../../checkbox';
import { Grid } from '../../../../grid';
import { CheckboxGroupInputProps, CheckboxGroupItemValueConstraint, CheckboxGroupValue } from './model';

export const CheckboxGroupInput = React.forwardRef<HTMLDivElement, CheckboxGroupInputProps<CheckboxGroupItemValueConstraint>>(
  <K extends CheckboxGroupItemValueConstraint>(
    {
      items,
      value = {} as CheckboxGroupValue<K>,
      onChange,
      error = false, // eslint-disable-line @typescript-eslint/no-unused-vars
      disabled = false,
      multiple = true,
      containerProps,
      itemSx,
      checkboxComponent,
    }: CheckboxGroupInputProps<K>,
    ref,
  ) => {
    const CheckboxComponent = checkboxComponent || Checkbox;
    const handleChange = (itemId: K) => (newItemValue: boolean) => {
      if (!onChange) {
        return;
      }

      if (multiple) {
        onChange({ ...value, [itemId]: newItemValue });
      } else {
        onChange({ [itemId]: newItemValue } as CheckboxGroupValue<K>);
      }
    };

    return (
      <Grid container ref={ref} rowSpacing={2} spacing={1} {...containerProps}>
        {items.map(({ value: id, label, icon, disabled: itemDisabled }) => (
          <Grid item key={id} sx={itemSx}>
            <CheckboxComponent
              disabled={disabled || itemDisabled}
              iconLeft={icon}
              label={label}
              name={String(id)}
              onChange={handleChange(id)}
              sx={{ justifyContent: 'center', width: 1 }}
              value={value[id]}
            />
          </Grid>
        ))}
      </Grid>
    );
  },
) as <K extends CheckboxGroupItemValueConstraint>(
  props: CheckboxGroupInputProps<K> & React.RefAttributes<HTMLDivElement>,
) => ReturnType<React.FC>;
