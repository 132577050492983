import React from 'react';
import useResizeObserver from 'use-resize-observer';
import { ButtonsPanel, ButtonsPanelProps } from '../../../buttonsPanel';
import { Box } from '../../../box';

export interface ButtonsProps extends ButtonsPanelProps {}

export const Buttons: React.FC<ButtonsProps> = ({ children, sx }) => {
  const buttonsPanelRef = React.useRef<HTMLDivElement>(null);
  const { height } = useResizeObserver<HTMLElement>({
    ref: buttonsPanelRef,
    box: 'border-box',
  });

  return (
    <>
      <Box component="div" sx={{ height }} />
      <ButtonsPanel position="bottom-absolute" ref={buttonsPanelRef} sx={sx}>
        {children}
      </ButtonsPanel>
    </>
  );
};
