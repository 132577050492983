import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const LogoIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="logo" ref={ref as any} {...props}>
      <path d="M13.3675 20.2769L13.988 19.7394C14.0545 19.684 14.121 19.6286 14.1875 19.5732C15.2957 18.6257 16.2543 17.8056 16.9524 16.969C17.8113 15.9328 18.2102 14.9299 18.2102 13.8107C18.2102 12.7136 17.8279 11.6996 17.1353 10.946C16.4205 10.1647 15.4342 9.73809 14.3648 9.73809C13.5669 9.73809 12.8023 9.99851 12.1595 10.4972C12.1041 10.5415 12.0487 10.5858 11.9988 10.6302C11.949 10.5858 11.8936 10.5415 11.8382 10.4972C11.1954 9.99851 10.4363 9.73809 9.63288 9.73809C8.56349 9.73809 7.58275 10.1647 6.86243 10.946C6.16982 11.6996 5.7875 12.7191 5.7875 13.8107C5.7875 14.9244 6.18644 15.9273 7.04528 16.969C7.73789 17.8112 8.69647 18.6257 9.81019 19.5732C9.87114 19.6286 9.93763 19.684 10.0041 19.7394L10.0374 19.7671L11.406 18.5758C11.2674 18.4595 11.14 18.3487 11.0181 18.2434C8.8904 16.426 7.60491 15.3289 7.60491 13.8439C7.60491 12.5529 8.44159 11.6165 9.5941 11.6165C10.6469 11.6165 11.068 11.9988 11.3506 12.2093C11.6331 12.4199 11.9656 12.8355 11.9933 13.1014C12.021 12.8355 12.3147 12.4476 12.6361 12.2093C12.9574 11.9711 13.5115 11.6165 14.3925 11.6165C15.545 11.6165 16.3817 12.5529 16.3817 13.8439C16.3817 15.3289 15.0962 16.426 12.9685 18.2434C12.6915 18.4816 12.3867 18.7365 12.0709 19.0136L10.6192 20.2824L10.4696 20.4154L10.4529 20.432L9.74924 21.0415H6.70175C5.53816 21.0415 4.5962 20.0996 4.5962 18.936V10.1426L2.7123 11.9766V18.9249C2.7123 21.1246 4.50201 22.9144 6.70175 22.9144H10.0207C10.2258 22.9088 10.4308 22.8368 10.597 22.6927L12.0044 21.4682L13.3675 20.2769Z" />
      <path d="M23.7069 10.619L23.6515 10.5636L17.7892 4.88417L17.7726 4.86755L15.0742 2.25224C14.2486 1.44327 13.1515 1 11.9934 1C10.8354 1 9.73828 1.44327 8.90714 2.25224L0.468348 10.4362L0.285498 10.6135C-0.0857423 10.9736 -0.0968241 11.572 0.263335 11.9433C0.623493 12.3145 1.22745 12.3367 1.59869 11.971L2.70687 10.8905L4.59078 9.05647L7.51638 6.20845L10.2148 3.60422C11.2122 2.64011 12.7692 2.64011 13.7665 3.60422L16.4705 6.22507L19.3905 9.06201V18.9304C19.3905 20.094 18.4486 21.0359 17.285 21.0359H14.2375L13.9549 20.7921L12.5863 21.9889L13.4008 22.6982C13.5837 22.8533 13.8053 22.9254 14.0269 22.9198H17.285C19.4847 22.9198 21.2744 21.1301 21.2744 18.9304V10.8961L22.3937 11.9821C22.7649 12.3422 23.3689 12.3256 23.7291 11.9544C24.0892 11.5776 24.0781 10.9792 23.7069 10.619Z" />
    </SvgIcon>
  );
});
