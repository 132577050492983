import { coreApi } from '@cp/shared/api/core';
import { ApiRouteQueryParams } from '@common/api/routeQueryParams';
import { ClientInteractionsResponse } from '@common/model/stats/clientInteractions.response';
import { ApiRoute } from '@common/api/route';
import { apiRouteMethodDict } from '@common/api/routeMethod';
import { generateApiUrl } from '@common/api/configure';
import { ApiServerEntityIDType } from '@cp/utils/apiServer/types';

export type QueryParams = ApiRouteQueryParams[ApiRoute.StatsClientInteractionsGet];

export const clientInteractionCoreApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getClientInteractions: builder.query<ClientInteractionsResponse, QueryParams>({
      query: (params) => ({
        method: apiRouteMethodDict[ApiRoute.StatsClientInteractionsGet],
        url: generateApiUrl(ApiRoute.StatsClientInteractionsGet),
        params,
      }),
    }),
    markInteractionViewed: builder.mutation<void, { id: ApiServerEntityIDType }>({
      query: (params) => ({
        method: apiRouteMethodDict[ApiRoute.StatsClientInteractionModeratorView],
        url: generateApiUrl(ApiRoute.StatsClientInteractionModeratorView, params),
      }),
    }),
  }),
});
