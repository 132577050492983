import { AppThemeName } from '@cp/ds';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DEFAULT_THEME_NAME } from '../../../app/shared/const/layout';

export interface ThemeState {
  name: AppThemeName;
}

const initialState: ThemeState = {
  name: DEFAULT_THEME_NAME,
};

export const themeSlice = createSlice({
  name: '@@app/theme',
  initialState,
  reducers: {
    setTheme(state, action: PayloadAction<ThemeState['name']>): void {
      state.name = action.payload;
    },
  },
});
