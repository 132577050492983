/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty-function */
import React, { useCallback, useMemo, useState } from 'react';
import { AdminCatalogItemViewModel } from '@common/model/catalog/item/admin.view';
import { CareerTag } from '@cp/entities/career';
import { format } from 'date-fns';
import { DateFormat } from '@common/const/date';
import { useVacancyDates } from '@cp/entities/vacancy';
import { Typography } from '@cp/ds/src/components/typography';
import { EntityType } from '@common/model/admin/entityView';
import { Badge } from '@cp/ds/src/components/badge';
import { FireIcon } from '@cp/ds/src/icons';
import { coreResponsesApi } from '@cp/entities/response';
import { CardProps } from 'src/shared/ui/card';
import { VacancyCard as BaseCard, BotCreatedTag, isCreatedByBot } from 'src/entities/vacancy';
import { useMarkViewedDecorator } from 'src/features/entityView';

export interface VacancyCardProps extends CardProps {
  model: AdminCatalogItemViewModel;
  onActivate?: (m: AdminCatalogItemViewModel) => void;
}
export const VacancyCard: React.FC<VacancyCardProps> = ({ model, onActivate = () => {}, ...props }) => {
  const { data: responses } = coreResponsesApi.endpoints.getCatalogItemResponses.useQuery({ catalogItemId: model.id });
  const dates = useVacancyDates(model);
  const city = model.city?.translation.name;
  const withMarkViewed = useMarkViewedDecorator(EntityType.CatalogItem);
  const onClick = useCallback(() => withMarkViewed(onActivate)(model), [model, withMarkViewed, onActivate]);
  const botTag = useMemo(() => (isCreatedByBot(model) ? <BotCreatedTag /> : null), [model]);
  const responseCounter = useMemo(() => {
    return responses?.totalCount ? (
      <Badge badgeContent={responses.totalCount} color="pink">
        <FireIcon />
      </Badge>
    ) : null;
  }, [responses?.totalCount]);

  return (
    <BaseCard
      badges={responseCounter}
      footer={format(new Date(model.createdAt), DateFormat.DateTimeModerationTask)}
      matched={'matchedAt' in model}
      onClick={onClick}
      tags={
        <>
          <CareerTag careerId={model.careerId} />
          {botTag}
        </>
      }
      title={dates}
      viewed={model.isViewed}
      {...props}
    >
      {city && <Typography>{city}</Typography>}
    </BaseCard>
  );
};
