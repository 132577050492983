import styled from '@mui/material/styles/styled';
import { DivTagProps, SelectStateProps, SelectValueConstraint } from '../model';

type UnstyledDivComponent<T extends SelectValueConstraint = SelectValueConstraint> = React.ComponentClass<
  DivTagProps & { ownerState: SelectStateProps<T> }
>;

export const SelectRoot = styled('div' as unknown as UnstyledDivComponent, {
  name: 'Select',
  slot: 'Root',
})({
  position: 'relative',
});
