import React from 'react';
import { ResumeCard as BaseResume, ResumeAuthorAge, ResumeAuthorName, ResumeCardTitle } from '@cp/entities/resume';
import { CatalogItemViewModel } from '@common/model/catalog/item/view';
import { Image } from '@cp/ds/src/components/image';
import { useScrollContainer } from 'src/shared/ui/scrollContainer';

export interface ResumeCardProps {
  component?: React.ElementType;
  onActivate?: (m: CatalogItemViewModel) => void;
  model: CatalogItemViewModel;
}

export const ResumeCard: React.FC<ResumeCardProps> = ({ model, onActivate, ...props }) => {
  const imageAsset = model?.clientProfile?.mainPhoto?.image;
  const { ref } = useScrollContainer();
  return (
    <BaseResume
      header={
        <ResumeCardTitle>
          <ResumeAuthorName author={model?.clientProfile || {}} />
          <ResumeAuthorAge age={model?.clientProfile?.age} />
        </ResumeCardTitle>
      }
      onClick={() => onActivate?.(model)}
      profilePhoto={
        imageAsset && (
          <Image
            alt=""
            asset={imageAsset}
            lazyLoadProps={{ scrollContainer: ref.current || undefined, overflow: true }}
            sx={{ maxWidth: '100%', height: 'auto', display: 'block', margin: 'auto' }}
          />
        )
      }
      {...props}
    />
  );
};
