/* eslint-disable @typescript-eslint/no-magic-numbers */
import styled from '@mui/material/styles/styled';
import { shouldForwardProp } from '@mui/system';
import { BUTTON_GROUP_LABEL_BORDER_WIDTH } from '../const';
import { ButtonGroupItemLabelStateProps } from '../model';

type UnstyledLabelComponent = React.ComponentClass<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> & {
    ownerState: Required<ButtonGroupItemLabelStateProps>;
  }
>;

export const StyledLabel = styled('label' as unknown as UnstyledLabelComponent, {
  shouldForwardProp,
  name: 'Radio',
  slot: 'Label',
})(({ theme, ownerState }) => {
  const borderLeftRadius: string = ownerState.isFirstItem ? `${theme.borderRadius.normal}px` : 'none';
  const borderRightRadius: string = ownerState.isLastItem ? `${theme.borderRadius.normal}px` : 'none';
  const borderRight: string = ownerState.isLastItem ? `${BUTTON_GROUP_LABEL_BORDER_WIDTH}px solid ${theme.palette['gray light']}` : 'none';
  return {
    display: 'inline-flex',
    justifyContent: 'center',
    padding: `${ownerState.size === 'small' ? 8 : 13}px ${ownerState.size === 'small' ? 0 : 0}px`, // 32 : 46
    position: 'relative',
    cursor: ownerState.disabled ? 'not-allowed' : 'pointer',
    textAlign: 'center',
    borderTopLeftRadius: borderLeftRadius,
    borderBottomLeftRadius: borderLeftRadius,
    borderTopRightRadius: borderRightRadius,
    borderBottomRightRadius: borderRightRadius,
    border: `${BUTTON_GROUP_LABEL_BORDER_WIDTH}px solid ${theme.palette['gray middle']}`,
    borderRight,
    backgroundColor: ownerState.checked ? (ownerState.disabled ? theme.palette['gray light'] : theme.palette['brand pink']) : 'transparent',
    color: ownerState.checked ? theme.palette.white : ownerState.disabled ? theme.palette['gray light'] : theme.palette['brand gray'],
    transition: theme.transitions.create(['background-color', 'border-color', 'color'], {
      duration: theme.transitions.duration.short,
    }),

    '@media (pointer: fine)': {
      '&:hover': {
        borderColor: theme.palette.gray,
      },
      '&:hover + *': {
        borderLeftColor: theme.palette.gray,
      },
    },
  };
});
