import { Box } from '@cp/ds/src/components/box';
import { styled } from '@cp/ds/src/theme/styled';
import { Advantage } from '../advantage';

export const StyledInput = styled('input')({
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  visibility: 'hidden',
});

export const StyledBox = styled(Box)({
  position: 'relative',
  display: 'inline-flex',
  cursor: 'pointer',
});

export const StyledAdvantage = styled(Advantage)<{ checked?: boolean }>(({ theme, checked }) => ({
  border: `1px solid ${theme.palette['gray light']}`,
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  flexGrow: 1,
  ...(checked && {
    borderColor: theme.palette['brand gray'],
    backgroundColor: theme.palette['brand gray'],
    color: theme.palette.white,
  }),
}));
