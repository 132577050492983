import { Threshold } from './thresholds';

export type DateRange = [Date | undefined, Date | undefined];

export const getThresholdRelativeTime = (threshold: Threshold, now = new Date()): Date => {
  return new Date(now.getTime() - threshold.minDuration);
};

export const getNextThreshold = (thresholds: Threshold[], current: Threshold): Threshold | undefined => {
  const i = thresholds.findIndex((v) => v === current);
  return thresholds[i + 1];
};

export const getPrevThreshold = (thresholds: Threshold[], current: Threshold): Threshold | undefined => {
  const i = thresholds.findIndex((v) => v === current);
  return thresholds[i - 1];
};

export const getThresholdRelativeDateRange = (thresholds: Threshold[], currentThreshold: Threshold, now = new Date()): DateRange => {
  const range: DateRange = [undefined, undefined];
  const nextThreshold = getNextThreshold(thresholds, currentThreshold);
  if (nextThreshold) {
    range[0] = getThresholdRelativeTime(nextThreshold, now);
  }
  if (currentThreshold.minDuration > 0) {
    range[1] = getThresholdRelativeTime(currentThreshold, now);
  }
  return range;
};
