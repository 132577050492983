import styled from '@mui/material/styles/styled';

export const AccordionRoot = styled('div', {
  name: 'AccordionRoot',
  slot: 'Root',
})(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette['gray light']}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'stretch',
}));
