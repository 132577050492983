import React from 'react';
import { Button } from '@cp/ds/src/components/button';
import { ModerationStatus } from '@common/model/moderation/moderationStatus';
import { Skeleton } from '@cp/ds/src/components/skeleton';
import { ApiServerError } from '@cp/utils/rtk/query/model';
import { SerializedError } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import type { ThunkDispatch } from 'redux-thunk';
import { coreModerationApi } from 'src/entities/moderation';
import { AdminCatalogItemApiTag, adminCatalogItemApi } from 'src/entities/catalog/item';
import { useApiServerErrorHandler } from 'src/app/shared/hooks/useApiServerErrorHandler';
import { ClientPatronageApiTag, clientPatronageApi } from 'src/entities/client/patronage';
import { MODERATION_MESSAGE_MODAL_NAME } from 'src/app/shared/components/modals/moderationMessage/const';
import { useModal } from 'src/app/shared/hooks/useModal';
import { ModerationUpdateForResumeButtonProps } from './model';

export const ModerationUpdateForResumeButton: React.FC<ModerationUpdateForResumeButtonProps> = ({ resumeId, sx }) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const { openModal } = useModal();
  const { data: resume, isLoading: isLoadingResume } = adminCatalogItemApi.endpoints.getCatalogItem.useQuery(
    { params: { id: resumeId as number } },
    { skip: !resumeId },
  );
  const handleApiServerError = useApiServerErrorHandler();
  const [isLoading, setLoading] = React.useState(false);

  const { label, handleClick } = React.useMemo(() => {
    if (!resume) {
      return {};
    }

    const withLoadingAndErrorHandler = (cb: () => Promise<{ error: ApiServerError | SerializedError } | object>): (() => Promise<void>) => {
      return async () => {
        try {
          setLoading(true);
          // eslint-disable-next-line callback-return
          const response = await cb();
          if ('error' in response && response.error) {
            handleApiServerError(response.error);
          } else {
            dispatch(clientPatronageApi.util.invalidateTags([ClientPatronageApiTag.List]));
            dispatch(adminCatalogItemApi.util.invalidateTags([{ type: AdminCatalogItemApiTag.Item, id: resume.id }]));
          }
        } finally {
          setLoading(false);
        }
      };
    };

    if (!resume.isPublished) {
      return {
        label: 'Опубликовать',
        handleClick: withLoadingAndErrorHandler(() => {
          return dispatch(coreModerationApi.endpoints.moderationApprove.initiate({ id: resume.moderation.id }));
        }),
      };
    } else if (resume.moderation.status !== ModerationStatus.Success) {
      return {
        label: 'Одобрить',
        handleClick: withLoadingAndErrorHandler(() => {
          return dispatch(coreModerationApi.endpoints.moderationApprove.initiate({ id: resume.moderation.id }));
        }),
      };
    } else {
      return {
        label: 'Заблокировать',
        handleClick: withLoadingAndErrorHandler(async () => {
          const message = await new Promise<string>((resolve, reject) => {
            openModal(MODERATION_MESSAGE_MODAL_NAME, {
              onModalDestroy: reject,
              onSubmit: (values, { onClose }) => {
                onClose();
                resolve(values.message);
              },
            });
          });

          return dispatch(coreModerationApi.endpoints.moderationDecline.initiate({ id: resume.moderation.id, message }));
        }),
      };
    }
  }, [resume, handleApiServerError, dispatch, openModal]);

  if (isLoadingResume) {
    return <Skeleton height={40} width={120} />;
  }

  if (!resume) {
    return null;
  }

  return (
    <Button color="pink" disabled={isLoading} onClick={handleClick} sx={sx} variant="primary">
      {label}
    </Button>
  );
};
