import { EntityId, createSelector } from '@reduxjs/toolkit';
import { DialogMemberViewModel } from '@common/model/dialog/dialogMemberViewModel';
import { DialogReducerStateShape } from './dialog';
import { dialogsAdapter } from './dialogsAdapter';
import { messagesAdapter } from './messagesAdapter';
import { membersAdapter } from './membersAdapter';

const dialogStateSelector = (state: DialogReducerStateShape) => state.dialog;
const { selectById: selectDialogById } = dialogsAdapter.getSelectors();
const { selectAll: selectMessages, selectTotal, selectById: selectMessageById } = messagesAdapter.getSelectors();
const { selectById: selectMemberById } = membersAdapter.getSelectors();

export const currentDialogBlocksSelector = (state: DialogReducerStateShape) => state.dialog.current.blocks;

export const currentDialogMessagesTotalCountSelector = (state: DialogReducerStateShape) => state.dialog.current.totalCount;

export const currentDialogIdSelector = (state: DialogReducerStateShape) => state.dialog.current.id;

export const currentDialogMessagesPageSelector = (state: DialogReducerStateShape) => state.dialog.current.messagesPage;

export const currentDialogMessagesSelector = createSelector(dialogStateSelector, (state) => selectMessages(state.current.messages));

export const messageSelector = createSelector([dialogStateSelector, (_: unknown, id: EntityId) => id], (state, id) =>
  selectMessageById(state.current.messages, id),
);

export const currentDialogLoadedMessagesCountSelector = createSelector(dialogStateSelector, (state) => selectTotal(state.current.messages));

export const currentDialogHasMoreMessagesSelector = createSelector(
  [currentDialogLoadedMessagesCountSelector, currentDialogMessagesTotalCountSelector],
  (loadedCount, totalCount) => loadedCount < totalCount,
);

export const currentDialogSelector = createSelector([dialogStateSelector, currentDialogIdSelector], (state, currentId) => {
  if (!currentId) {
    return;
  }
  const dialogNormalized = selectDialogById(state.dialogs, currentId);
  if (!dialogNormalized) {
    return;
  }
  const members = dialogNormalized.members
    .map((memberId) => selectMemberById(state.members, memberId))
    .filter(Boolean) as DialogMemberViewModel[];
  return { ...dialogNormalized, members };
});
