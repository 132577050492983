import React from 'react';
import { Typography } from '@cp/ds/src/components/typography';
import { NotFilledProps } from './model';

export const NotFilled: React.FC<NotFilledProps> = ({ children }) => {
  return (
    <Typography sx={{ color: 'brand pink' }} variant="body">
      {children}
    </Typography>
  );
};
