import React from 'react';
import { SvgIcon, SvgIconProps } from '../components/svgIcon';

export const VirusIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="virus" ref={ref as any} {...props}>
      <path
        d="M20.108 11.19h-1.703c-.162-1.136-.567-2.19-1.216-3l1.216-1.298a.882.882 0 0 0 0-1.216.882.882 0 0 0-1.216 0l-1.216 1.216c-.892-.649-1.865-1.135-3-1.216V3.892A.9.9 0 0 0 12.081 3a.9.9 0 0 0-.892.892v1.784c-1.135.162-2.189.567-3 1.216L6.892 5.676c-.324-.325-.892-.325-1.297 0a.882.882 0 0 0 0 1.216L6.81 8.108c-.649.892-1.135 1.865-1.216 3H3.892A.9.9 0 0 0 3 12a.9.9 0 0 0 .892.892h1.784c.162 1.135.567 2.19 1.216 3l-1.216 1.216a.882.882 0 0 0 0 1.216.882.882 0 0 0 1.216 0l1.216-1.216c.892.649 1.865 1.135 3 1.216v1.784A.9.9 0 0 0 12 21a.9.9 0 0 0 .892-.892v-1.784c1.135-.162 2.19-.567 3-1.216l1.216 1.216a.882.882 0 0 0 1.216 0 .882.882 0 0 0 0-1.216l-1.216-1.216c.649-.892 1.135-1.865 1.216-3h1.784A.9.9 0 0 0 21 12c0-.405-.405-.81-.892-.81ZM9.892 15.73a1.073 1.073 0 0 1-1.054-1.054c0-.568.486-1.054 1.054-1.054.568 0 1.054.486 1.054 1.054 0 .567-.486 1.054-1.054 1.054Zm1.784-4.216A1.8 1.8 0 0 1 9.892 9.73a1.8 1.8 0 0 1 1.784-1.784A1.8 1.8 0 0 1 13.46 9.73a1.8 1.8 0 0 1-1.784 1.784Zm3.81 3a1.073 1.073 0 0 1-1.054-1.054c0-.568.487-1.055 1.054-1.055.568 0 1.055.487 1.055 1.055 0 .567-.487 1.053-1.055 1.053Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});
