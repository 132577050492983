import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const FireIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="fire" ref={ref as any} {...props}>
      <path
        d="M12 20C15.314 20 18 18 18 14.5C18 13 17.5 10.5 15.5 8.5C15.75 10 14.25 10.5 14.25 10.5C15 8 13 4.5 10 4C10.357 6 10.5 8 8 10C6.75 11 6 12.729 6 14.5C6 18 8.686 20 12 20ZM12 19C10.343 19 9 18 9 16.25C9 15.5 9.25 14.25 10.25 13.25C10.125 14 11 14.5 11 14.5C10.625 13.25 11.5 11.25 13 11C12.821 12 12.75 13 14 14C14.625 14.5 15 15.364 15 16.25C15 18 13.657 19 12 19Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});
