// eslint-disable-next-line import/no-duplicates
import { format, isThisWeek, isToday } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { ru } from 'date-fns/locale';
import { DateFormat } from '@common/const/date';
import { DateISOString } from '@common/types/dateISOString';

export const getMessageDateTitle = (dateString: DateISOString): string => {
  const date = new Date(dateString);
  if (isToday(date)) {
    return format(date, DateFormat.Time);
  }

  if (isThisWeek(date)) {
    return format(date, DateFormat.DayOfThisWeekShort, { locale: ru });
  }

  return format(date, DateFormat.CatalogItemDateAnotherYear, { locale: ru });
};
