import React from 'react';
import { Typography } from '../../typography';
import { BUTTON_GROUP_LABEL_TYPOGRAPHY_VARIANT } from '../const';
import { ButtonGroupItemProps, ButtonGroupValue } from '../model';
import { StyledRadioInput } from './styledRadioInput';
import { StyledLabel } from './styledLabel';

export const ButtonGroupItem = <V extends ButtonGroupValue>({
  sx,
  label,
  checked,
  value,
  disabled = false,
  totalCount,
  index,
  onChange,
  size,
  ...props
}: ButtonGroupItemProps<V>) => {
  const isFirstItem = !index;
  const isLastItem = index === totalCount - 1;

  const ownerState = {
    checked,
    disabled,
    isFirstItem,
    isLastItem,
    size,
  };

  return (
    <StyledLabel ownerState={ownerState} sx={sx}>
      <StyledRadioInput
        checked={checked}
        disabled={disabled}
        onChange={disabled ? undefined : onChange}
        type="radio"
        value={value}
        {...props}
      />
      <Typography variant={BUTTON_GROUP_LABEL_TYPOGRAPHY_VARIANT}>{label}</Typography>
    </StyledLabel>
  );
};
