export * from './useUnreadMessagesCount';
export * from './useDialogMemberForCurrentUser';
export * from './getMessageDescription';
export { dialogReducerConfig, dialogSlice } from './store/dialog';
export { createDialogMiddleware } from './store/dialogMiddleware';
import { dialogSlice } from './store/dialog';
import * as dialogSelectors from './store/selectors';
import * as currentDialogSelectors from './store/currentDialogSelectors';
export type { DialogReducerStateShape as DialogStateShape } from './store/dialog';

export const dialogModel = {
  actions: dialogSlice.actions,
  selectors: dialogSelectors,
};

export const currentDialogModel = {
  selectors: currentDialogSelectors,
};
