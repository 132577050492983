/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Property } from 'csstype';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { useIsMobile } from '@cp/ds/src/hooks/useIsMobile';
import { useMemo } from 'react';

export type DialogTheme = {
  /** Message list background */
  listBg: Property.Color;
  /** Gap between messages */
  listGap: number;
  /** Gap between header elements */
  headerGap: number;
  blockHeaderLine: Property.Color;
  blockHeaderText: Property.Color;
};

export const useDialogTheme = (): DialogTheme => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  return useMemo(
    () => ({
      listBg: theme.palette['gray background'],
      listGap: isMobile ? 1.5 : 2.5,
      headerGap: isMobile ? 1.5 : 1,
      blockHeaderLine: '#D9D9D9',
      blockHeaderText: theme.palette.text.secondary,
    }),
    [theme, isMobile],
  );
};
