import React from 'react';
import useForkRef from '@mui/utils/useForkRef';
import { PaymentSystemIcon } from '../../../../../icons/payment';
import { useInputMask } from '../../../../../hooks/useInputMask';
import { CreditCardIcon } from '../../../../../icons/creditCard';
import { TextInput } from '../text';
import { CardNumberInputProps } from './model';
import { CARD_NUMBER_INPUT_MASK, cardNumberInputMaskTokens } from './const';
import { useCardNumberState } from './hooks/useCardNumberState';

export * from './const';
export * from './model';

export const CardNumberInput = React.forwardRef<HTMLInputElement, CardNumberInputProps>((props, ref) => {
  const { onValidnessStateChange, ...restProps } = props;
  const innerRef = React.useRef<HTMLInputElement>(null);
  const fieldRef = useForkRef(innerRef, ref);
  const { paymentSystem, isValid } = useCardNumberState(props);

  React.useEffect(() => {
    onValidnessStateChange?.(isValid);
  }, [onValidnessStateChange, isValid]);

  useInputMask(innerRef, CARD_NUMBER_INPUT_MASK, cardNumberInputMaskTokens);

  return (
    <TextInput
      autoComplete="cc-number"
      htmlType="text"
      inputMode="numeric"
      placeholder="‒ ‒ ‒ ‒  ‒ ‒ ‒ ‒  ‒ ‒ ‒ ‒  ‒ ‒ ‒ ‒"
      startAdornment={<PaymentSystemIcon defaultIcon={CreditCardIcon} fontSize="2rem" paymentSystem={paymentSystem} />}
      {...restProps}
      ref={fieldRef}
    />
  );
});
