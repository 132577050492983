import { ClientCatalogItemViewModel } from '@common/model/client/catalog/item/clientCatalogItemViewModel';
import React, { useMemo } from 'react';
import { AdvantageList } from '@cp/entities/advantage';
import { catalogOptionCoreApi } from '@cp/entities/catalogOption';
import { Skeleton } from '@cp/ds/src/components/skeleton';
import { Box } from '@cp/ds/src/components/box';
import { MOBILE_BREAKPOINT_THRESHOLD, SxProps } from '@cp/ds/src/theme';
import { optionToAdvantage } from '../../lib';

export interface OptionsListProps {
  data: ClientCatalogItemViewModel;
  sx?: SxProps;
}
export const OptionsList: React.FC<OptionsListProps> = ({ data, ...props }) => {
  const { data: allOptions, isLoading } = catalogOptionCoreApi.endpoints.getOptions.useQuery({ careerId: data.careerId, active: true });
  const advantages = useMemo(() => {
    if (!allOptions) {
      return [];
    }
    const optionsSet = new Set<number>();
    for (const option of data.options) {
      optionsSet.add(option.id);
    }
    return allOptions.items.filter((option) => optionsSet.has(option.id)).map(optionToAdvantage);
  }, [data.options, allOptions]);

  return (
    <Box {...props}>
      {isLoading ? (
        <Skeleton />
      ) : (
        <AdvantageList
          advantages={advantages}
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            columnGap: 1,
            flexDirection: { xs: 'column', [MOBILE_BREAKPOINT_THRESHOLD]: 'row' },
            rowGap: 0.5,
          }}
        />
      )}
    </Box>
  );
};
