import styled from '@mui/material/styles/styled';
import React from 'react';
import { ACCORDION_SUMMARY_ICON_SPACING } from '../const';
import { AccordionOwnerState, SpanTagProps } from '../model';

type UnstyledSpanComponent = React.ComponentClass<SpanTagProps & { ownerState: AccordionOwnerState }>;

export const SummaryIconWrapper = styled('span' as unknown as UnstyledSpanComponent, {
  name: 'AccordionSummaryIcon',
  slot: 'Icon',
})(({ theme, ownerState }) => {
  const { fontSize: fontSizeRaw, lineHeight: lineHeightRaw } = theme.typography[ownerState.summaryTypography];

  let marginTop: string | number = 0;

  if (fontSizeRaw && lineHeightRaw && ownerState.isSummaryPassedAsPlainText) {
    const isFontSizeInRem = typeof fontSizeRaw === 'string' && fontSizeRaw.includes('rem');
    const isNumericLineHeight = typeof lineHeightRaw === 'number';

    if (isFontSizeInRem && isNumericLineHeight) {
      const fontSizeValue = parseInt(fontSizeRaw);
      // eslint-disable-next-line no-mixed-operators
      marginTop = `${(fontSizeValue * lineHeightRaw - fontSizeValue) / 2}rem`; // eslint-disable-line @typescript-eslint/no-magic-numbers
    }
  }

  return {
    ...(ownerState.isExpanded ? { transform: 'rotate(180deg)' } : undefined),
    display: 'inline-flex',
    flexDirection: 'row',
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.standard,
    }),
    marginTop,
    marginLeft: theme.spacing(ACCORDION_SUMMARY_ICON_SPACING),
    // Fallback to vertical centering if marginTop calculation failed
    ...(!marginTop && {
      alignSelf: 'stretch',
      alignItems: 'center',
    }),
  };
});
