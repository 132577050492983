import { ClientProfileUpdateRequest } from '@common/model/client/profile/clientProfileUpdateRequest';
import { coreApi } from '@cp/shared/api/core';
import { ApiServerEntityIDType } from '@cp/utils/apiServer/types';
import { AdminClientProfileViewModel } from '@common/model/client/profile/adminClientProfileViewModel';
import { ModerationApiTags, moderationApiTagTypes } from 'src/entities/moderation';
import { AdminCatalogItemApiTag, adminCatalogItemApiTags } from 'src/entities/catalog/item';
import { AdminClientProfileApiTags, adminClientProfileApiTagTypes } from './tags';

const BASE_URL = '/admin/clients';

export const coreClientProfileApi = coreApi
  .enhanceEndpoints({ addTagTypes: [...moderationApiTagTypes, ...adminClientProfileApiTagTypes, ...adminCatalogItemApiTags] as const })
  .injectEndpoints({
    endpoints: (builder) => ({
      getClientProfile: builder.query<AdminClientProfileViewModel, { clientId: ApiServerEntityIDType }>({
        query: ({ clientId }) => {
          return {
            url: `${BASE_URL}/${clientId}/profile`,
          };
        },
        providesTags: (res, err, args) => [{ type: AdminClientProfileApiTags.ClientProfile, id: args.clientId }],
      }),
      updateClientProfile: builder.mutation<AdminClientProfileViewModel, ClientProfileUpdateRequest & { clientId: ApiServerEntityIDType }>({
        query: ({ clientId, ...body }) => {
          return {
            url: `${BASE_URL}/${clientId}/profile`,
            method: 'PUT',
            body,
          };
        },
        invalidatesTags: (res, err, args) => [
          { type: AdminClientProfileApiTags.ClientProfile, id: args.clientId },
          ModerationApiTags.Moderation,
          { type: AdminCatalogItemApiTag.Item, id: res?.mainResumeId },
        ],
      }),
    }),
  });
