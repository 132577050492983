import React from 'react';
import { Stack } from '@cp/ds/src/components/stack';
import { Typography } from '@cp/ds/src/components/typography';
import { useIsMobile } from '@cp/ds/src/hooks/useIsMobile';
import { formatResumeScheduleDayOfWeek, useResumeScheduleData } from '@cp/entities/resume';
import { SchedulePreviewProps } from './model';

const MAX_COLUMNS = 2;

export const SchedulePreview: React.FC<SchedulePreviewProps> = ({ data }) => {
  const isMobile = useIsMobile();
  const { sections } = useResumeScheduleData({ data, excludeEmptySections: true });

  const columns = React.useMemo(() => {
    if (!sections.length) {
      return [];
    }

    if (isMobile) {
      return [sections];
    }

    const result: (typeof sections)[] = [];
    let sectionsSliced = 0;

    while (sectionsSliced < sections.length) {
      const columnSections = sections.slice(sectionsSliced, sectionsSliced + Math.ceil(sections.length / MAX_COLUMNS));
      sectionsSliced += columnSections.length;
      result.push(columnSections);
    }

    return result;
  }, [isMobile, sections]);

  return (
    <Stack direction="row" sx={{ mt: 1 }}>
      {columns.map((columnSections, index) => (
        <Stack direction="column" gap={1} key={index} sx={{ width: 1 / MAX_COLUMNS }}>
          {columnSections.map((section) => (
            <Typography component="div" key={section.dayOfWeek} sx={{ display: 'flex' }} variant="body">
              <Typography component="span" sx={{ whiteSpace: 'nowrap' }} variant="body bold">
                {formatResumeScheduleDayOfWeek(section.dayOfWeek)} -&nbsp;
              </Typography>
              <span>{section.intervals.map(({ from, to }) => `${from}-${to}`).join(', ')}</span>
            </Typography>
          ))}
        </Stack>
      ))}
    </Stack>
  );
};
