import { DateISOString } from '@common/types/dateISOString';
import { formatISODuration } from 'date-fns';
import { Selection } from './selections';

type Result = { onOrBefore: DateISOString; onOrAfter?: DateISOString };

export const getSelectionInterval = (threshold: Selection, baseDate: Date = new Date()): Result => {
  const timestamp = baseDate.toISOString();
  const interval: Result = { onOrBefore: timestamp };

  if (threshold.minAge) {
    interval.onOrBefore = [formatISODuration(threshold.minAge), timestamp].join('/');
  }
  if (threshold.maxAge) {
    interval.onOrAfter = [formatISODuration(threshold.maxAge), timestamp].join('/');
  }

  return interval;
};
