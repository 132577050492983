import { StyledButton } from '@cp/ds/src/components/styled/styledButton';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { SendIcon } from '@cp/ds/src/icons/send';
import { mergeSx } from '@cp/ds/src/utils/sx/merge';
import React from 'react';
import { SendButtonProps } from './model';
export type { SendButtonProps } from './model';

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const SendButton: React.FC<SendButtonProps> = ({ size = 48, sx, ...restProps }) => {
  const theme = useTheme();

  return (
    <StyledButton
      sx={mergeSx(
        {
          width: size,
          height: size,
          borderRadius: '50%',
          backgroundColor: restProps.disabled ? 'gray middle' : 'brand gray',
          transition: theme.transitions.create(['backgroundColor'], {
            duration: theme.transitions.duration.short,
          }),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        sx,
      )}
      type="submit"
      {...restProps}
    >
      <SendIcon sx={{ color: 'white', mr: -0.25 }} />
    </StyledButton>
  );
};
