import { coreDialogApi } from '@cp/entities/dialog';
import { useCallback } from 'react';
import { DialogMemberType } from '@common/model/dialog/dialogMemberType';
import { handleApiServerError } from '@cp/utils/apiServer/errorHandler';
import { ApiServerEntityIDType } from '@cp/utils/apiServer/types';
import { IDialogFindQuery } from './findDialog.interface';

export const useDialogIdGetter = (): [(q: IDialogFindQuery) => Promise<ApiServerEntityIDType | undefined>, boolean] => {
  const [ensureDialog, { isLoading }] = coreDialogApi.useCreateDialogMutation();
  const load = useCallback(
    async (query: IDialogFindQuery) => {
      if ('id' in query) {
        return query.id;
      }
      const dialogResponse = await ensureDialog({ participantId: query.clientId, participantType: DialogMemberType.Client });

      if ('error' in dialogResponse) {
        handleApiServerError(dialogResponse.error);
        return;
      }

      return dialogResponse.data.id;
    },
    [ensureDialog],
  );

  return [load, isLoading];
};
