import React from 'react';
import { Stack } from '@cp/ds/src/components/stack';
import { MOBILE_BREAKPOINT_THRESHOLD } from '@cp/ds/src/theme';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { clientContactItemDict } from '../item/dict';
import { PreviewProps } from './model';

export * from './model';

export const Preview: React.FC<PreviewProps> = ({ data = {}, contactsToHide = [], ...props }) => {
  const theme = useTheme();

  return (
    <Stack direction="row" gap={1} sx={{ mt: { xs: 1.5, [MOBILE_BREAKPOINT_THRESHOLD]: 2 } }} {...props}>
      {Object.entries(clientContactItemDict).map(
        ([type, dictItem]) =>
          dictItem &&
          !contactsToHide.includes(parseInt(String(type))) && (
            <dictItem.icon color={!data[type] ? theme.palette['gray middle'] : undefined} key={type} sx={{ fontSize: '2.5rem' }} />
          ),
      )}
    </Stack>
  );
};
