import { LanguageCode } from '../../../model/resource/language';

export type ValidationMessagesDictionary = {
  [Key in LanguageCode]?: string;
};

export const validatorMessageFromDictionary = (validationMessagesDictionary: ValidationMessagesDictionary): string => {
  return JSON.stringify(validationMessagesDictionary);
};

export const validationMessagesDictionaryFromMessage = (message: string): ValidationMessagesDictionary | undefined => {
  try {
    return JSON.parse(message);
  } catch {
    return;
  }
};
