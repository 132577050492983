/* eslint-disable @typescript-eslint/no-magic-numbers */
import { alpha } from '@mui/system/colorManipulator';
import { AppTheme, SxProps } from '../../theme';
import { CardVariant } from './model';

export const cardStyles: Record<CardVariant, (theme: AppTheme, { hover }: { hover: boolean }) => SxProps> = {
  one: (theme, { hover }) => ({
    boxSizing: 'border-box',
    cursor: 'pointer',
    transition: theme.transitions.create(['border-color', 'box-shadow', 'background-color'], {
      duration: theme.transitions.duration.short,
    }),
    borderRadius: '16px',
    border: `1px solid ${theme.palette['gray middle']}`,
    boxShadow: '14px 17px 40px 4px rgba(138, 182, 209, 0.08)',
    '&:hover': hover
      ? {
          boxShadow: `0px 4px 16px ${alpha(theme.palette.black, 0.13)}`,
          backgroundColor: theme.palette['gray ultra light'],
          '& [data-card-description=gradient]': {
            background: `linear-gradient(to right, transparent, ${theme.palette['gray ultra light']})`,
          },
          '& [data-card-description=linear]': {
            backgroundColor: theme.palette['gray ultra light'],
          },
        }
      : undefined,
  }),
  two: (theme) => ({
    boxSizing: 'border-box',
    p: 1.5,
    backgroundColor: 'common.white',
    boxShadow: `0px 2.166555166244507px 3.24983286857605px 0px ${alpha(theme.palette.black, 0.1)}`,
    borderRadius: '12px',
    border: `1px solid ${theme.palette['gray light']}`,
    flex: '1 1 0px',
  }),
};
