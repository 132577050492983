import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const AttachIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="attach" ref={ref as any} {...props}>
      <path
        d="M5.84868 20.94C3.7265 19.4291 3.22917 16.4734 4.74004 14.3512L12.2185 3.84693C13.3361 2.27714 15.5225 1.90925 17.0923 3.02686C18.6621 4.14449 19.03 6.33088 17.9124 7.90068L11.8476 16.4193C11.1276 17.4306 9.71907 17.6676 8.70773 16.9476C7.69642 16.2276 7.45942 14.8191 8.17944 13.8078L12.6299 7.55662L13.6427 8.27767L9.19221 14.5288C8.8698 14.9817 8.97591 15.6125 9.42876 15.9349C9.88165 16.2573 10.5124 16.1511 10.8348 15.6983L16.8996 7.17962C17.6196 6.16828 17.3826 4.7597 16.3712 4.03965C15.3599 3.31963 13.9513 3.55664 13.2313 4.56799L5.75286 15.0722C4.63957 16.636 5.00606 18.8139 6.56975 19.9271C8.13344 21.0404 10.3114 20.674 11.4246 19.1102L17.1806 11.0254L18.1934 11.7464L12.4374 19.8313C10.9266 21.9535 7.97085 22.4508 5.84868 20.94Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});
