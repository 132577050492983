import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const LocationIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="location" ref={ref as any} {...props}>
      <path
        d="M11.5 2C7.36445 2 4 5.34699 4 9.46105C4 15.5907 10.7535 21.5758 11.0411 21.8277C11.1722 21.9425 11.3361 22 11.5 22C11.6639 22 11.8278 21.9426 11.959 21.8277C12.2465 21.5759 19 15.5908 19 9.46105C19 5.34699 15.6356 2 11.5 2ZM11.5 7.36651C12.6488 7.36651 13.5834 8.30611 13.5834 9.46105C13.5834 10.616 12.6488 11.5556 11.5 11.5556C10.3512 11.5556 9.41665 10.616 9.41665 9.46105C9.41665 8.30611 10.3512 7.36651 11.5 7.36651Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});
