import { format } from 'date-fns';
import { ru } from '@cp/shared/lib/date';
import { useMemo } from 'react';
import { DateFormat } from '@common/const/date';
import { CatalogItemViewModel } from '@common/model/catalog/item/view';

type Model = Pick<CatalogItemViewModel, 'startedAt' | 'endedAt'>;

export const formatVacancyDates = (model: Model) => {
  const start = model.startedAt ? new Date(model.startedAt) : undefined;
  const end = model.endedAt ? new Date(model.endedAt) : undefined;
  let t = '';
  if (start) {
    t += `${format(start, DateFormat.CatalogItemDateThisYear, { locale: ru })} ${format(start, DateFormat.Time)}`;
  }
  if (end) {
    t += ` - ${format(end, DateFormat.Time)}`;
  }
  return t;
};

export const useVacancyDates = ({ startedAt, endedAt }: Model) => {
  return useMemo(() => formatVacancyDates({ startedAt, endedAt }), [startedAt, endedAt]);
};
