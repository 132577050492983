import React from 'react';
import { CityViewModel } from '@common/model/location/cityViewModel';
import { LocationSuggestion } from '@common/model/location/locationSuggestion';
import { AppSuggestionsContext, ISuggestionsContext } from '@cp/utils/form/appSuggestionsContext';
import { CITY_SEARCH_QUERY_MIN_LENGTH } from '@common/const/location/city';
import { useDispatch } from 'react-redux';
import type { ThunkDispatch } from 'redux-thunk';
import { SuggestionsProviderProps } from './model';

const coreLocationApiModuleFactory = () => import('@cp/entities/location');

export const AppSuggestionsProvider: React.FC<SuggestionsProviderProps> = ({ children }) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const value = React.useRef<ISuggestionsContext<CityViewModel, LocationSuggestion>>({
    async onSearchCity(query) {
      const apiModule = await coreLocationApiModuleFactory();

      const response = await dispatch(
        apiModule.coreLocationApi.endpoints.getCities.initiate(
          query.length < CITY_SEARCH_QUERY_MIN_LENGTH ? { onlyBigCities: true } : { name: query },
        ),
      );

      return response.data ?? [];
    },
    async onSearchLocation(query, { cityId }) {
      const apiModule = await coreLocationApiModuleFactory();

      const response = await dispatch(
        apiModule.coreLocationApi.endpoints.getCityLocations.initiate({
          address: String(query),
          cityId,
        }),
      );

      return response.data ?? [];
    },
  });

  return <AppSuggestionsContext.Provider value={value.current}>{children}</AppSuggestionsContext.Provider>;
};
