import React, { ReactNode } from 'react';
import { Box } from '@cp/ds/src/components/box';
import { Typography } from '@cp/ds/src/components/typography';
import { SvgIconComponent } from '@cp/ds/src/components/svgIcon';
import { SxProps } from '@cp/ds';
import { mergeSx } from '@cp/ds/src/utils/sx/merge';
import { Skeleton } from '@cp/ds/src/components/skeleton';

export interface StatItemProps {
  title: string;
  value?: ReactNode;
  isLoading?: boolean;
  Icon?: SvgIconComponent;
  iconSxProps?: SxProps;
  sx?: SxProps;
}

export const StatItem: React.FC<StatItemProps> = ({ title, value = '--', Icon, isLoading, iconSxProps, sx }) => {
  return (
    <Box sx={mergeSx({ display: 'flex', alignItems: 'center' }, sx)}>
      {Icon && <Icon sx={{ mr: 0.6, ...iconSxProps }} />}
      <Typography sx={{ color: 'gray', pr: 1, whiteSpace: 'nowrap' }} variant="caption bold">
        {title}:
      </Typography>
      {isLoading ? (
        <Skeleton>
          <Typography sx={{ whiteSpace: 'nowrap' }} variant="caption bold">
            loading
          </Typography>
        </Skeleton>
      ) : (
        <Typography sx={{ whiteSpace: 'nowrap' }} variant="caption bold">
          {value}
        </Typography>
      )}
    </Box>
  );
};
