import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const MessageIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="message" ref={ref as any} {...props}>
      <path
        clipRule="evenodd"
        d="M7.33333 6.75C7.17764 6.75 7.02894 6.81163 6.91978 6.92035C6.81071 7.02897 6.75 7.17564 6.75 7.32792V14.5455H8C8.32689 14.5455 8.61612 14.7572 8.71492 15.0688L9.30435 16.9277L11.094 14.8112C11.2365 14.6427 11.446 14.5455 11.6667 14.5455H16.6667C16.8224 14.5455 16.9711 14.4838 17.0802 14.3751C17.1893 14.2665 17.25 14.1198 17.25 13.9675V7.32792C17.25 7.17563 17.1893 7.02897 17.0802 6.92035C16.9711 6.81163 16.8224 6.75 16.6667 6.75H7.33333ZM5.86127 5.85753C6.25221 5.46819 6.78178 5.25 7.33333 5.25H16.6667C17.2182 5.25 17.7478 5.46819 18.1387 5.85753C18.5298 6.24698 18.75 6.77584 18.75 7.32792V13.9675C18.75 14.5196 18.5298 15.0485 18.1387 15.4379C17.7478 15.8273 17.2182 16.0455 16.6667 16.0455H12.0147L9.57271 18.9335C9.39639 19.1421 9.12067 19.2383 8.85289 19.1847C8.58512 19.1312 8.36762 18.9363 8.28508 18.676L7.45101 16.0455H6C5.58579 16.0455 5.25 15.7097 5.25 15.2955V7.32792C5.25 6.77584 5.47024 6.24698 5.86127 5.85753Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
});
