import React from 'react';
import { SvgIcon } from '../../../components/svgIcon';
import { SvgIconProps } from '../../../components/svgIcon/model';

export const HousekeeperIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="career/colored/housekeeper" ref={ref as any} {...props}>
      <path
        d="M3 9.61111L9.90196 9L14 12.6667L13.3529 20C13.3529 20 7.7451 18.5741 5.37255 16.3333C3 14.0926 3.92654 12.2363 3 9.61111Z"
        fill="#FFCEC3"
      />
      <path
        d="M13.578 21C13.7569 21 13.9246 20.9139 14.0292 20.7689C14.1339 20.624 14.1624 20.4374 14.1062 20.2677C14.0916 20.2237 12.6708 15.8398 14.7589 13.7518L15.5461 12.9645C16.4966 12.014 16.6146 10.5417 15.9002 9.4616L20.5116 4.85003C21.1628 4.19887 21.1628 3.13952 20.5116 2.48837C19.8606 1.83721 18.8012 1.83721 18.15 2.48837L13.5387 7.0995C12.4691 6.3892 10.9966 6.4927 10.0355 7.45377L9.24822 8.24105C7.15908 10.33 2.77611 8.90814 2.7319 8.89365C2.5623 8.83741 2.37573 8.86596 2.23077 8.97062C2.08582 9.07529 2 9.24314 2 9.42188C2 12.5146 3.20431 15.422 5.39102 17.6088C7.57802 19.7957 10.4854 21 13.578 21ZM18.9373 3.27549C19.1543 3.05849 19.5074 3.05849 19.7244 3.27549C19.9415 3.49264 19.9415 3.84576 19.7244 4.06276L15.1526 8.63475C15.0121 8.49414 14.7513 8.23351 14.3652 7.84748L18.9373 3.27549ZM10.8228 8.24105C11.4752 7.58859 12.5315 7.58829 13.1843 8.2409L14.7589 9.81558C15.41 10.4667 15.41 11.5261 14.7589 12.1772L14.3652 12.5708L10.4291 8.6346L10.8228 8.24105ZM9.61628 9.39636L13.6035 13.3836C12.0391 15.4097 12.505 18.4526 12.8405 19.861C12.0875 19.8083 11.3565 19.6751 10.6555 19.4708L10.9463 18.308C11.0715 17.8073 11.232 17.3102 11.4235 16.8309C11.5376 16.5455 11.3985 16.2214 11.113 16.1074C10.8264 15.993 10.5031 16.1335 10.3896 16.4178C10.1796 16.9438 10.0035 17.4888 9.86618 18.0379L9.60033 19.1012C8.48632 18.6418 7.46871 17.995 6.5862 17.2011L8.54243 15.2447C8.75986 15.0275 8.75986 14.6749 8.54243 14.4576C8.32513 14.2401 7.97258 14.2401 7.75515 14.4576L5.79894 16.4138C5.00485 15.5312 4.35819 14.5137 3.89867 13.3997C4.68376 13.1889 5.45451 13.0607 6.582 12.6103C6.86757 12.4963 7.00658 12.1725 6.89251 11.8869C6.77885 11.6023 6.4553 11.4622 6.16902 11.5765C5.11893 11.9959 4.41182 12.109 3.52903 12.3444C3.32478 11.6435 3.1917 10.9125 3.13894 10.1596C4.54722 10.495 7.59033 10.9609 9.61628 9.39636Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});
