import React from 'react';
import { mergeSx } from '@cp/ds/src/utils/sx/merge';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { Stack } from '@cp/ds/src/components/stack';
import { ClientPhotoCard } from '@cp/entities/clientPhoto';
import { LocationOutlineIcon } from '@cp/ds/src/icons';
import { Typography } from '@cp/ds/src/components/typography';
import { ClientPatronageStage } from '@common/model/admin/clientPatronage/stage';
import { alpha } from '@mui/material/styles';
import { getClientSummary } from 'src/entities/client';
import { Card as BaseCard } from 'src/shared/ui/card';
import { CardProps } from './model';
import { History } from './history';

export * from './model';

export const Card: React.FC<CardProps> = ({ clientPhotoCardProps, data, onHeaderClick, beforeHeader, beforeBody, footer, sx }) => {
  const theme = useTheme();

  const backgroundColor =
    data.stage === ClientPatronageStage.Rejected
      ? theme.palette['pink light']
      : data.mainResume?.isPublished
        ? // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          alpha(theme.palette.green, 0.1)
        : theme.palette.white;

  return (
    <BaseCard sx={mergeSx({ backgroundColor }, sx)}>
      {beforeHeader}

      <Stack
        direction="row"
        gap={1}
        {...(onHeaderClick ? { onClick: onHeaderClick, tabIndex: 0, role: 'button', sx: { cursor: 'pointer' } } : undefined)}
      >
        <ClientPhotoCard data={data.client} rounded size={48} {...clientPhotoCardProps} />
        <div>
          <Typography component="div" variant="body bold">
            {getClientSummary(data.client)}
          </Typography>
          <Typography
            component="div"
            sx={{
              color: 'gray dark',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
              lineClamp: '1',
              wordBreak: 'break-all',
            }}
            variant="small text"
          >
            <LocationOutlineIcon fontSize="0.75rem" sx={{ mt: -0.125 }} />
            {[data.client.city?.translation.name, data.client.address].filter(Boolean).join(', ')}
          </Typography>
        </div>
      </Stack>

      {beforeBody}

      <History data={data} sx={{ mt: 1, mb: footer ? 1 : 0 }} />

      {footer}
    </BaseCard>
  );
};
