import { PaymentSystem } from '@common/const/payment';

const dict: Record<Exclude<PaymentSystem, PaymentSystem.Unknown>, { regexp: RegExp }> = {
  [PaymentSystem.Visa]: {
    regexp: /^4[0-9]{0,}$/,
  },
  [PaymentSystem.MasterCard]: {
    regexp: /^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$/,
  },
  [PaymentSystem.Maestro]: {
    regexp: /^(5[06789]|6)[0-9]{0,}$/,
  },
  [PaymentSystem.Mir]: {
    regexp: /^2[2][0-9]{0,}$/,
  },
};

export const getPaymentSystemFromCardNumber = (cardNumber: string): PaymentSystem => {
  const sanitizedCardNumber = cardNumber.replace(/\D+/g, '');
  const paymentSystemAsString = Object.entries(dict).find(([_, { regexp }]) => sanitizedCardNumber.match(regexp))?.[0];
  return paymentSystemAsString ? parseInt(String(paymentSystemAsString)) : PaymentSystem.Unknown;
};
