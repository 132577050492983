import React from 'react';
import { EditableSection } from '@cp/ds/src/components/editableSection';
import { ResumeSchedulePreview } from '@cp/entities/resume';
import { useModal } from 'src/app/shared/hooks/useModal';
import { useResumeData } from 'src/entities/resume';
import { RESUME_SCHEDULE_EDIT_MODAL_NAME } from 'src/app/shared/components/modals/resumeScheduleEdit/const';
import { MainResumeScheduleEditableSectionProps } from './model';

export * from './model';

export const ResumeScheduleEditableSection: React.FC<MainResumeScheduleEditableSectionProps> = ({ resumeId }) => {
  const { openModal } = useModal();
  const { resume, isLoadingResume } = useResumeData({ resumeId });

  const schedule = resume?.schedule;
  const isEmpty = !isLoadingResume && !schedule;

  return (
    <EditableSection
      emptyMessage={isEmpty ? 'Расписание не заполнено' : undefined}
      isLoading={isLoadingResume}
      onEdit={() => {
        if (resume) {
          openModal(RESUME_SCHEDULE_EDIT_MODAL_NAME, { data: resume });
        } else {
          throw new Error('Ошибка сервиса: резюме не найдено');
        }
      }}
      title="Расписание"
    >
      {!isEmpty && schedule && <ResumeSchedulePreview data={schedule} />}
    </EditableSection>
  );
};
