import styled from '@mui/material/styles/styled';
import { InputTagProps, SelectButtonStateProps } from '../model';

type UnstyledInputComponent = React.ComponentClass<InputTagProps & { ownerState: SelectButtonStateProps }>;

export const SelectButtonInput = styled('input' as unknown as UnstyledInputComponent, {
  name: 'Select',
  slot: 'Input',
})({
  bottom: 0,
  left: 0,
  position: 'absolute',
  opacity: 0,
  pointerEvents: 'none',
  width: '100%',
  boxSizing: 'border-box',
});
