/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, ReactNode } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Box } from '@cp/ds/src/components/box';
import { useIsMobile } from '@cp/ds/src/hooks/useIsMobile';
import { SxProps } from '@mui/system'; // use mui because of incompatible type error in consumer component
import { ContentSkeleton } from '@cp/ds/src/components/contentSkeleton';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { mergeSx } from '@cp/ds/src/utils/sx/merge';
import { DialogMessageList } from '../dialogMessageList';
import { DialogMessageBlockPlain } from '../../model/dialogMessageDateBlock';
import { useDialogTheme } from '../theme';

export interface DialogProps {
  sx?: SxProps;
  hasMore: boolean;
  isLoadingMessages: boolean;
  loadedMessagesLength: number;
  loadMore: () => void;
  messageBlocks: DialogMessageBlockPlain[];
  scrollContainerRef: React.RefObject<HTMLDivElement>;
  headerContainerRef?: React.RefObject<HTMLDivElement>;
  header: ReactNode;
  footer: ReactNode;
  MessageListWrapper: (props: { sx?: SxProps; ref?: React.RefObject<HTMLDivElement>; children?: React.ReactElement }) => JSX.Element | null;
  Loader: () => JSX.Element;
}
const style = { display: 'flex', flexDirection: 'column-reverse' } as const;

export const Dialog: FC<DialogProps> = ({
  sx,
  hasMore,
  loadMore,
  messageBlocks,
  header,
  footer,
  isLoadingMessages,
  loadedMessagesLength,
  scrollContainerRef,
  headerContainerRef,
  MessageListWrapper,
  Loader,
}) => {
  const isMobile = useIsMobile();
  const theme = useTheme();
  const dialogTheme = useDialogTheme();
  const containerWrapperSx = {
    zIndex: 5,
    boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.13)',
    position: 'relative',
    backgroundColor: theme.palette.white,
  };

  return (
    <Box sx={{ backgroundColor: dialogTheme.listBg, ...sx }}>
      <Box
        ref={headerContainerRef}
        sx={mergeSx(
          containerWrapperSx,
          isMobile
            ? {
                px: 1.5,
                py: 1.25,
                flexShrink: 0,
              }
            : {
                pt: 3,
                px: 5,
                pb: 2,
              },
        )}
      >
        {header}
      </Box>
      <MessageListWrapper
        ref={scrollContainerRef}
        sx={{
          px: isMobile ? 0 : 5,
          flexGrow: 1,
          flexShrink: 1,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse',
          backgroundColor: dialogTheme.listBg,
          paddingBottom: dialogTheme.listGap,
        }}
      >
        {isLoadingMessages && !messageBlocks?.length ? (
          <ContentSkeleton blocks={5} />
        ) : messageBlocks?.length ? (
          <InfiniteScroll
            dataLength={loadedMessagesLength}
            hasMore={hasMore}
            inverse
            loader={<Loader />}
            next={loadMore}
            scrollThreshold={0.95}
            scrollableTarget={scrollContainerRef.current as React.ReactNode}
            style={style}
          >
            <DialogMessageList blocks={messageBlocks} />
          </InfiniteScroll>
        ) : undefined}
      </MessageListWrapper>
      {footer}
    </Box>
  );
};
