export enum ClientContactType {
  InnerChat = 0,
  WhatsApp = 1,
  Viber = 2,
  Telegram = 3,
  VK = 4,
  Instagram = 5,
  Phone = 6,
  Email = 7,
}

// Old DB contact types
// --------------------
// const WHATS_APP = 1;
// const VIBER = 2;
// const TELEGRAM = 3;
// const VK = 4;
// const INSTAGRAM = 5;
