import React from 'react';
import { ACCORDION_HORIZONTAL_SPACING, ACCORDION_VERTICAL_SPACING } from '../const';
import { AccordionOwnerState, DivTagProps } from '../model';
import { styled } from '../../../theme';

type UnstyledDivComponent = React.ComponentClass<DivTagProps & { ownerState: AccordionOwnerState }>;

export const AccordionSummary = styled('div' as unknown as UnstyledDivComponent, {
  name: 'AccordionSummary',
  slot: 'Summary',
})(({ theme, ownerState }) => {
  const commonStyles = {
    color: theme.palette['brand gray'],
  };

  if (ownerState.color === 'white') {
    commonStyles.color = theme.palette.white;
  }

  return {
    ...theme.typography[ownerState.summaryTypography],
    ...commonStyles,
    padding: theme.spacing(ACCORDION_VERTICAL_SPACING, ACCORDION_HORIZONTAL_SPACING),
    cursor: 'pointer',
    display: 'inline-flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',

    '&:focus, &:active': {
      outline: 'none',
    },
    '&:hover': {
      backgroundColor: theme.palette['gray ultra light'],
    },
  };
});
