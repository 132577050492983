import React from 'react';
import { Stack } from '@cp/ds/src/components/stack';
import { Typography } from '@cp/ds/src/components/typography';
import { DeclinationMnemonic } from '@common/model/resource/declinationMnemonic';
import { MOBILE_BREAKPOINT_THRESHOLD } from '@cp/ds/src/theme';
import { mergeSx } from '@cp/ds/src/utils/sx/merge';
import { useDeclination } from '@cp/shared/declination';
import { CAREER_IDS_WITH_CHILDREN_PARAMETER } from '@common/const/catalog/item';
import { TariffsPreviewProps } from './model';

export * from './model';

export const TariffsPreview: React.FC<TariffsPreviewProps> = ({ careerId, tariffs, price, sx }) => {
  const declination = useDeclination();
  const isSeparatedByChildrenCount = CAREER_IDS_WITH_CHILDREN_PARAMETER.includes(careerId);

  const renderPrice = (priceValue: number | undefined, key?: React.Key) => {
    return (
      <Typography key={key} variant="body bold">
        {priceValue} руб/час
      </Typography>
    );
  };

  return (
    <Stack component="div" direction="column" gap={0.5} sx={mergeSx({ mt: { xs: 1, [MOBILE_BREAKPOINT_THRESHOLD]: 1.5 } }, sx)}>
      {isSeparatedByChildrenCount
        ? tariffs?.map(({ childrenCount, price: tariffPrice }) => (
            <Typography component="div" key={childrenCount} variant="body">
              {childrenCount} {declination(childrenCount, DeclinationMnemonic.Child)} - {renderPrice(tariffPrice, childrenCount)}
            </Typography>
          ))
        : renderPrice(price)}
    </Stack>
  );
};
