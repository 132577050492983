import { InputMaskTokenDictionary } from '../../../../../hooks/useInputMask/model';

export const CVC_INPUT_MASK = '###';
export const CVC_LENGTH = CVC_INPUT_MASK.length;

export const cvcInputMaskTokens: InputMaskTokenDictionary = {
  '#': {
    pattern: /\d/,
  },
};
