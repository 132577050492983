import React from 'react';
import { Box } from '@cp/ds/src/components/box';
import { useSelector } from 'react-redux';
import { Stack } from '@cp/ds/src/components/stack';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { Link } from '@cp/ds/src/components/link';
import { ColumnProps } from './model';

export const Column = React.memo(
  <T, S extends {} = {}>({
    itemsDataSelector,
    canLoadMoreSelector,
    onLoadMore,
    itemKeyGenerator,
    renderItem,
    index,
  }: ColumnProps<T, S>): ReturnType<React.FC> => {
    const items = useSelector(itemsDataSelector);
    const canLoadMore = useSelector(canLoadMoreSelector);
    const theme = useTheme();

    return (
      <Box component="td" sx={{ verticalAlign: 'top', p: 0, borderRight: `1px solid ${theme.palette.gray}` }}>
        <Stack direction="column" gap={1.5} sx={{ p: 1.5, pl: index ? undefined : 0 }}>
          {items.map((data, itemIndex) => (
            <React.Fragment key={itemKeyGenerator(data)}>{renderItem(data, itemIndex)}</React.Fragment>
          ))}
          {canLoadMore && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Link onClick={onLoadMore} role="button" size="small">
                Показать еще
              </Link>
            </Box>
          )}
        </Stack>
      </Box>
    );
  },
) as <T, S extends {} = {}>(props: ColumnProps<T, S>) => ReturnType<React.FC>;
