import { coreApi } from '@cp/shared/api/core';
import { CatalogItemResponsesRequest, CatalogItemResponsesResponse } from '@common/model/catalog/item/response';
import { generateApiUrl } from '@common/api/configure';
import { ApiRoute } from '@common/api/route';
import { apiRouteMethodDict } from '@common/api/routeMethod';

export const coreResponsesApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getCatalogItemResponses: builder.query<CatalogItemResponsesResponse, CatalogItemResponsesRequest>({
      query: (params) => ({
        url: generateApiUrl(ApiRoute.CatalogItemGetResponses, params),
        method: apiRouteMethodDict[ApiRoute.CatalogItemGetResponses],
        params,
      }),
    }),
  }),
});
