import React from 'react';
import { Box } from '@cp/ds/src/components/box';
import { Typography } from '@cp/ds/src/components/typography';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { RoutedLink as Link } from 'src/shared/ui/link';
import { SidebarSectionItemProps } from './model';

export const SidebarSectionItem: React.FC<SidebarSectionItemProps> = (props) => {
  const theme = useTheme();

  if ('component' in props) {
    const Component = props.component;
    return <Component />;
  }

  const { badgeValue, href, title } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', ml: { xs: 2, md: 4 }, mt: 1, mb: 1 }}>
      {badgeValue && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 14,
            width: 14,
            backgroundColor: theme.palette['brand pink'],
            borderRadius: '50%',
            color: theme.palette.white,
            mr: 1.25,
          }}
        >
          <Typography variant="extraSmall">{badgeValue}</Typography>
        </Box>
      )}

      <Link href={href} sx={{ display: 'flex', alignItems: 'center' }} underline={false}>
        <Typography variant="body bold">{title}</Typography>
      </Link>
    </Box>
  );
};
