import { coreApi } from '@cp/shared/api/core';
import { ApiRoutePathParams } from '@common/api/routePathParams';
import { ApiRouteBody } from '@common/api/routeBody';
import { ApiRoute } from '@common/api/route';
import { generateApiUrl } from '@common/api/configure';
import { apiRouteMethodDict } from '@common/api/routeMethod';
import { ApiRouteQueryParams } from '@common/api/routeQueryParams';
import { ClientPatronageListResponse } from '@common/model/admin/clientPatronage/list.response';
import { ClientPatronageViewModel } from '@common/model/admin/clientPatronage/view';
import { ClientPatronageStage } from '@common/model/admin/clientPatronage/stage';
import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { ClientInterviewViewModel } from '@common/model/admin/clientInterview/view';
import { ClientPatronageApiTag, clientPatronageApiTags } from './tags';

export type IGetClientPatronageListQueryParameters = Omit<
  ApiRouteQueryParams[ApiRoute.AdminClientPatronageGet],
  'stage' | 'perPage' | 'page'
>;

export type IGetClientPatronageListForPageQueryParameters = Pick<
  Required<ApiRouteQueryParams[ApiRoute.AdminClientPatronageGet]>,
  'stage' | 'page'
> &
  Omit<ApiRouteQueryParams[ApiRoute.AdminClientPatronageGet], 'perPage'>;

export const clientPatronageApi = coreApi
  .enhanceEndpoints({
    addTagTypes: clientPatronageApiTags,
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getClientPatronageList: builder.query<ClientPatronageListResponse, IGetClientPatronageListQueryParameters>({
        query: (params) => {
          return {
            url: generateApiUrl(ApiRoute.AdminClientPatronageGet),
            method: apiRouteMethodDict[ApiRoute.AdminClientPatronageGet],
            params,
          };
        },
        providesTags: [ClientPatronageApiTag.List],
      }),

      getClientPatronageListForPage: builder.query<ClientPatronageListResponse, IGetClientPatronageListForPageQueryParameters>({
        query: (params) => {
          return {
            url: generateApiUrl(ApiRoute.AdminClientPatronageGet),
            method: apiRouteMethodDict[ApiRoute.AdminClientPatronageGet],
            params,
          };
        },
        providesTags: [ClientPatronageApiTag.List],
        keepUnusedDataFor: 1,
        async onQueryStarted({ stage, page, ...params }, { dispatch, queryFulfilled }) {
          const { data } = await queryFulfilled;
          const dataForPage = data[stage];

          if (!dataForPage) {
            throw new Error('Stage data is empty');
          }

          dispatch(
            clientPatronageApi.util.updateQueryData('getClientPatronageList', params, (draft) => {
              const prevData = draft[stage];
              const { items: newItems, perPage, totalCount } = dataForPage;
              const prevItemsCountToPick = perPage * (page - 1);
              const items = prevData?.items ?? [];

              if (prevData) {
                if (prevData.perPage !== perPage) {
                  throw new Error('Invalid perPage value');
                }
                if (page > 1 && prevData.items.length < prevItemsCountToPick) {
                  throw new Error('Invalid page value');
                }
              }

              items.splice(prevItemsCountToPick, prevItemsCountToPick + perPage, ...newItems);

              Object.assign(draft, {
                [stage]: {
                  items,
                  perPage,
                  totalCount,
                },
              });
            }),
          );
        },
      }),

      updateClientPatronage: builder.mutation<
        ClientPatronageViewModel,
        { params: ApiRoutePathParams[ApiRoute.AdminClientPatronageUpdate]; body: ApiRouteBody[ApiRoute.AdminClientPatronageUpdate] }
      >({
        query: ({ params, body }) => ({
          url: generateApiUrl(ApiRoute.AdminClientPatronageUpdate, params),
          method: apiRouteMethodDict[ApiRoute.AdminClientPatronageUpdate],
          body,
        }),
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            const {
              client: { mainCareerId: clientCareerId, cityId: clientCityId },
            } = data;

            if (!clientCareerId) {
              throw new Error('Empty careerId');
            }

            type ICreateRecipeParameters = Omit<IGetClientPatronageListForPageQueryParameters, 'stage' | 'page'>;

            const createRecipe =
              ({ careerId, cityId }: ICreateRecipeParameters) =>
              (draft: MaybeDrafted<ClientPatronageListResponse>) => {
                // 1. Remove from previous place
                stagesLoop: for (const stageOrString of Object.values(ClientPatronageStage)) {
                  if (typeof stageOrString === 'string') {
                    continue;
                  }

                  const stageData = draft[stageOrString];

                  if (!stageData) {
                    continue;
                  }

                  for (let i = 0; i < stageData.items.length; i++) {
                    if (stageData.items[i].id === data.id) {
                      const pageToRefetch = Math.ceil(stageData.items.length / stageData.perPage);

                      stageData.items.splice(i, 1);

                      dispatch(
                        clientPatronageApi.endpoints.getClientPatronageListForPage.initiate(
                          {
                            stage: stageOrString,
                            careerId,
                            cityId,
                            page: pageToRefetch,
                          },
                          { forceRefetch: true },
                        ),
                      );

                      break stagesLoop;
                    }
                  }
                }

                // 2. Insert into new place
                const stagePrevData = draft[data.stage];

                if (stagePrevData) {
                  Object.assign(draft, {
                    [data.stage]: {
                      ...stagePrevData,
                      items: [
                        data,
                        ...(stagePrevData.items.length % stagePrevData.perPage ? stagePrevData.items : stagePrevData.items.slice(0, -1)),
                      ],
                      totalCount: stagePrevData.totalCount + 1,
                    },
                  });
                }
              };

            const parametersSeq: ICreateRecipeParameters[] = [
              { careerId: clientCareerId },
              { careerId: clientCareerId, cityId: clientCityId },
            ];

            for (const parameters of parametersSeq) {
              dispatch(clientPatronageApi.util.updateQueryData('getClientPatronageList', parameters, createRecipe(parameters)));
            }
          } catch {}
        },
      }),

      scheduleClientInterview: builder.mutation<
        ClientInterviewViewModel,
        {
          patchParams?: IGetClientPatronageListQueryParameters;
          params: ApiRoutePathParams[ApiRoute.AdminClientInterviewSchedule];
          body: ApiRouteBody[ApiRoute.AdminClientInterviewSchedule];
        }
      >({
        query: ({ params, body }) => ({
          url: generateApiUrl(ApiRoute.AdminClientInterviewSchedule, params),
          method: apiRouteMethodDict[ApiRoute.AdminClientInterviewSchedule],
          body,
        }),
        async onQueryStarted({ patchParams }, { dispatch, queryFulfilled }) {
          if (!patchParams) {
            return;
          }

          try {
            const { data } = await queryFulfilled;

            type ICreateRecipeParameters = Omit<IGetClientPatronageListForPageQueryParameters, 'stage' | 'page'>;

            const recipe = (draft: MaybeDrafted<ClientPatronageListResponse>) => {
              stagesLoop: for (const stageOrString of Object.values(ClientPatronageStage)) {
                if (typeof stageOrString === 'string') {
                  continue;
                }

                const stageData = draft[stageOrString];

                if (!stageData) {
                  continue;
                }

                for (const item of stageData.items) {
                  if (item.client.id === data.clientId) {
                    item.client.interview = data;
                    break stagesLoop;
                  }
                }
              }
            };

            const parametersSeq: ICreateRecipeParameters[] = [{ careerId: patchParams.careerId }, patchParams];

            for (const parameters of parametersSeq) {
              dispatch(clientPatronageApi.util.updateQueryData('getClientPatronageList', parameters, recipe));
            }
          } catch {}
        },
      }),
    }),
  });
