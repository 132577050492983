import React, { useCallback } from 'react';
import { CareerTag } from '@cp/entities/career';
import { Skeleton } from '@cp/ds/src/components/skeleton';
import { ApiServerEntityIDType } from '@cp/utils/apiServer/types';
import { ClientPhotoCard } from '@cp/entities/clientPhoto';
import { ClientInteractionViewModel } from '@common/model/stats/clientInteractionViewModel';
import { SxProps } from '@cp/ds';
import { InteractionCard } from 'src/entities/clientInteraction';
import { coreClientProfileApi } from 'src/entities/clientProfile/api';
import { getClientSummary } from 'src/entities/client';
import { useScrollContainer } from 'src/shared/ui/scrollContainer';
import { ClientNameLazy } from 'src/entities/clientProfile';
import { useMarkViewed } from '../../model';

export interface ResponseDelayCardProps {
  interaction: ClientInteractionViewModel;
  clientId: ApiServerEntityIDType;
  resumeViewer?: (id: ApiServerEntityIDType) => void;
  sx?: SxProps;
}

export const ResponseDelayCard: React.FC<ResponseDelayCardProps> = ({ clientId, interaction, resumeViewer, ...props }) => {
  const { data: client } = coreClientProfileApi.endpoints.getClientProfile.useQuery({ clientId });
  const markViewed = useMarkViewed();
  const { ref } = useScrollContainer();

  const onClick = useCallback(() => {
    if (!resumeViewer || !client?.mainResumeId) {
      return;
    }
    if (!interaction.viewedByModerator) {
      markViewed(interaction.id);
    }
    resumeViewer(client.mainResumeId);
  }, [resumeViewer, client?.mainResumeId, interaction, markViewed]);

  return (
    <InteractionCard
      onClick={onClick}
      photo={<ClientPhotoCard data={client} lazyLoadProps={{ overflow: true, scrollContainer: ref.current || undefined }} size={60} />}
      tags={
        client?.mainCareerId ? (
          <CareerTag careerId={client.mainCareerId} />
        ) : (
          <Skeleton>
            <CareerTag careerId={1} />
          </Skeleton>
        )
      }
      title={<ClientNameLazy client={client} generateName={getClientSummary} variant="body bold" />}
      viewed={interaction.viewedByModerator}
      {...props}
    />
  );
};
