import { Type } from 'class-transformer';
import { Allow, IsEnum, ValidateNested } from 'class-validator';
import { DialogEvent } from '../dialogEvent';
import { DialogEventType } from '../dialogEventType';

export class DialogMessageDeliveryReportPayload {
  @Allow()
  @Type(() => Number)
  dialogId: number;

  @Allow()
  @Type(() => Number)
  messageId: number;
  // memberId: number;
}

export class DialogMessageReceivedEvent implements DialogEvent<DialogMessageDeliveryReportPayload> {
  readonly type = DialogEventType.DeliveryReport;
  readonly payload: DialogMessageDeliveryReportPayload;
  readonly createdAt: Date;

  constructor(payload: DialogMessageDeliveryReportPayload, createdAt = new Date()) {
    this.payload = payload;
    this.createdAt = createdAt;
  }
}

export class DialogMessageDeliveryReportDTO implements DialogEvent<DialogMessageDeliveryReportPayload> {
  @IsEnum(DialogEventType)
  readonly type: DialogEventType;

  @Type(() => DialogMessageDeliveryReportPayload)
  @ValidateNested()
  readonly payload: DialogMessageDeliveryReportPayload;

  @Allow()
  @Type(() => Date)
  readonly createdAt: Date;
}
