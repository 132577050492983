import React from 'react';
import useForkRef from '@mui/utils/useForkRef';
import { StyledTextAreaContainer } from '../textArea/components/styledContainer';
import { TextAreaRoot } from '../textArea/components/root';
import { TextAreaLabel } from '../textArea/components/label';
import { StyledTextAreaAutoSize } from './components/styledTextAreaAutoSize';
import { TextAreaAutoSizeProps } from './model';

export const TextAreaAutoSize = React.forwardRef<HTMLTextAreaElement, TextAreaAutoSizeProps>((props, ref) => {
  const { error = false, disabled = false, placeholder = 'Введите текст', sx, label, size = 'normal', ...otherProps } = props;

  const innerRef = React.useRef<HTMLTextAreaElement>(null);
  const inputRef = useForkRef(innerRef, ref);
  const id = React.useId();

  const [focused, setFocused] = React.useState(false);
  const ownerState = { error, disabled, focused, size };

  React.useEffect(() => {
    if (!innerRef.current) {
      return;
    }

    const textArea = innerRef.current;
    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);

    textArea.addEventListener('focus', onFocus);
    textArea.addEventListener('blur', onBlur);

    return () => {
      textArea.removeEventListener('focus', onFocus);
      textArea.removeEventListener('blur', onBlur);
    };
  }, []);

  return (
    <TextAreaRoot sx={sx}>
      {label && (
        <TextAreaLabel htmlFor={id} ownerState={ownerState}>
          {label}
        </TextAreaLabel>
      )}
      <StyledTextAreaContainer ownerState={ownerState}>
        <StyledTextAreaAutoSize id={id} placeholder={placeholder} ref={inputRef} size={size} {...otherProps} />
      </StyledTextAreaContainer>
    </TextAreaRoot>
  );
});
