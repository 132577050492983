import React from 'react';
import { Accordion } from '@cp/ds/src/components/accordion';
import { Stack } from '@cp/ds/src/components/stack';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { Badge } from '@cp/ds/src/components/badge';
import { Typography } from '@cp/ds/src/components/typography';
import { Box } from '@cp/ds/src/components/box';
import { ChevronDownIcon } from '@cp/ds/src/icons';
import { RoutedLink as Link } from 'src/shared/ui/link';
import { SidebarSectionItem } from '../sectionItem';
import { LAYOUT_SIDE_INDENT_DESKTOP, LAYOUT_SIDE_INDENT_MOBILE } from '../../../../const';
import { SidebarSectionProps, SidebarSectionWithoutItemsProps } from './model';

export const SidebarSectionWithoutItems: React.FC<SidebarSectionWithoutItemsProps> = (props) => {
  const theme = useTheme();

  if ('component' in props) {
    const Component = props.component;
    return <Component />;
  }

  const { icon, badgeValue, href, title, sxIcon } = props;
  const Icon = icon;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        pl: { xs: LAYOUT_SIDE_INDENT_MOBILE, md: LAYOUT_SIDE_INDENT_DESKTOP },
        py: 2,
        pr: 1.5,
        borderBottom: `1px solid ${theme.palette['gray light']}`,
      }}
    >
      {Icon && (
        <Badge badgeContent={badgeValue}>
          <Icon sx={sxIcon} />
        </Badge>
      )}
      <Link href={href} onClick={props.onClick} underline={false}>
        <Typography sx={{ typography: { xs: 'button', md: 'h6' } }}>{title}</Typography>
      </Link>
    </Box>
  );
};

export const SidebarSection: React.FC<SidebarSectionProps> = (props) => {
  if ('sectionItems' in props) {
    const { sectionItems, title } = props;
    return (
      <Accordion
        details={
          <Stack>
            {sectionItems.map((itemProps, i) => (
              <SidebarSectionItem key={i} {...itemProps} />
            ))}
          </Stack>
        }
        isExpanded={props.isExpanded}
        summary={title}
        summaryIcon={ChevronDownIcon}
        summarySx={{ pl: { xs: LAYOUT_SIDE_INDENT_MOBILE, md: LAYOUT_SIDE_INDENT_DESKTOP } }}
      />
    );
  } else {
    return <SidebarSectionWithoutItems {...props} />;
  }
};
