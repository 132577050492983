import React from 'react';
import { SvgIcon } from '../../../components/svgIcon';
import { SvgIconProps } from '../../../components/svgIcon/model';

export const PsychologistIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="career/common/psychologist" ref={ref as any} {...props}>
      <path
        clipRule="evenodd"
        d="M4.41367 5.63174C3.60257 6.61198 3.11111 7.95125 3.11111 9.34945C3.11111 12.4838 5.31372 15.1149 7.63939 17.0085C8.79083 17.946 9.945 18.68 10.8125 19.1798C11.2456 19.4294 11.6058 19.6197 11.8563 19.747C11.9062 19.7724 11.9518 19.7953 11.9927 19.8156C12.0346 19.7936 12.0814 19.7688 12.1329 19.7412C12.3841 19.6068 12.7448 19.4065 13.1787 19.1459C14.0476 18.6239 15.2036 17.8633 16.3569 16.9072C18.6909 14.9725 20.8889 12.3419 20.8889 9.34945C20.8889 7.95146 20.3975 6.61219 19.5864 5.6319C18.7781 4.65483 17.6741 4.05521 16.4271 4.05521C14.7033 4.05521 13.3663 5.12139 12.4987 6.7997L12 7.76432L11.5013 6.7997C10.6336 5.12128 9.29661 4.05521 7.57289 4.05521C6.326 4.05521 5.22206 4.65472 4.41367 5.63174ZM12 20.4109C11.759 20.8863 11.7588 20.8862 11.7586 20.8861L11.7561 20.885L11.7498 20.882L11.7271 20.8715C11.7075 20.8623 11.6793 20.8489 11.6429 20.8315C11.5702 20.7965 11.4651 20.745 11.3321 20.6774C11.0661 20.5422 10.6884 20.3426 10.2361 20.082C9.33278 19.5614 8.12583 18.7945 6.91617 17.8096C4.51961 15.8583 2 12.9586 2 9.34945C2 7.72691 2.56735 6.15528 3.54001 4.97978C4.51533 3.80102 5.92006 3 7.57289 3C9.51933 3 10.9995 4.03588 12 5.49757C13.0004 4.03595 14.4806 3 16.4271 3C18.0801 3 19.4848 3.80117 20.4601 4.97994C21.4327 6.15555 22 7.72717 22 9.34945C22 12.8224 19.4757 15.7226 17.0876 17.7022C15.8798 18.7033 14.6747 19.4958 13.7727 20.0376C13.3211 20.3089 12.9441 20.5183 12.6787 20.6604C12.546 20.7314 12.4411 20.7858 12.3687 20.8227C12.3325 20.8411 12.3044 20.8552 12.2849 20.8649L12.2624 20.876L12.2562 20.8791L12.2543 20.88L12.2537 20.8803C12.2535 20.8804 12.2533 20.8805 12 20.4109ZM12 20.4109L12.2537 20.8803L12.0077 21L11.7586 20.8861L12 20.4109Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M16 7V13.8788C16 14.63 15.6874 15.2024 15.1598 15.5648C14.6553 15.9114 14.021 16.0206 13.4436 15.9969C12.8637 15.9731 12.2754 15.8135 11.8163 15.5497C11.4765 15.3545 11.1408 15.0591 11.0029 14.6707C10.3688 14.904 9.63554 14.95 8.99283 14.8253C8.48843 14.7275 7.99247 14.517 7.61644 14.1632C7.23152 13.8011 7 13.3135 7 12.7252C7 11.8784 7.33047 11.2665 7.92014 10.8195C8.46616 10.4057 9.20658 10.1578 9.95571 9.93583C10.1314 9.88376 10.3109 9.83214 10.4936 9.77965C11.9216 9.36906 13.5448 8.90243 15.0742 7.71738L16 7ZM8.63749 11.6132C8.32188 11.8524 8.125 12.1661 8.125 12.7252C8.125 13.049 8.24504 13.2777 8.42262 13.4447C8.60909 13.6201 8.88657 13.7516 9.22592 13.8174C9.93079 13.9542 10.7053 13.776 11.1023 13.4125L12.0625 12.5332V14.2919C12.0625 14.3571 12.1188 14.5073 12.4142 14.677C12.6933 14.8374 13.0894 14.9511 13.4939 14.9677C13.9009 14.9844 14.251 14.9013 14.4847 14.7407C14.6954 14.596 14.875 14.3464 14.875 13.8788V9.14165C13.4441 10.0156 11.9927 10.4307 10.8234 10.7651C10.6425 10.8169 10.4684 10.8667 10.3021 10.916C9.53952 11.142 8.99671 11.3409 8.63749 11.6132Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12.6556 12.0712C12.9866 12.2163 13.0985 12.5358 12.9054 12.7847C11.7026 14.335 11.4015 15.1149 11.3883 15.6416C11.3818 15.8993 11.4437 16.1222 11.5481 16.3843C11.5788 16.4613 11.617 16.5498 11.6589 16.6468C11.7483 16.8536 11.8542 17.0987 11.9383 17.3517C12.0312 17.6312 11.8052 17.9144 11.4334 17.9843C11.0616 18.0542 10.6849 17.8842 10.5919 17.6047C10.5181 17.3825 10.4416 17.2068 10.3617 17.0231C10.3153 16.9162 10.2676 16.8067 10.2187 16.6837C10.0938 16.3701 9.99044 16.0311 10.0007 15.6218C10.021 14.8143 10.4807 13.839 11.7066 12.2589C11.8997 12.0101 12.3246 11.926 12.6556 12.0712Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
});
