import { InputMaskTokenDictionary } from '../../../../../hooks/useInputMask/model';

export const CARD_NUMBER_INPUT_MASK = '#### #### #### ####';
export const CARD_NUMBER_LENGTH = CARD_NUMBER_INPUT_MASK.length;

export const cardNumberInputMaskTokens: InputMaskTokenDictionary = {
  '#': {
    pattern: /\d/,
  },
};
