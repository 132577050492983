import { MessageType } from '@common/model/dialog/message/messageType';

export type DialogMessageIdentity = string;

/**
 * Generate unique reproducible message id
 */
export const generateDraftId = (dialogId: number, payloadStr: string, messageType: MessageType): DialogMessageIdentity => {
  return ['m', dialogId, messageType, payloadStr].join(':');
};
