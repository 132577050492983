import { AppTheme } from '../../theme';
import { TagColor, TagSize } from './model';

export const TAG_COLOR_DEFAULT: TagColor = 'gray light';
export const TAG_SIZE_DEFAULT: TagSize = 'normal';
export const TAG_VERTICAL_SPACING = 4;
export const TAG_HORIZONTAL_SPACING = 8;
export const TAG_BORDER_WIDTH = 1;

export const TAG_LIST_COLLAPSED_COUNT = 8;

export const tagColorDict: Record<
  TagColor,
  {
    color: keyof AppTheme['palette'];
    backgroundColor: keyof AppTheme['palette'];
    backgroundColorWithIcon: keyof AppTheme['palette'];
    borderColor?: keyof AppTheme['palette'];
    backgroundColorHover: keyof AppTheme['palette'];
  }
> = {
  'gray light': {
    color: 'brand gray',
    backgroundColor: 'gray ultra light',
    backgroundColorWithIcon: 'gray ultra light',
    borderColor: 'gray light',
    backgroundColorHover: 'gray light',
  },
  pink: {
    color: 'white',
    backgroundColor: 'brand pink',
    backgroundColorWithIcon: 'brand pink hover',
    backgroundColorHover: 'brand pink hover',
  },
  peach: {
    color: 'brand gray',
    backgroundColor: 'peach',
    backgroundColorWithIcon: 'peach',
    backgroundColorHover: 'peach',
  },
  white: {
    color: 'brand gray',
    backgroundColor: 'white',
    backgroundColorWithIcon: 'white',
    borderColor: 'gray light',
    backgroundColorHover: 'gray ultra light',
  },
};
