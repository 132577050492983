import React from 'react';
import { Tag, TagProps } from '@cp/ds/src/components/tag';
import { CareerId } from '@common/const/catalog/career';
import { getCareerIcon, useCareerDeclination } from '../../lib';

export interface CareerTagProps extends TagProps {
  careerId: CareerId;
  declinationCount?: number;
}
export const CareerTag: React.FC<CareerTagProps> = ({ careerId, declinationCount = 1, ...props }) => {
  const declination = useCareerDeclination();
  const Icon = getCareerIcon(careerId);
  return (
    <Tag iconLeft={<Icon />} {...props}>
      {declination(declinationCount, careerId)}
    </Tag>
  );
};
