import { ApiServerEntityIDType } from '@cp/utils/apiServer/types';
import { useCallback } from 'react';
import { coreClientApi } from 'src/entities/client';

export const useClientDelete = () => {
  const [doDelete] = coreClientApi.endpoints.deleteClient.useMutation();
  return useCallback(
    (id: ApiServerEntityIDType) => {
      const confirmed = window.confirm('Удалить клиента?');
      if (!confirmed) {
        return;
      }
      return doDelete({ id });
    },
    [doDelete],
  );
};
