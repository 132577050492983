export const CLOUDPAYMENTS_PUBLIC_ID = 'pk_f10af26ef744fb362e8e071dcb29d';
export const CLOUDPAYMENTS_DEV_PUBLIC_ID = 'pk_281046486b632b886eac04949d0a9';
export const GOOGLE_MERCHANT_ID = 'BCR2DN6TTOQZTGT4';
export const APPLE_MERCHANT_ID = 'merchant.ru.youhelp';

export enum PaymentSystem {
  Unknown = 0,
  Visa = 1, // 'Visa',
  Maestro = 2, // Maestro
  MasterCard = 3, // 'MasterCard',
  Mir = 4, // 'MIR',
}
