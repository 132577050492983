import React from 'react';
import { SvgIcon, SvgIconProps } from '../../../components/svgIcon';

export const PhoneIconRound = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="social/round/phoneRound" ref={ref as any} {...props}>
      <g clipPath="url(#clip0_318_22870)" fill="none">
        <path
          d="M18.8506 15.966C18.8797 16.1888 18.8118 16.3825 18.6474 16.5472L16.7164 18.4648C16.6293 18.5616 16.5156 18.644 16.3753 18.7117C16.2349 18.7796 16.097 18.8231 15.9616 18.8425C15.9519 18.8425 15.9228 18.845 15.8744 18.8498C15.826 18.8546 15.7631 18.8571 15.6857 18.8571C15.5018 18.8571 15.2041 18.8256 14.7928 18.7627C14.3814 18.6997 13.8781 18.5447 13.2829 18.2978C12.6876 18.0508 12.0125 17.6803 11.2576 17.1864C10.5026 16.6925 9.69925 16.0145 8.84751 15.1525C8.16998 14.4842 7.60861 13.845 7.16338 13.2348C6.71815 12.6247 6.36003 12.0605 6.08902 11.5423C5.81799 11.0242 5.61473 10.5544 5.47923 10.1331C5.34372 9.71182 5.25177 9.34863 5.20338 9.04354C5.15498 8.73846 5.13562 8.49875 5.1453 8.32441C5.15498 8.15008 5.15982 8.05323 5.15982 8.03386C5.17918 7.89827 5.22273 7.76025 5.29049 7.61982C5.35824 7.47938 5.44051 7.36558 5.5373 7.27841L7.46824 5.34621C7.60374 5.21062 7.75861 5.14282 7.93283 5.14282C8.05865 5.14282 8.16996 5.17914 8.26675 5.25178C8.36354 5.32442 8.44581 5.41401 8.51356 5.52055L10.067 8.46969C10.1541 8.62466 10.1783 8.79415 10.1396 8.97817C10.1009 9.16219 10.0186 9.31715 9.89281 9.44306L9.18141 10.1549C9.16205 10.1743 9.14511 10.2058 9.13059 10.2494C9.11607 10.2929 9.10881 10.3293 9.10881 10.3583C9.14753 10.5617 9.23464 10.7941 9.37014 11.0556C9.48629 11.2881 9.66535 11.5714 9.90732 11.9055C10.1493 12.2397 10.4929 12.6246 10.9381 13.0605C11.3737 13.506 11.7608 13.8522 12.0996 14.0992C12.4383 14.3461 12.7215 14.5278 12.9489 14.644C13.1764 14.7602 13.3506 14.8305 13.4716 14.8546L13.653 14.8909C13.6724 14.8909 13.7039 14.8837 13.7474 14.8692C13.791 14.8546 13.8224 14.8377 13.8418 14.8183L14.6693 13.9757C14.8436 13.8207 15.0468 13.7432 15.2791 13.7432C15.4437 13.7432 15.5743 13.7723 15.6711 13.8304H15.6856L18.4876 15.4866C18.6909 15.6126 18.8119 15.7723 18.8506 15.966Z"
          fill="#405368"
        />
        <circle cx="12" cy="12" r="11.5" stroke="#405368" strokeWidth="1" />
      </g>
    </SvgIcon>
  );
});
