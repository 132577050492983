/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from 'react';
import { SxProps } from '@mui/material';
import { AppTheme } from '@cp/ds';
import { TagStateProps } from './model';
import { TAG_BORDER_WIDTH, TAG_HORIZONTAL_SPACING, TAG_VERTICAL_SPACING, tagColorDict } from './const';

export const buildTagSX = ({ theme, ownerState }: { theme: AppTheme; ownerState: TagStateProps }): SxProps => {
  const { isFocused, size } = ownerState;
  const { color, backgroundColor, backgroundColorWithIcon, backgroundColorHover, borderColor } = tagColorDict[ownerState.color];

  const commonStyles: React.CSSProperties = {
    backgroundColor: theme.palette[ownerState.hasIconLeft || ownerState.isClosable ? backgroundColorWithIcon : backgroundColor] as string,
    color: theme.palette[color] as string,
  };

  let verticalSpacing = TAG_VERTICAL_SPACING;
  let horizontalSpacing = TAG_HORIZONTAL_SPACING;

  if (isFocused) {
    commonStyles.backgroundColor = backgroundColorHover;
  }

  if (borderColor) {
    commonStyles.borderWidth = TAG_BORDER_WIDTH;
    commonStyles.borderStyle = 'solid';
    commonStyles.borderColor = theme.palette[borderColor] as string;

    verticalSpacing -= TAG_BORDER_WIDTH * 2;
    horizontalSpacing -= TAG_BORDER_WIDTH * 2;
  } else {
    commonStyles.borderWidth = 0;
    commonStyles.borderStyle = 'none';
    commonStyles.borderColor = 'transparent';
  }

  return {
    ...theme.typography[size === 'normal' ? 'body bold' : 'small text bold'],
    ...commonStyles,
    position: 'relative',
    overflow: 'hidden',
    display: 'inline-flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    textTransform: 'none',
    transition: theme.transitions.create(['background-color', 'border-color', 'color'], {
      duration: theme.transitions.duration.short,
    }),
    WebkitTapHighlightColor: 'transparent',
    borderRadius: `${theme.borderRadius.small}px`,
    padding: `${verticalSpacing}px ${horizontalSpacing}px`,

    '&:active': {
      outline: 'none',
    },

    '&:focus': {
      outline: 'none',
    },

    '@media (pointer: fine)': {
      '&:hover': {
        backgroundColor: backgroundColorHover,
      },
    },
  };
};
