import React, { ReactNode } from 'react';
import { Box } from '@cp/ds/src/components/box';
import { Card } from '@cp/ds/src/components/card';
import { CardProps } from '@cp/ds/src/components/card/model';
import { Stack } from '@cp/ds/src/components/stack';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { styled } from '@cp/ds/src/theme/styled';

const Root = styled(Card)({
  borderRadius: '16px',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  position: 'relative',
});

export interface ResumeCardProps extends CardProps {
  hidden?: ReactNode | ReactNode[];
  header?: ReactNode;
  achievements?: ReactNode | ReactNode[];
  profilePhoto?: ReactNode;
  mediaButton?: ReactNode;
  footer?: ReactNode;
}

export const ResumeCard: React.FC<ResumeCardProps> = ({ mediaButton, hidden, header, achievements, profilePhoto, footer, ...props }) => {
  const theme = useTheme();
  return (
    <Root {...props}>
      {header && (
        <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between', flexWrap: 'nowrap', py: 1, px: 2 }}>
          {header}
        </Stack>
      )}
      {hidden}
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          backgroundColor: theme.palette.background.paper,
        }}
      >
        {profilePhoto}
        <Stack
          direction="row"
          sx={{
            flexWrap: 'wrap',
            gap: 0.5,
            paddingLeft: 2,
            paddingTop: 1.5,
            position: 'absolute',
            left: 0,
            top: 0,
            right: 0,
            bottom: 'auto',
          }}
        >
          {achievements}
        </Stack>
        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            bottom: 20,
            transform: 'translateX(-50%)',
            zIndex: 2,
          }}
        >
          {mediaButton}
        </Box>
      </Box>
      {footer}
    </Root>
  );
};
