import React from 'react';
import { TextInputRoot } from './components/root';
import { StyledTextInput } from './components/input';
import { TextInputProps, TextInputStateProps, TextInputTagProps } from './model';

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const {
    size = 'normal',
    error = false,
    disabled = false,
    placeholder = 'Text',
    htmlType = 'text',
    sx,
    inputSx,
    value = '',
    startAdornment,
    endAdornment,
    rootRef,
    ...otherProps
  } = props;
  const [isFocused, setFocused] = React.useState(false);

  // if (otherProps.htmlType) {
  //   console.log({ props, otherProps });
  // }

  const ownerState: Required<TextInputStateProps> = {
    size,
    error,
    htmlType,
    disabled,
    focused: isFocused,
    startAdornment,
    endAdornment,
  } as Required<TextInputStateProps>;

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <TextInputRoot ownerState={ownerState} ref={rootRef as any} sx={sx}>
      {startAdornment}
      <StyledTextInput
        disabled={disabled}
        onBlur={() => setFocused(false)}
        onFocus={() => setFocused(true)}
        ownerState={ownerState}
        placeholder={placeholder}
        ref={
          ref as React.Ref<
            React.Component<
              TextInputTagProps & {
                ownerState: Required<TextInputStateProps>;
              },
              unknown,
              unknown
            >
          >
        }
        sx={inputSx}
        type={htmlType}
        value={value ?? ''}
        {...otherProps}
      />
      {endAdornment}
    </TextInputRoot>
  );
});
