import { coreApi } from '@cp/shared/api/core';
import { CareerViewModel } from '@common/model/catalog/career';
import { CoreCareerApiTags, coreCareerApiTagTypes } from './tags';
import { careerDictionaryDataAdaptor } from './dataAdaptor';
import { CareerDictionaryResponse } from './response';

export const coreCareerApi = coreApi.enhanceEndpoints({ addTagTypes: coreCareerApiTagTypes }).injectEndpoints({
  endpoints: (builder) => ({
    getCareers: builder.query<CareerDictionaryResponse, void>({
      query: () => ({ url: 'catalog/careers' }),
      transformResponse: (response: CareerViewModel[]) => careerDictionaryDataAdaptor(response),
      providesTags: [{ type: CoreCareerApiTags.CareerList }],
    }),
  }),
});
