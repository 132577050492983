import React, { PropsWithChildren } from 'react';
import { Box, BoxProps } from '@cp/ds/src/components/box';
import { styled } from '@cp/ds/src/theme/styled';

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
  marginBlock: 0,
  paddingInline: 0,
}));

export interface ResponseListProps extends BoxProps, PropsWithChildren {}
export const ResponseList: React.FC<ResponseListProps> = ({ children, ...props }) => {
  return (
    <Root component="ul" {...props}>
      {children}
    </Root>
  );
};
