import React from 'react';
import { Typography } from '@cp/ds/src/components/typography';
import { SignoutIcon } from '@cp/ds/src/icons/signout';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { Button } from '@cp/ds/src/components/button';
import { useLogout } from '../../../../../hooks/useLogout';
import { LAYOUT_SIDE_INDENT_DESKTOP, LAYOUT_SIDE_INDENT_MOBILE } from '../../../const';

export const Logout = () => {
  const theme = useTheme();
  const { logout, isLoading } = useLogout();
  return (
    <Button
      disabled={isLoading}
      iconRight={<SignoutIcon />}
      onClick={isLoading ? undefined : logout}
      sx={{
        borderTop: `1px solid ${theme.palette['gray light']}`,
        py: 2,
        pl: { xs: LAYOUT_SIDE_INDENT_MOBILE, md: LAYOUT_SIDE_INDENT_DESKTOP },
      }}
      variant="text"
    >
      <Typography sx={{ typography: { xs: 'button', md: 'h6', width: '100%', textAlign: 'left' } }}>Выйти</Typography>
    </Button>
  );
};
