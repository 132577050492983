export enum DeclinationMnemonic {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
  Child = 'child',
  ChildAccusative = 'childAccusative',
  Proclamation = 'proclamation',
  Vacancy = 'vacancy',
  Feedback = 'feedback',
  Reply = 'reply',
  Specialist = 'specialist',
}
