import React from 'react';
import { Grid } from '@cp/ds/src/components/grid';
import { Tag } from '@cp/ds/src/components/tag';
import { Typography } from '@cp/ds/src/components/typography';
import { Box } from '@cp/ds/src/components/box';
import { ClientProfileTagsProps } from './model';

export const ClientProfileTags: React.FC<ClientProfileTagsProps> = ({ tags, sx }) => {
  return (
    <Box sx={sx}>
      <Grid columnSpacing={1.25} container direction="row" flexWrap="wrap" rowSpacing={1}>
        {tags.map(({ icon, label, onClick }, i) => {
          const Icon = icon;
          return (
            <Grid item key={i}>
              <Tag iconLeft={Icon && <Icon />} onClick={onClick}>
                {typeof label === 'string' ? <Typography variant="body">{label}</Typography> : label}
              </Tag>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
