import { useCallback, useState } from 'react';
import { ApiServerEntityIDType } from '@cp/utils/apiServer/types';
import { IDialogFindQuery } from './findDialog.interface';
import { useDialogIdGetter } from './useDialogIdGetter';

export const useOpenDialog = () => {
  const [load, isLoading] = useDialogIdGetter();
  const [dialogId, setActiveDialogId] = useState<ApiServerEntityIDType | undefined>();
  const openDialog = useCallback(
    async (dialogFindQuery: IDialogFindQuery) => {
      const id = await load(dialogFindQuery);
      if (id) {
        setActiveDialogId(id);
      }
    },
    [load],
  );
  const onClose = () => setActiveDialogId(undefined);
  return { isLoading, openDialog, dialogId, onClose };
};
