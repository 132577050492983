import { EntityType } from '@common/model/admin/entityView';
import { ApiServerEntityIDType } from '@cp/utils/apiServer/types';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { coreEntityViewApi } from '../api';

type HasIdentity = { id: ApiServerEntityIDType; isViewed?: boolean };
type Fn<T extends HasIdentity, R = void> = (entity: T) => R;

export const useMarkViewedDecorator = (type: EntityType) => {
  const dispatch = useDispatch();
  return useCallback(
    <T extends HasIdentity, R = void>(originalFn: Fn<T, R>) =>
      (m: T) => {
        if (!m.isViewed) {
          dispatch(coreEntityViewApi.endpoints.markViewed.initiate({ type, entityId: m.id }));
        }
        return originalFn(m);
      },
    [type, dispatch],
  );
};
