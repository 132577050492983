import React from 'react';
import { ACCORDION_DETAILS_TYPOGRAPHY_VARIANT, ACCORDION_HORIZONTAL_SPACING, ACCORDION_VERTICAL_SPACING } from '../const';
import { AccordionOwnerState, DivTagProps } from '../model';
import { styled } from '../../../theme';

type UnstyledDivComponent = React.ComponentClass<DivTagProps & { ownerState: AccordionOwnerState }>;

export const AccordionDetails = styled('div' as unknown as UnstyledDivComponent, {
  name: 'AccordionDetails',
  slot: 'Details',
})(({ theme, ownerState }) => {
  const commonStyles = {
    color: theme.palette['brand gray'],
  };

  if (ownerState.color === 'white') {
    commonStyles.color = theme.palette.white;
  }

  return {
    ...(ownerState.isDetailsPassedAsPlainText ? theme.typography[ACCORDION_DETAILS_TYPOGRAPHY_VARIANT] : undefined),
    ...commonStyles,
    padding: theme.spacing(0, ACCORDION_HORIZONTAL_SPACING, ACCORDION_VERTICAL_SPACING),
  };
});
