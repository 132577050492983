import styled from '@mui/material/styles/styled';
import { shouldForwardProp } from '@mui/system';
import { ButtonGroupStateProps, ButtonGroupValue } from '../model';

type UnstyledLabelComponent<V extends ButtonGroupValue> = React.ComponentClass<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLDivElement>, HTMLDivElement> & { ownerState: ButtonGroupStateProps<V> }
>;

export const ButtonGroupContainer = styled('div' as unknown as UnstyledLabelComponent<ButtonGroupValue>, {
  shouldForwardProp,
  name: 'Radio',
  slot: 'Group',
})(({ ownerState, theme }) => ({
  display: ['inline-flex', 'inline-grid'],
  gridTemplateColumns: `repeat(${ownerState.items.length ?? 1}, 1fr)`,
  borderRadius: theme.borderRadius.normal,
}));
