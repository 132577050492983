import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AdminCatalogItemViewModel } from '@common/model/catalog/item/admin.view';
import { TaskBoard, TaskBoardConfig } from 'src/shared/ui/taskBoard';
import { adminCatalogItemApi } from 'src/entities/catalog/item';
import { useVacancyMonitorService } from '../model';
import { MatchingModal } from './matchingModal';
import { VacancyCard } from './vacancyCard';

export interface VacancyMonitorProps {
  selectedId?: number;
  onSelected?: (id?: number) => void;
}
export const VacancyMonitor: React.FC<VacancyMonitorProps> = ({ selectedId, onSelected }) => {
  const [localId, setLocalId] = useState(NaN);
  const id = selectedId || localId;
  const [baseDate] = useState(new Date());
  const service = useVacancyMonitorService();
  const { data: currentItem } = adminCatalogItemApi.endpoints.getCatalogItem.useQuery({ params: { id } }, { skip: Number.isNaN(id) });

  const onCardActivate = useCallback(
    (model: AdminCatalogItemViewModel) => {
      onSelected?.(model.id);
      setLocalId(model.id);
    },
    [onSelected],
  );
  const onClose = useCallback(() => {
    onSelected?.();
    setLocalId(NaN);
  }, [onSelected]);

  useEffect(() => {
    service.loadItems(baseDate);
    return () => service.tearDown();
  }, [baseDate, service]);

  const config: TaskBoardConfig<AdminCatalogItemViewModel> = useMemo(() => {
    return {
      columns: service.columns.map((threshold) => {
        return {
          key: threshold.title,
          title: threshold.title,
          itemKeyGenerator: (m) => m.id,
          renderItem: (m) => <VacancyCard model={m} onActivate={onCardActivate} />,
          itemsDataSelector: () => service.getCachedItemsBySelection(threshold),
          canLoadMoreSelector: () => service.hasMore(threshold),
          onLoadMore: () => service.loadMore(threshold),
        };
      }),
    };
  }, [service, onCardActivate]);
  return (
    <>
      <TaskBoard config={config} />
      {Number.isNaN(id) || <MatchingModal catalogItemId={id} isOpen={!Number.isNaN(id)} onClose={onClose} vacancy={currentItem} />}
    </>
  );
};
