import React, { FormEventHandler, ReactNode } from 'react';
import { Box } from '@cp/ds/src/components/box';
import { mergeSx } from '@cp/ds/src/utils/sx/merge';
import { useIsMobile } from '@cp/ds/src/hooks/useIsMobile';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { SxProps } from '@cp/ds';

export interface SendFormProps {
  onSubmit: FormEventHandler<HTMLFormElement>;
  onChange?: FormEventHandler<HTMLFormElement>;
  children: ReactNode | ReactNode[];
  sx?: SxProps;
}

export const SendForm = React.forwardRef<HTMLFormElement, SendFormProps>(({ children, sx, ...props }, ref) => {
  const isMobile = useIsMobile();
  const theme = useTheme();
  return (
    <Box
      component="form"
      ref={ref}
      sx={mergeSx(
        {
          borderTop: `1px solid ${theme.palette['gray light']}`,
          display: 'flex',
          alignItems: 'center',
          zIndex: 5,
          gap: 2.5,
          backgroundColor: 'white',
          ...(isMobile
            ? {
                px: 2.5,
                py: 1,
              }
            : {
                px: 5,
                py: 2.5,
                position: 'relative',
              }),
        },
        sx,
      )}
      {...props}
    >
      {children}
    </Box>
  );
});
