import { CatalogItemViewModel } from './view';

const dict: { key: keyof CatalogItemViewModel; labelShort: string; labelEditing: string }[] = [
  {
    key: 'freeMettingWithParents',
    labelShort: 'Бесплатное знакомство',
    labelEditing: 'Готовы ли вы на бесплатное знакомство с родителями?',
  },
  { key: 'childWithDisabilities', labelShort: 'Особые дети', labelEditing: 'Готовы ли вы сидеть с особыми детьми?' },
  { key: 'baby', labelShort: 'Младенцы', labelEditing: 'Готовы ли вы сидеть с младенцами?' },
  { key: 'bedriddenPatient', labelShort: 'Уход за лежачими больными', labelEditing: 'Готовы ли вы ухаживать за лежачими больными?' },
  { key: 'workingAtHospital', labelShort: 'Сиделка в больнице', labelEditing: 'Готовы ли вы быть сиделкой в больнице?' },
  { key: 'patronage', labelShort: 'Патронаж', labelEditing: 'Готовы ли вы осуществлять патронаж?' },
  { key: 'apartment', labelShort: 'Уборка помещений', labelEditing: 'Готовы ли вы убирать помещения?' },
  { key: 'countryHouse', labelShort: 'Уборка загородных домов', labelEditing: 'Готовы ли вы убирать загородные дома?' },
  { key: 'livingAtConsumersHome', labelShort: 'Домработница с проживанием', labelEditing: 'Готовы ли вы проживать в доме нанимателя?' },
  { key: 'workingAtConsumersHome', labelShort: 'Обучение на дому', labelEditing: 'Готовы ли вы обучать на дому?' },
  { key: 'preschooler', labelShort: 'Дошкольники', labelEditing: 'Готовы ли вы обучать дошкольников?' },
  { key: 'schoolchild', labelShort: 'Школьники', labelEditing: 'Готовы ли вы обучать школьников?' },
];

export const CATALOG_ITEM_ADDITIONAL_PARAMETERS_SEQUENCE: (keyof CatalogItemViewModel)[] = dict.map(({ key }) => key);

type ParametersDict = Partial<Record<keyof CatalogItemViewModel, (typeof dict)[number]>>;

export const CATALOG_ITEM_ADDITIONAL_PARAMETERS_DICT = dict.reduce<ParametersDict>((acc, item) => {
  acc[item.key] = item;
  return acc;
}, {});
