import { ApiServerEntityIDType } from '@cp/utils/apiServer/types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { clientInteractionsCoreApi } from '../api';
import { slice } from './slice';

export const useMarkViewed = () => {
  const dispatch = useDispatch();
  const [markViewed] = clientInteractionsCoreApi.endpoints.markInteractionViewed.useMutation();
  return useCallback(
    (id: ApiServerEntityIDType) => {
      dispatch(slice.actions.markViewed({ id }));
      return markViewed({ id });
    },
    [markViewed, dispatch],
  );
};
