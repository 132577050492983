import React from 'react';
import { Drawer } from '../drawer';
import { TRANSITION_DURATION, Z_INDEX } from './const';
import { ModalViewBaseProps } from './model';

const DrawerRightView: React.FC<ModalViewBaseProps> = ({ children, onClose, isOpen }) => {
  return (
    <Drawer
      BackdropProps={{ sx: { zIndex: 10 } }}
      ModalProps={{
        components: { Root: 'div' },
        disableAutoFocus: true,
        disableEnforceFocus: true,
        disableRestoreFocus: true,
      }}
      PaperProps={{ elevation: 0, sx: { backgroundColor: 'background.paper', overflowY: 'hidden', zIndex: Z_INDEX } }}
      anchor="right"
      onClose={onClose}
      open={isOpen}
      sx={{ zIndex: 11 }}
      transitionDuration={TRANSITION_DURATION}
      variant="temporary"
    >
      {children}
    </Drawer>
  );
};

export default DrawerRightView;
