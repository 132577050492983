import { DateFormat } from '@common/const/date';
import { ClientPatronageHistoryViewModel } from '@common/model/admin/clientPatronage/history.view';
import { ClientPatronageStage } from '@common/model/admin/clientPatronage/stage';
import { CLIENT_PATRONAGE_STAGE_CONFIG } from '@common/model/admin/clientPatronage/stage.config';
import { ClientPatronageViewModel } from '@common/model/admin/clientPatronage/view';
import format from 'date-fns/format';
import React from 'react';

export interface IHistoryItem {
  label: string;
  completedAt?: string;
}

export interface IUseHistoryItemsReturn {
  items: IHistoryItem[];
  stageToHistoryItem: Partial<Record<ClientPatronageStage, IHistoryItem>>;
}

const STAGE_TO_COMPLETED_AT_DATA_PARAMETER: Partial<Record<ClientPatronageStage, keyof ClientPatronageViewModel>> = {
  [ClientPatronageStage.Photo]: 'clientTrainingCompletedAt',
  [ClientPatronageStage.ResumeFilling]: 'clientPhotoLoadedAt',
  [ClientPatronageStage.ResumeModeration]: 'clientMainResumeFilledAt',
};

const STAGES_SEQUENCE = [
  ClientPatronageStage.Initial,
  ClientPatronageStage.Interview,
  ClientPatronageStage.Training,
  ClientPatronageStage.Photo,
  ClientPatronageStage.ResumeFilling,
  ClientPatronageStage.ResumeModeration,
  ClientPatronageStage.Rejected,
];

export const useHistoryItems = ({ data }: { data: ClientPatronageViewModel }): IUseHistoryItemsReturn => {
  return React.useMemo(() => {
    const stageToFirstHistoryRecord = data.client.patronageHistory.reduce<
      Partial<Record<ClientPatronageStage, ClientPatronageHistoryViewModel>>
    >((acc, record) => {
      const prevRecord = acc[record.stage];

      if (!prevRecord || new Date(prevRecord.createdAt) > new Date(record.createdAt)) {
        acc[record.stage] = record;
      }

      /**
       * Calculate history record for "interview completed" when training stage is skipped for client
       * TODO: Fix this when client interview entity will be fully implemented
       */
      const trainingRecord = acc[ClientPatronageStage.Training];
      if (
        record.stage > ClientPatronageStage.Training &&
        (!trainingRecord || new Date(trainingRecord.createdAt) > new Date(record.createdAt))
      ) {
        acc[ClientPatronageStage.Training] = acc[record.stage];
      }

      return acc;
    }, {});

    const result: IUseHistoryItemsReturn = {
      items: [],
      stageToHistoryItem: {},
    };

    for (const stage of STAGES_SEQUENCE) {
      const record = stageToFirstHistoryRecord[stage];
      const { historyLabel } = CLIENT_PATRONAGE_STAGE_CONFIG[stage];

      if (!historyLabel) {
        continue;
      }

      const completedAtDataParameter = STAGE_TO_COMPLETED_AT_DATA_PARAMETER[stage];
      const completedAt = completedAtDataParameter
        ? (data[completedAtDataParameter] as string | undefined)
        : record
          ? record.createdAt
          : undefined;

      const item: IHistoryItem = {
        label: typeof historyLabel === 'function' ? historyLabel({ client: data.client }) : historyLabel,
        completedAt: completedAt ? format(new Date(completedAt), DateFormat.DateTimeModerationTask) : undefined,
      };

      result.stageToHistoryItem[stage] = item;

      result.items.push(item);
    }

    return result;
  }, [data]);
};
