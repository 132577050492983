/* eslint-disable @typescript-eslint/no-magic-numbers */
import { CareerId } from './career';

export const EXPERIENCE_MIN = 0;

export const EXPERIENCE_MAX = 120;

export const CATALOG_ITEM_DESCRIPTION_LENGTH_MIN = 100;

export const CATALOG_ITEM_TARIFFS_COUNT_MIN = 1;

export const CATALOG_ITEM_TARIFFS_COUNT_MAX = 20;

export const CATALOG_ITEM_PRICE_MIN = 1;

export const CATALOG_ITEM_SCHEDULE_INTERVAL_TIME_STEP_IN_MS = 30 * 60 * 1000;

export const CAREER_IDS_WITH_DEALS = [CareerId.Nanny];

export const CAREER_IDS_WITH_STARTED_AT_PARAMETER = [CareerId.Nanny];

export const CAREER_IDS_WITH_ENDED_AT_PARAMETER = [CareerId.Nanny];

export const CAREER_IDS_WITH_TRANSLATION_TITLE_PARAMETER = [CareerId.Nurse, CareerId.Tutor, CareerId.Housekeeper];

export const CAREER_IDS_WITH_EXPERIENCE_PARAMETER = [CareerId.Nanny, CareerId.Nurse, CareerId.Tutor, CareerId.Housekeeper];

export const CAREER_IDS_WITH_BABY_PARAMETER = [CareerId.Nanny];

export const CAREER_IDS_WITH_CHILD_WITH_DISABILITIES_PARAMETER = [CareerId.Nanny];

export const CAREER_IDS_WITH_REGULAR_SPECIALIST_PARAMETER = [CareerId.Nanny, CareerId.Nurse, CareerId.Tutor, CareerId.Housekeeper];

export const CAREER_IDS_WITH_CHILDREN_PARAMETER = [CareerId.Nanny];

export const CAREER_IDS_WITH_FREE_MEETING_WITH_PARENTS_PARAMETER = [CareerId.Nanny];

export const CAREER_IDS_WITH_ATTITUDE_TOWARDS_PETS_PARAMETER = [CareerId.Nanny];

export const CAREER_IDS_WITH_BEDRIDDEN_PATIENT_PARAMETER = [CareerId.Nurse];

export const CAREER_IDS_WITH_WORKING_AT_HOSPITAL_PARAMETER = [CareerId.Nurse];

export const CAREER_IDS_WITH_PATRONAGE_PARAMETER = [CareerId.Nurse];

export const CAREER_IDS_WITH_WORKING_AT_CONSUMERS_HOME_PARAMETER = [CareerId.Tutor];

export const CAREER_IDS_WITH_PRESCHOOLER_PARAMETER = [CareerId.Tutor];

export const CAREER_IDS_WITH_SCHOOLCHILD_PARAMETER = [CareerId.Tutor];

export const CAREER_IDS_WITH_APARTMENT_PARAMETER = [CareerId.Housekeeper];

export const CAREER_IDS_WITH_COUNTRY_HOUSE_PARAMETER = [CareerId.Housekeeper];

export const CAREER_IDS_WITH_LIVING_AT_CONSUMERS_HOME = [CareerId.Housekeeper];
