import React from 'react';
import { ClipIcon } from '../../../../../../icons/clip';
import { CloseIcon } from '../../../../../../icons/close';
import { FileIcon } from '../../../../../../icons/file';
import { useTheme } from '../../../../../../hooks/useTheme';
import { IconButton } from '../../../../../iconButton';
import { Typography } from '../../../../../typography';
import { LabelContentDefaultProps } from '../model';

export const LabelContentDefault: React.FC<LabelContentDefaultProps> = ({ value, error, disabled, labelText, onReset }) => {
  const theme = useTheme();
  const color = error ? theme.palette['brand pink'] : theme.palette.text[disabled ? 'disabled' : 'primary'];
  const valueLabel = value
    ? Array.from(value)
        .map((f) => f.name)
        .join(', ')
    : undefined;

  return (
    <>
      {value?.length ? <FileIcon color={color} /> : <ClipIcon color={color} />}
      <Typography component="span" sx={{ ml: 2, color }} variant="caption">
        {(valueLabel || labelText) ?? 'Pick file'}
      </Typography>
      {value?.length ? (
        <IconButton color="gray dark" component="span" onClick={onReset} sx={{ ml: 1 }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </>
  );
};
