import { IconButton } from '@cp/ds/src/components/iconButton';
import { ChevronRightIcon } from '@cp/ds/src/icons/chevronRight';
import { SxProps } from '@mui/material';
import React from 'react';

export const HidingButton: React.FC<{ sx: SxProps; handleClick: () => void }> = ({ sx, handleClick }) => {
  return (
    <IconButton onClick={handleClick} sx={sx}>
      <ChevronRightIcon />
    </IconButton>
  );
};
