import React from 'react';
import { alpha } from '@mui/material/styles';
import { useTheme } from '../../hooks/useTheme';
import { mergeSx } from '../../utils/sx/merge';
import { MOBILE_BREAKPOINT_THRESHOLD, SxProps } from '../../theme';
import { Box } from '../box';
import { ButtonsPanelProps } from './model';

export * from './model';

const BACKGROUND_OPACITY = 0.7;

export const ButtonsPanel = React.forwardRef<HTMLDivElement, ButtonsPanelProps>(({ children, position, sx }, ref) => {
  const theme = useTheme();

  const baseSx = React.useMemo(() => {
    const _baseSx: SxProps = {
      justifyContent: { xs: 'center', md: 'flex-start' },
      width: 1,
      backgroundColor: alpha(theme.palette.background.paper, BACKGROUND_OPACITY),
      boxShadow: '0px -3px 8px 0px rgba(0, 0, 0, 0.10)',
      pt: 1.5,
      pb: { xs: 4.5, [MOBILE_BREAKPOINT_THRESHOLD]: 2 },
    };

    if (position === 'bottom-absolute' || position === 'bottom-fixed') {
      _baseSx.left = 0;
      _baseSx.right = 0;
      _baseSx.bottom = 0;
      _baseSx.zIndex = 1;
    }

    if (position === 'bottom-absolute') {
      _baseSx.position = 'absolute';
    }

    if (position === 'bottom-fixed') {
      _baseSx.position = 'fixed';
    }

    return _baseSx;
  }, [position, theme.palette.background.paper]);

  return (
    <Box component="div" ref={ref} sx={mergeSx(baseSx, sx)}>
      {children}
    </Box>
  );
});
