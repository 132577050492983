import { FieldErrors, FieldValues, Resolver as RHFResolver, ResolverOptions, ResolverResult } from 'react-hook-form';
import { ValidationError, ValidatorOptions, validate, validateSync } from 'class-validator';
import { ClassConstructor, ClassTransformOptions, plainToInstance } from 'class-transformer';
import { toNestError, validateFieldsNatively } from '@hookform/resolvers';
import { validationMessagesDictionaryFromMessage } from '@common/utils/validation/i18n/createMessagesDictionary';
import { FormContext } from './formContext';

export type Resolver = <
  T extends {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [_: string]: any;
  },
>(
  schema: ClassConstructor<T>,
  schemaOptions?: {
    validator?: ValidatorOptions;
    transformer?: ClassTransformOptions;
  },
  resolverOptions?: {
    mode?: 'async' | 'sync';
    rawValues?: boolean;
  },
) => <TFieldValues extends FieldValues, TContext extends FormContext = FormContext>(
  values: TFieldValues,
  context: TContext | undefined,
  options: ResolverOptions<TFieldValues>,
) => Promise<ResolverResult<TFieldValues>>;

const parseErrors = (
  errors: ValidationError[],
  validateAllFieldCriteria: boolean,
  context: FormContext | undefined,
  parsedErrors: FieldErrors = {},
  path = '',
) => {
  return errors.reduce((acc, error) => {
    const _path = path ? `${path}.${error.property}` : error.property;

    if (error.constraints) {
      const key = Object.keys(error.constraints)[0];
      const rawMessage = error.constraints[key];
      let message = rawMessage;

      if (context?.languageCode) {
        const messageDict = validationMessagesDictionaryFromMessage(rawMessage);

        if (messageDict) {
          const messageDictFirstKey = Object.keys(messageDict)[0];

          message = messageDict[context.languageCode] ?? messageDict[messageDictFirstKey] ?? message;
        }
      }

      acc[_path] = {
        type: key,
        message,
      } as (typeof acc)[keyof typeof acc];

      const _e = acc[_path];
      if (validateAllFieldCriteria && _e) {
        Object.assign(_e, { types: error.constraints });
      }
    }

    if (error.children && error.children.length) {
      parseErrors(error.children, validateAllFieldCriteria, context, acc, _path);
    }

    return acc;
  }, parsedErrors);
};

export const createFromValidationResolver: Resolver =
  (schema, schemaOptions = {}, resolverOptions = {}) =>
  async (values, context, options) => {
    const { transformer, validator } = schemaOptions;
    const data = plainToInstance(schema, values, transformer);

    const rawErrors = await (resolverOptions.mode === 'sync' ? validateSync : validate)(data, validator);

    if (rawErrors.length) {
      return {
        values: {},
        errors: toNestError(parseErrors(rawErrors, !options.shouldUseNativeValidation && options.criteriaMode === 'all', context), options),
      };
    }

    // eslint-disable-next-line no-unused-expressions
    options.shouldUseNativeValidation && validateFieldsNatively({}, options);

    return { values, errors: {} };
  };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const composeResolvers = (...resolvers: ReturnType<Resolver>[]): RHFResolver<any> => {
  return (values, context, options) => {
    return Promise.all(resolvers.map((resolver) => resolver(values, context, options))).then((results) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { values, errors } = results.pop() as ResolverResult;
      return { values, errors: results.reduce((acc, v) => Object.assign(acc, v.errors), errors) };
    });
  };
};
