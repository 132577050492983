import React from 'react';
import { mergeSx } from '../../utils/sx/merge';
import { ButtonGroupItem } from './components/item';
import { ButtonGroupContainer } from './components/container';
import { ButtonGroupItemConfig, ButtonGroupProps, ButtonGroupStateProps, ButtonGroupValue } from './model';
import { BUTTON_GROUP_SIZE_DEFAULT } from './const';

export * from './model';

export const ButtonGroup = React.forwardRef(
  <V extends ButtonGroupValue>(
    { value, name, onChange, disabled, items, sx, itemSx, passiveItemSx, size = BUTTON_GROUP_SIZE_DEFAULT }: ButtonGroupProps<V>,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const ownerState: ButtonGroupStateProps<V> = { items, size };

    return (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      <ButtonGroupContainer ownerState={ownerState} ref={ref as any} sx={sx}>
        {items?.map((item: ButtonGroupItemConfig<V>, index) => {
          const isActive = value === item.value;
          return (
            <ButtonGroupItem
              checked={isActive}
              disabled={disabled ?? item.disabled ?? false}
              index={index}
              key={item.value}
              label={item.label}
              name={name}
              onChange={() => onChange?.(item.value)}
              size={size}
              sx={mergeSx(itemSx, isActive ? {} : passiveItemSx)}
              totalCount={items.length}
              value={item.value}
            />
          );
        })}
      </ButtonGroupContainer>
    );
  },
) as <V extends ButtonGroupValue>(props: ButtonGroupProps<V> & { ref?: React.Ref<HTMLDivElement> }) => ReturnType<React.FC>;
