import { coreApi } from '@cp/shared/api/core';
import { ClientContactUpdateRequest, ClientContactViewModel, ClientContactsDictionary } from '@common/model/client';
import { ApiServerEntityIDType } from '@cp/utils/apiServer/types';
import { CoreClientContactApiTags, coreClientContactApiTagTypes } from './tags';
import { clientContactsListDataAdaptor } from './adaptor';

export const coreClientContactApi = coreApi
  .enhanceEndpoints({
    addTagTypes: coreClientContactApiTagTypes,
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getContacts: builder.query<ClientContactsDictionary, { clientId: ApiServerEntityIDType }>({
        query: ({ clientId }) => ({
          url: `admin/clients/${clientId}/contacts`,
        }),
        transformResponse: (response: ClientContactViewModel[], _meta, _arg) => {
          return clientContactsListDataAdaptor(response);
        },
        providesTags: [CoreClientContactApiTags.ClientContactList],
      }),

      updateContact: builder.mutation<void, ClientContactUpdateRequest & { clientId: ApiServerEntityIDType }>({
        query: ({ clientId, ...body }) => ({
          method: 'PUT',
          url: `admin/clients/${clientId}/contacts`,
          body,
        }),
        invalidatesTags: [CoreClientContactApiTags.ClientContactList],
      }),
    }),

    overrideExisting: false,
  });
