import { sleep } from '@cp/utils/sleep';
import { authenticationSlice } from '@cp/shared/api/core';
import { AdminAuthRequest, AdminAuthResponse } from '@common/model/admin/auth';
import { coreApi } from '../..';

export const coreAdminApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    auth: builder.mutation<AdminAuthResponse, AdminAuthRequest>({
      query: (request) => ({
        method: 'POST',
        url: 'admin/auth',
        body: request,
      }),
    }),

    logout: builder.mutation<boolean, void>({
      query: () => ({
        method: 'DELETE',
        url: 'admin/auth',
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          await sleep(0);
          // ToDo: clear only admin dependent caches
          dispatch(coreAdminApi.util.resetApiState()); //  .invalidateTags(coreServiceClientDependentTags)
          dispatch(authenticationSlice.actions.setToken(null));
        } catch {}
      },
    }),

    deleteCache: builder.mutation<boolean, void>({
      query: () => ({
        method: 'DELETE',
        url: 'admin/cache',
      }),
    }),
  }),

  overrideExisting: false,
});
