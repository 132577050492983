import React from 'react';
import { Container } from '@cp/ds/src/components/container';
import { Specialists } from './specialists';
import { Employers } from './employers';
import { Vacancies } from './vacancies';
import { ActiveSubscriptions } from './activeSubscriptions';

export const CommonStats = () => {
  return (
    <Container sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', flexWrap: 'nowrap', overflowY: 'auto', gap: 2 }}>
      <Specialists />
      <Employers />
      <Vacancies />
      <ActiveSubscriptions />
    </Container>
  );
};
