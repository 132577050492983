import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@cp/ds/src/components/button';
import { ButtonProps } from '@cp/ds/src/components/button/model';
import { useClientDelete } from '../../model';

export interface ClientDeleteButtonProps extends ButtonProps {
  clientId?: number;
}

export const ClientDeleteButton: React.FC<ClientDeleteButtonProps> = ({ clientId, ...props }) => {
  const [disabled, setDisabled] = useState(!clientId);
  const doDelete = useClientDelete();
  const onClick = useCallback(async () => {
    if (!clientId) {
      return;
    }
    setDisabled(true);
    await doDelete(clientId);
    setDisabled(!clientId);
  }, [clientId, doDelete]);

  useEffect(() => {
    setDisabled(!clientId);
  }, [clientId]);

  return (
    <Button disabled={disabled} onClick={onClick} title="Удалить клиента" {...props}>
      Удалить
    </Button>
  );
};
