import React from 'react';
import { ChevronDownIcon } from '../../icons/chevronDown';
import { Box } from '../box';
import { SelectButtonProps, SelectButtonStateProps } from './model';
import { SelectIcon } from './components/icon';
import { SelectButtonRoot } from './components/root';
import { SelectButtonInput } from './components/input';
export type { SelectButtonProps, SelectButtonStateProps } from './model';

export const SelectButton = React.forwardRef<HTMLDivElement, React.PropsWithChildren<SelectButtonProps>>((props, ref) => {
  const {
    inputName,
    id,
    disabled = false,
    error = false,
    size = 'normal',
    inputValue,
    active = false,
    sx,
    ariaLabelledBy,
    ariaExpanded,
    ariaHasPopup,
    onClick,
    children,
  } = props;

  const ownerState: SelectButtonStateProps = {
    disabled,
    error,
    value: inputValue,
    active,
    size,
  };

  return (
    <SelectButtonRoot
      aria-expanded={ariaExpanded}
      aria-haspopup={ariaHasPopup}
      aria-labelledby={ariaLabelledBy}
      id={id}
      onClick={onClick}
      ownerState={ownerState}
      ref={ref as unknown as undefined}
      role="button"
      sx={sx}
      tabIndex={0}
    >
      <Box
        component="span"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 1,
          lineClamp: '1',
          wordBreak: 'break-all',
        }}
      >
        {children}
      </Box>
      <SelectIcon ownerState={ownerState}>
        <ChevronDownIcon fontSize="small" />
      </SelectIcon>
      {Boolean(inputName) && (
        <SelectButtonInput
          aria-hidden="true"
          name={inputName}
          ownerState={ownerState}
          tabIndex={-1}
          type="hidden"
          value={inputValue ?? ''}
        />
      )}
    </SelectButtonRoot>
  );
});
