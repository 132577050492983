import { Threshold } from './thresholds';

export const calcActualThresholdIdx = (thresholds: Threshold[], createdAt: Date, now = new Date()): number => {
  const timeSpend = now.getTime() - createdAt.getTime();
  let idx = 0;
  for (let i = 0; i < thresholds.length; i++) {
    if (timeSpend >= thresholds[i].minDuration) {
      idx = i;
    }
  }
  return idx;
};

/**
 * Calculate left time to move card to next column
 */
export const calcTimeout = (thresholds: Threshold[], createdAt: Date, now = new Date()): number | undefined => {
  const i = calcActualThresholdIdx(thresholds, createdAt, now);
  const nextThreshold: Threshold | undefined = thresholds[i + 1];
  if (!nextThreshold) {
    return;
  }
  return nextThreshold.minDuration - (now.getTime() - createdAt.getTime());
};
