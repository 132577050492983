import styled from '@mui/material/styles/styled';
import { TextAreaStateProps, TextAreaTagProps } from '../model';

type UnstyledTextAreaComponent = React.ComponentClass<TextAreaTagProps & { ownerState: Required<TextAreaStateProps> }>;

export const StyledTextArea = styled('textarea' as unknown as UnstyledTextAreaComponent, {
  name: 'TextArea',
  slot: 'Input',
})(({ theme, ownerState }) => {
  const { disabled, size } = ownerState;
  const py = { 'small': 8, 'normal': 12 }[size];
  const px = { 'small': 16, 'normal': 24 }[size];

  return {
    ...theme.typography['body'],
    resize: 'none',
    color: theme.palette.text[disabled ? 'disabled' : 'primary'],
    backgroundColor: 'inherit',
    width: '100%',
    margin: 0,
    paddingTop: py,
    paddingBottom: py,
    paddingRight: px,
    paddingLeft: px,
    border: 'none',
    borderRadius: 0,
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: theme.palette.secondary,
    },
  };
});
