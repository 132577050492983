// eslint-disable-next-line import/no-duplicates
import { ru } from 'date-fns/locale';
// eslint-disable-next-line import/no-duplicates
import { format, nextDay } from 'date-fns';
import { DateFormat } from '@common/const/date';
import { DayOfWeek } from '@common/model/dayOfWeek';

export const formatScheduleDayOfWeek = (dayOfWeek: DayOfWeek, dateFormat: DateFormat = DateFormat.DayOfThisWeekShort): string => {
  return format(nextDay(new Date(), dayOfWeek), dateFormat, { locale: ru });
};

export * from './getFullName';
