import React from 'react';
import { SxProps } from '@cp/ds';
import { FormField } from '@cp/ds/src/components/form';
import { Box } from '@cp/ds/src/components/box';
import { Tag } from '@cp/ds/src/components/tag';
import { Stack } from '@cp/ds/src/components/stack';
import { useFormContext } from 'react-hook-form';
// TODO: move from entities to a shared layer
import { MessageInput } from '@cp/entities/dialog';
import { FieldLabel } from '@cp/ds/src/components/form/components/field/base/components/label';
import { MODERATOR_MESSAGE_FIELD_NAME_DEFAULT } from './const';

export interface ModeratorMessageFieldProps {
  sx?: SxProps;
  name?: string;
  suggestions?: string[];
}

export const ModeratorMessageField: React.FC<ModeratorMessageFieldProps> = ({
  sx,
  name = MODERATOR_MESSAGE_FIELD_NAME_DEFAULT,
  suggestions,
}) => {
  const form = useFormContext();
  const { setValue } = useFormContext();
  return (
    <Box sx={sx}>
      <FieldLabel>
        {' '}
        Сообщение от модератора{' '}
        <Box component="span" sx={{ color: 'brand pink' }}>
          (обязательно)
        </Box>
      </FieldLabel>

      <FormField fieldContainerProps={{ sx: { mt: 1.5, mb: 0.5 } }} label="sms" name="sendCopyBySms" type="checkbox" />
      <MessageInput
        form={form}
        messageFieldProps={{
          autoFocus: true,
          placeholder: 'Введите текст',
          required: true,
          sx: { width: '100%' },
          fieldContainerProps: {},
          maxRows: 5,
          minRows: 5,
        }}
      />

      {suggestions?.length ? (
        <Stack alignItems="flex-start" gap={0.75} my={0.75}>
          {suggestions.map((suggest) => (
            <Tag
              key={suggest}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setValue(name, suggest);
              }}
            >
              {suggest}
            </Tag>
          ))}
        </Stack>
      ) : null}
    </Box>
  );
};
