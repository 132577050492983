import React from 'react';
import { Typography } from '../../../../../../typography';
import { useTheme } from '../../../../../../../hooks/useTheme';
import { mergeSx } from '../../../../../../../utils/sx/merge';
import { FieldLabelProps } from './model';

export const FieldLabel: React.FC<React.PropsWithChildren<FieldLabelProps>> = ({ children, sx }) => {
  const theme = useTheme();

  return (
    <Typography component="label" sx={mergeSx({ mb: 1.5, color: theme.palette['brand gray'] }, sx)} variant="body bold">
      {children}
    </Typography>
  );
};
