import React from 'react';
import MUIMenuList from '@mui/material/MenuList';
import type { MenuListProps as MuiMenuListProps } from '@mui/material';
import { MenuSize } from './model';
import { MenuContext } from './context';

export interface MenuListProps extends MuiMenuListProps {
  size?: MenuSize;
}
export const MenuList = React.forwardRef<HTMLUListElement, MenuListProps>(({ size = 'normal', children, ...props }, ref) => (
  <MenuContext.Provider value={{ size }}>
    <MUIMenuList ref={ref} {...props}>
      {children}
    </MUIMenuList>
  </MenuContext.Provider>
));
