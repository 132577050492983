import { InputMaskTokenDictionary } from './model';

export const defaultTokens: InputMaskTokenDictionary = {
  '#': {
    pattern: /\d/,
  },
  X: {
    pattern: /[0-9a-zA-Z]/,
  },
  S: {
    pattern: /[a-zA-Z]/,
  },
  A: {
    pattern: /[a-zA-Z]/,
    transform: (t) => t.toLocaleUpperCase(),
  },
  a: {
    pattern: /[a-zA-Z]/,
    transform: (t) => t.toLocaleLowerCase(),
  },
  '!': {
    escape: true,
  },
};
