import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const ShareIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="share" ref={ref as any} {...props}>
      <path
        clipRule="evenodd"
        d="M5.59998 11.5C5.87612 11.5 6.09998 11.7239 6.09998 12V18.4C6.09998 18.6917 6.21587 18.9715 6.42216 19.1778C6.62845 19.3841 6.90824 19.5 7.19998 19.5H16.8C17.0917 19.5 17.3715 19.3841 17.5778 19.1778C17.7841 18.9715 17.9 18.6917 17.9 18.4V12C17.9 11.7239 18.1238 11.5 18.4 11.5C18.6761 11.5 18.9 11.7239 18.9 12V18.4C18.9 18.957 18.6787 19.4911 18.2849 19.8849C17.8911 20.2788 17.3569 20.5 16.8 20.5H7.19998C6.64302 20.5 6.10888 20.2788 5.71505 19.8849C5.32122 19.4911 5.09998 18.957 5.09998 18.4V12C5.09998 11.7239 5.32383 11.5 5.59998 11.5Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M11.6464 3.64645C11.8416 3.45118 12.1582 3.45118 12.3535 3.64645L15.5535 6.84645C15.7487 7.04171 15.7487 7.35829 15.5535 7.55355C15.3582 7.74882 15.0416 7.74882 14.8464 7.55355L11.9999 4.70711L9.15348 7.55355C8.95822 7.74882 8.64164 7.74882 8.44637 7.55355C8.25111 7.35829 8.25111 7.04171 8.44637 6.84645L11.6464 3.64645Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12 3.5C12.2761 3.5 12.5 3.72386 12.5 4V14.4C12.5 14.6761 12.2761 14.9 12 14.9C11.7239 14.9 11.5 14.6761 11.5 14.4V4C11.5 3.72386 11.7239 3.5 12 3.5Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
});
