import React from 'react';
import { Select } from '../../../select';
import { Checkbox } from '../../../checkbox';
import { ButtonGroup } from '../../../buttonGroup';
import { Switch } from '../../../switch';
import { AutocompleteInput } from './autocomplete';
import { CheckboxGroupInput } from './checkboxGroup';
import { EmailInput } from './email';
import { FormFieldInputProps, FormFieldProps } from './model';
import { FileInput } from './file';
import { PasswordInput } from './password';
import { PhoneInput } from './phone';
import { TextInput } from './text';
import { TextArea } from './textArea';
import { SmsCodeInput } from './smsCode';
import { CardNumberInput } from './cardNumber';
import { CvcInput } from './cvc';
import { CardExpirationInput } from './cardExpiration';
import { DateInput } from './date';
import { RangeInput } from './range';
import { TextAreaAutoSize } from './textAreaAutoSize';
import { DateTimePicker } from './dateTimePicker';

export interface FormFieldDictionaryItem<TName, Type extends FormFieldProps<TName>['type']> {
  component: React.FC<React.PropsWithRef<{ type: Type } & FormFieldInputProps>>;
}

type FormFieldDictionary<TName> = {
  [Type in FormFieldProps<TName>['type']]: FormFieldDictionaryItem<TName, Type>;
};

export const formFieldDictionary: FormFieldDictionary<string> = {
  text: {
    component: TextInput,
  },
  password: {
    component: PasswordInput,
  },
  phone: {
    component: PhoneInput,
  },
  select: {
    component: Select,
  },
  email: {
    component: EmailInput,
  },
  textArea: {
    component: TextArea,
  },
  textAreaAutoSize: {
    component: TextAreaAutoSize,
  },
  file: {
    component: FileInput,
  },
  checkbox: {
    component: Checkbox,
  },
  checkboxGroup: {
    component: CheckboxGroupInput,
  },
  autocomplete: {
    component: AutocompleteInput,
  },
  sms: {
    component: SmsCodeInput,
  },
  cardNumber: {
    component: CardNumberInput,
  },
  cardExpiration: {
    component: CardExpirationInput,
  },
  cvc: {
    component: CvcInput,
  },
  buttonGroup: {
    component: ButtonGroup,
  },
  date: {
    component: DateInput,
  },
  dateTimePicker: {
    component: DateTimePicker,
  },
  range: {
    component: RangeInput,
  },
  switch: {
    component: Switch,
  },
};
