import { styled } from '@cp/ds/src/theme/styled';
import { Box } from '@cp/ds/src/components/box';
import { OwnerState } from './ownerState';

export const AdvantageRoot = styled(Box, { name: 'Advantage', slot: 'root' })<{ ownerState: OwnerState }>(({ theme, ownerState }) => ({
  borderRadius: theme.borderRadius.small,
  padding: `5px ${theme.spacing(1)}`,
  display: 'inline-flex',
  gap: theme.spacing(1),
  justifyContent: 'flex-start',
  alignItems: 'center',
  userSelect: 'none',
  boxSizing: 'content-box',
  lineHeight: theme.typography.h2.lineHeight,
  minHeight: `calc(${theme.typography.h2.fontSize} * ${theme.typography.h2.lineHeight})`,
  backgroundColor: theme.palette.background.default,
  ...(ownerState.background === 'gray' && {
    backgroundColor: theme.palette['gray ultra light'],
  }),
  ...(ownerState.background === 'white' && {
    backgroundColor: theme.palette.white,
  }),
  ...(ownerState.bordered && {
    border: `1px solid ${theme.palette['gray light']}`,
  }),
}));
