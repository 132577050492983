import { useFormContext } from 'react-hook-form';
import { getPaymentSystemFromCardNumber } from '@cp/shared/lib/payment';
import { PaymentSystem } from '@youhelp/common/const/payment';
import { CARD_NUMBER_LENGTH } from '../const';
import { CardNumberInputProps } from '../model';

export const useCardNumberState = ({ name }: CardNumberInputProps): { paymentSystem: PaymentSystem; isValid: boolean } => {
  const { getValues, watch } = useFormContext<{ [key in typeof name]: string }>();

  watch(name, '');
  const cardNumber = getValues(name) ?? '';

  const paymentSystem = getPaymentSystemFromCardNumber(cardNumber);

  return {
    paymentSystem,
    isValid: cardNumber.length === CARD_NUMBER_LENGTH,
  };
};
