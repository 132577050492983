import { createContext, useContext } from 'react';
import { DialogViewerProps } from './viewer.interface';
import { RedirectViewer } from './redirectViewer';

export interface IDialogViewContext {
  Viewer: (props: DialogViewerProps) => JSX.Element | null;
}

const context = createContext<IDialogViewContext>({
  Viewer: RedirectViewer,
});

export const DialogViewContextProvider = context.Provider;

export const useDialogViewContext = () => {
  return useContext(context);
};
