import React from 'react';
import { ResponseCard } from '@cp/entities/response';
import { ResponseView } from '@common/model/catalog/item/response';
import { OnlineStatus, OnlineStatusMarker } from '@cp/ds/src/components/onlineStatus';
import { Avatar } from '@cp/ds/src/components/avatar';
import { Box } from '@cp/ds/src/components/box';
import { coreDialogApi, formatMessageDate, getMessageDescription } from '@cp/entities/dialog';
import { MessageContent } from '@cp/ds/src/components/messageContent';
import { useScrollContainer } from 'src/shared/ui/scrollContainer';

export interface ResponseCardFeatureProps {
  model: ResponseView;
  onActivate?: (model: ResponseView) => void;
}

export const ResponseCardFeature: React.FC<ResponseCardFeatureProps> = ({ model, onActivate }) => {
  const { data: dialog } = coreDialogApi.endpoints.getDialog.useQuery({ id: model.dialogId });
  const { ref } = useScrollContainer();
  return (
    <ResponseCard
      avatar={
        <Box>
          <Avatar
            content={{ image: model.respondent.mainPhoto?.image }}
            lazyLoadProps={{ scrollContainer: ref.current || undefined, overflow: true }}
            rounded
            size={48}
          />
          <OnlineStatusMarker
            status={model.respondent.isOnline ? OnlineStatus.Online : OnlineStatus.Offline}
            sx={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}
          />
        </Box>
      }
      component="li"
      description={dialog?.lastMessage && <MessageContent content={getMessageDescription(dialog.lastMessage)} textOnly />}
      onClick={() => onActivate?.(model)}
      respondedAt={formatMessageDate(model.createdAt)}
      title={model.respondent.name}
    />
  );
};
