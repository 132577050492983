import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const FileIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="file" ref={ref as any} {...props}>
      <path
        clipRule="evenodd"
        d="M6.52682 5.19345C6.91752 4.80275 7.44743 4.58325 7.99996 4.58325H12.6666C12.8655 4.58325 13.0563 4.66227 13.197 4.80292L17.8636 9.46959C18.0043 9.61024 18.0833 9.80101 18.0833 9.99992V17.3333C18.0833 17.8858 17.8638 18.4157 17.4731 18.8064C17.0824 19.1971 16.5525 19.4166 16 19.4166H7.99996C6.85241 19.4166 5.91663 18.4808 5.91663 17.3333V6.66659C5.91663 6.11405 6.13612 5.58415 6.52682 5.19345ZM7.99996 6.08325C7.84525 6.08325 7.69688 6.14471 7.58748 6.25411C7.47808 6.3635 7.41663 6.51188 7.41663 6.66659V17.3333C7.41663 17.6524 7.68084 17.9166 7.99996 17.9166H16C16.1547 17.9166 16.303 17.8551 16.4124 17.7457C16.5218 17.6363 16.5833 17.488 16.5833 17.3333V10.3106L12.356 6.08325H7.99996Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12.6666 5.25C13.0808 5.25 13.4166 5.58579 13.4166 6V9.25H16.6666C17.0808 9.25 17.4166 9.58579 17.4166 10C17.4166 10.4142 17.0808 10.75 16.6666 10.75H12.6666C12.2524 10.75 11.9166 10.4142 11.9166 10V6C11.9166 5.58579 12.2524 5.25 12.6666 5.25Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
});
