import React from 'react';
import { Typography } from '@cp/ds/src/components/typography';
import { ClientContactsPreview } from '@cp/entities/clientContact';
import { EditableSection } from '@cp/ds/src/components/editableSection';
import { ApiServerEntityIDType } from '@cp/utils/apiServer/types';
import { ClientContactType } from '@common/model/client/clientContactType';
import { useModal } from 'src/app/shared/hooks/useModal';
import { coreClientContactApi } from 'src/entities/clientContact';
import { CLIENT_CONTACTS_EDIT_MODAL_NAME } from 'src/app/shared/components/modals/clientContactsEdit/const';
import { ClientContactsEditableSectionProps } from './model';

const CONTACTS_TO_HIDE = [ClientContactType.Phone, ClientContactType.Email];

export const ClientContactsEditableSection: React.FC<ClientContactsEditableSectionProps> = ({ clientId }) => {
  const { data, isLoading } = coreClientContactApi.endpoints.getContacts.useQuery(
    { clientId: clientId as ApiServerEntityIDType },
    { skip: !clientId },
  );
  const { openModal } = useModal();

  const contacts = Object.entries(data ?? {});
  const isEmpty = !isLoading && data && !contacts.length;

  return (
    <EditableSection
      emptyMessage={isEmpty ? 'Соцсети не указаны' : undefined}
      isLoading={isLoading}
      onEdit={() => {
        if (!clientId) {
          throw new Error('clientId is required');
        }
        openModal(CLIENT_CONTACTS_EDIT_MODAL_NAME, { clientId });
      }}
      title={
        <>
          Доп. контакты <Typography variant="body">(не обязательно)</Typography>
        </>
      }
    >
      {!isEmpty && data && <ClientContactsPreview contactsToHide={CONTACTS_TO_HIDE} data={data} />}
    </EditableSection>
  );
};
