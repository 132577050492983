import { ConfigError, ConfigType, IConfigManager } from '@cp/shared/config';

export class ConfigManager<C extends ConfigType = ConfigType> implements IConfigManager<C> {
  readonly #values: C;
  constructor(values: C) {
    this.#values = values;
  }

  get<T extends keyof C | string>(key: T): T extends keyof C ? C[T] | null : null;
  get<T extends keyof C | string, V = T extends keyof C ? NonNullable<C[T]> : never>(
    key: T,
    def: V,
  ): T extends keyof C ? NonNullable<C[T]> : V;
  get<T extends keyof C | string, V = T extends keyof C ? NonNullable<C[T]> : never>(key: T, def?: V) {
    return this.#values.hasOwnProperty(key) ? this.#values[key] : def || null;
  }

  getOrThrow<T extends keyof C | string, R extends T extends keyof C ? C[T] : never>(key: T): R {
    if (this.#values.hasOwnProperty(key)) {
      return this.#values[key] as R;
    }
    throw ConfigError.NotFound(key);
  }
}
