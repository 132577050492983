import React from 'react';
import { Button as ButtonBase } from '@cp/ds/src/components/button';
import { useDialogViewContext, useOpenDialog } from '../../model';
import { ButtonProps } from './model';

export const Button: React.FC<ButtonProps> = ({ dialogFindQuery, ...restProps }) => {
  const { Viewer } = useDialogViewContext();
  const { isLoading, openDialog, dialogId, onClose } = useOpenDialog();

  return (
    <>
      <ButtonBase
        disabled={!dialogFindQuery || isLoading || Boolean(dialogId)}
        onClick={() => dialogFindQuery && openDialog(dialogFindQuery)}
        {...restProps}
      >
        Написать
      </ButtonBase>
      {dialogId ? <Viewer dialogId={dialogId} onClose={onClose} /> : null}
    </>
  );
};
