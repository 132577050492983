import * as React from 'react';
import { useEnhancedEffect } from './useEnhancedEffect';

/**
 * https://github.com/facebook/react/issues/14099#issuecomment-440013892
 */
export const useEventCallback = <Args extends unknown[], Return>(
  fn?: (...args: Args) => Return,
): ((...args: Args) => Return) | undefined => {
  const ref = React.useRef(fn);

  useEnhancedEffect(() => {
    ref.current = fn;
  });

  const memoizedFn = React.useCallback(
    (...args: Args) =>
      // @ts-expect-error hide `this`
      // tslint:disable-next-line:ban-comma-operator
      (0, ref.current)(...args),
    [],
  );

  return fn ? memoizedFn : undefined;
};
