import { CSSProperties } from 'react';
import { Path } from 'react-hook-form';
import { VkIconRound } from '@cp/ds/src/icons/social/round/vkRound';
import { InstagramIconRound } from '@cp/ds/src/icons/social/round/instagramRound';
import { WhatsAppIconRound } from '@cp/ds/src/icons/social/round/whatsAppRound';
import { MailIconRound } from '@cp/ds/src/icons/social/round/mailRound';
import { PhoneIconRound } from '@cp/ds/src/icons/social/round/phoneRound';
import { TelegramIconRound } from '@cp/ds/src/icons/social/round/telegramRound';
import { ViberIconRound } from '@cp/ds/src/icons/social/round/viberRound';
import { ClientContactType } from '@common/model/client/clientContactType';
import { SvgIconComponent } from '@cp/ds/src/components/svgIcon';
import { FormFieldProps } from '@cp/ds/src/components/form/components/field/model';
import { ClientContactViewModel } from '@common/model/client';
import { ClientContactItemFormModel } from './components/form/model';

type FormFieldPropsForContactItemForm = FormFieldProps<Path<ClientContactItemFormModel>>;
export interface ClientContactItemDictItem {
  icon: SvgIconComponent;
  iconStyleProps?: CSSProperties;
  placeholder: string;
  label: string | ((contact: ClientContactViewModel) => string);
  linkResolver: (contact: ClientContactViewModel) => string;
  formFieldsConfig: {
    value: {
      fieldProps: Extract<
        FormFieldPropsForContactItemForm,
        {
          type: 'text' | 'phone';
        }
      >;
    };
  } & {
    [FieldName in keyof Omit<ClientContactItemFormModel, 'value'>]?: {
      fieldProps: FormFieldPropsForContactItemForm;
    };
  };
}

export type ClientContactItemDict = Partial<Record<ClientContactType, ClientContactItemDictItem>>;

// TODO: move dict to app level
export const clientContactItemDict: ClientContactItemDict = {
  [ClientContactType.VK]: {
    icon: VkIconRound,
    iconStyleProps: { color: 'social.vk' },
    placeholder: 'vk.com/',
    label: 'Вконтакте',
    linkResolver: (contact) => `https://vk.com/${contact.value}`,
    formFieldsConfig: {
      value: {
        fieldProps: { name: 'value', type: 'text' },
      },
    },
  },
  [ClientContactType.Instagram]: {
    icon: InstagramIconRound,
    placeholder: 'instagram.com/',
    label: 'Instagram',
    linkResolver: (contact) => `https://www.instagram.com/${contact.value}`,
    formFieldsConfig: {
      value: {
        fieldProps: { name: 'value', type: 'text' },
      },
    },
  },
  [ClientContactType.WhatsApp]: {
    icon: WhatsAppIconRound,
    iconStyleProps: { color: 'social.whatsapp' },
    placeholder: '+7 (999) 777-12-34',
    label: 'WhatsApp',
    linkResolver: (contact) => `https://api.whatsapp.com/send?phone=${contact.value.replace(/\D/g, '')}`,
    formFieldsConfig: {
      value: {
        fieldProps: { name: 'value', type: 'phone' },
      },
    },
  },
  [ClientContactType.Telegram]: {
    icon: TelegramIconRound,
    placeholder: '@login',
    label: 'Telegram',
    linkResolver: (contact) => `https://telegram.me/${contact.value}`,
    formFieldsConfig: {
      value: {
        fieldProps: { name: 'value', type: 'text' },
      },
    },
  },
  [ClientContactType.Viber]: {
    icon: ViberIconRound,
    placeholder: '+7 (999) 777-12-34',
    label: 'Viber',
    linkResolver: (contact) => `viber://chat?number=%2B${contact.value.replace(/\D/g, '')}`,
    formFieldsConfig: {
      value: {
        fieldProps: { name: 'value', type: 'phone' },
      },
    },
  },
  [ClientContactType.Email]: {
    icon: MailIconRound,
    label: 'Email',
    placeholder: 'email@example.com',
    linkResolver: (contact) => `mailto:${contact.value}`,
    formFieldsConfig: {
      value: {
        fieldProps: { name: 'value', type: 'text' },
      },
    },
  },
  // [ClientContactType.InnerChat]: {
  //   icon: InternalChatIconRound,
  //   label: 'Онлайн чат',
  //   linkResolver: (contact, { generateAppPath, catalogItem }) =>
  //     generateAppPath({
  //       page: ClientWebPage.ProfileMessages,
  //       queryParams: { catalogItemId: catalogItem.id },
  //     }),
  // },
  [ClientContactType.Phone]: {
    icon: PhoneIconRound,
    placeholder: '+7 (999) 777-12-34',
    label: (contact) => `Позвонить ${contact.value}`,
    linkResolver: (contact) => `tel:${contact.value}`,
    formFieldsConfig: {
      value: {
        fieldProps: { name: 'value', type: 'phone' },
      },
    },
  },
};
