import { CAREER_IDS_WITH_TRAINING } from '../../../const/client/training';
import { ClientPatronageStage } from './stage';
import { IClientPatronageStageConfig } from './stage.config.interface';

export const CLIENT_PATRONAGE_STAGE_CONFIG: Record<ClientPatronageStage, IClientPatronageStageConfig> = {
  [ClientPatronageStage.Unknown]: {
    canBeChangedToByAdmin: [],
    label: 'Неизвестно',
  },
  [ClientPatronageStage.Initial]: {
    canBeChangedToByAdmin: [ClientPatronageStage.Interview, ClientPatronageStage.Rejected],
    label: 'Зарегистрировался',
    historyLabel: 'Зарегистрирован',
  },
  [ClientPatronageStage.Interview]: {
    canBeChangedToByAdmin: ({ careerId }) => [
      ClientPatronageStage.Initial,
      ...(CAREER_IDS_WITH_TRAINING.includes(careerId) ? [ClientPatronageStage.Training] : []),
      ClientPatronageStage.Photo,
      ClientPatronageStage.Rejected,
    ],
    label: 'Пройти собеседование (ручная)',
    historyLabel: 'Интервью',
  },
  [ClientPatronageStage.Training]: {
    canBeChangedToByAdmin: [ClientPatronageStage.Photo, ClientPatronageStage.Rejected],
    canBeChangedToByProgram: [ClientPatronageStage.Photo],
    label: 'Пройти обучение',
    historyLabel: 'Проверен',
  },
  [ClientPatronageStage.Photo]: {
    canBeChangedToByAdmin: [ClientPatronageStage.Rejected],
    canBeChangedToByProgram: [ClientPatronageStage.ResumeFilling],
    label: 'Загрузить фото',
    historyLabel: ({ client }) =>
      client.mainCareerId && CAREER_IDS_WITH_TRAINING.includes(client.mainCareerId) ? 'Школа YouHelp' : 'Обучение не нужно',
    messageRequiringStrategy: 'onMoveBack',
  },
  [ClientPatronageStage.ResumeFilling]: {
    canBeChangedToByAdmin: [ClientPatronageStage.Completed, ClientPatronageStage.Photo, ClientPatronageStage.Rejected],
    canBeChangedToByProgram: [ClientPatronageStage.ResumeModeration, ClientPatronageStage.Rejected],
    hideAvailableStagesInUI: [ClientPatronageStage.Completed],
    label: 'Заполнить анкету',
    historyLabel: 'Фото загружено',
    messageRequiringStrategy: 'onMoveBack',
  },
  [ClientPatronageStage.ResumeModeration]: {
    canBeChangedToByAdmin: [
      ClientPatronageStage.Photo,
      ClientPatronageStage.ResumeFilling,
      ClientPatronageStage.Completed,
      ClientPatronageStage.Rejected,
    ],
    hideAvailableStagesInUI: [ClientPatronageStage.Completed],
    label: 'Проверить анкету (ручная)',
    historyLabel: 'Анкета заполнена',
  },
  [ClientPatronageStage.Completed]: {
    canBeChangedToByAdmin: [
      ClientPatronageStage.Initial,
      ClientPatronageStage.Interview,
      ClientPatronageStage.Training,
      ClientPatronageStage.Photo,
      ClientPatronageStage.ResumeFilling,
      ClientPatronageStage.ResumeModeration,
      ClientPatronageStage.Rejected,
    ],
    canBeChangedToByProgram: [ClientPatronageStage.ResumeModeration],
    label: 'Завершено',
    historyLabel: 'Анкета одобрена',
  },
  [ClientPatronageStage.Rejected]: {
    canBeChangedToByAdmin: [
      ClientPatronageStage.Initial,
      ClientPatronageStage.Interview,
      ClientPatronageStage.Training,
      ClientPatronageStage.Photo,
      ClientPatronageStage.ResumeFilling,
      ClientPatronageStage.ResumeModeration,
      ClientPatronageStage.Completed,
    ],
    label: 'Специалист заблокирован',
    messageRequiringStrategy: 'always',
  },
};
