import React from 'react';
import { Button } from '@cp/ds/src/components/button';
import { AttachIcon } from '@cp/ds/src/icons/attach';

export interface AttachButtonProps {
  onAttach: (e: React.SyntheticEvent) => void;
}

export const AttachButton = React.forwardRef<HTMLButtonElement, AttachButtonProps>(({ onAttach }, ref) => {
  return (
    <Button color="white" onClick={onAttach} ref={ref} size="small" square type="button" variant="primary">
      <AttachIcon />
    </Button>
  );
});
