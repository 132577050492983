import React, { FC, useContext } from 'react';
import MUIMenuItem from '@mui/material/MenuItem';
import type { MenuItemProps as MuiMenuItemProps } from '@mui/material';
import { styled } from '../../theme/styled';
import { MenuSize } from './model';
import { MenuContext } from './context';
export type { MenuItemProps as MuiMenuItemProps } from '@mui/material';

export interface MenuItemProps extends MuiMenuItemProps {
  size?: MenuSize;
}

const sizeMap: Record<MenuSize, { horizontal: number; vertical: number }> = {
  'normal': { horizontal: 3, vertical: 1 },
  'small': { horizontal: 2, vertical: 0.5 },
};
const StyledMenuItem = styled(MUIMenuItem)<MenuItemProps>(({ theme, size = 'normal' }) => ({
  padding: `${theme.spacing(sizeMap[size].vertical)} ${theme.spacing(sizeMap[size].horizontal)}`,
}));

export const MenuItem: FC<MenuItemProps> = (props) => {
  const ctx = useContext(MenuContext);
  return <StyledMenuItem {...ctx} {...props} />;
};
