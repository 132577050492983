import React, { ReactNode } from 'react';
import { Box } from '@cp/ds/src/components/box';
import { CardProps } from 'src/shared/ui/card';
import { StyledCard } from './styledCard';

export interface InteractionCardProps extends CardProps {
  viewed?: boolean;
  photo?: ReactNode;
  title: ReactNode;
  tags?: ReactNode;
}

export const InteractionCard = ({ photo, title, tags, children, ...props }: InteractionCardProps) => {
  return (
    <StyledCard {...props}>
      <Box sx={{ flex: '0 0 60px' }}>{photo}</Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {title}
        <Box>{tags}</Box>
        {children}
      </Box>
    </StyledCard>
  );
};
