import React from 'react';
import { Typography } from '../typography';
import { Stack } from '../stack';
import { Box } from '../box';
import { StyledInput } from './components/styledInput';
import { StyledLabel } from './components/styledLabel';
import { CHECKBOX_LABEL_TYPOGRAPHY_VARIANT } from './const';
import { CheckboxProps } from './model';
import { useUtilityClasses } from './useUtilityClasses';

export const CheckboxButton = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ sx, iconLeft, label, value: _value, disabled = false, error = false, onChange, size: _size, ...props }, ref) => {
    const value = _value ?? false;
    const ownerState = {
      value,
      disabled,
      error,
    };
    const classes = useUtilityClasses(ownerState);

    return (
      <StyledLabel className={classes.root} ownerState={ownerState} sx={sx}>
        <StyledInput
          checked={value}
          disabled={disabled}
          onChange={disabled ? undefined : (event) => onChange?.(event.target.checked)}
          ref={ref}
          type="checkbox"
          {...props}
        />
        <Typography variant={CHECKBOX_LABEL_TYPOGRAPHY_VARIANT}>
          {iconLeft ? (
            <Stack component="span" direction="row" sx={{ alignItems: 'center' }}>
              <Box component="span" sx={{ display: 'flex', alignItems: 'center', mr: 0.5 }}>
                {iconLeft}
              </Box>
              {label}
            </Stack>
          ) : (
            label
          )}
        </Typography>
      </StyledLabel>
    );
  },
);
