import capitalize from 'lodash/capitalize';
import { AdminClientProfileViewModel } from '@common/model/client/profile/adminClientProfileViewModel';
import { DeclinationMnemonic } from '@common/model/resource/declinationMnemonic';
import { SEX_TRANSLATIONS } from '@common/const/resource/sex';
import { Skeleton } from '@cp/ds/src/components/skeleton';
import { ClientType } from '@common/model/client/clientType';
import { getCareerIcon, useCareerDeclination } from '@cp/entities/career';
import { useDeclination } from 'src/app/shared/hooks/useDeclination';
import { CLIENT_TYPE_TRANSLATIONS } from '../../../../../common/const/resource/client';
import { ClientProfileTagProps, ClientProfileTagType } from './types';

export const useClientProfileTags = (
  clientProfile: AdminClientProfileViewModel | undefined,
  { tagTypes }: { tagTypes: ClientProfileTagType[] },
): ClientProfileTagProps[] => {
  const declination = useDeclination();
  const careerDeclination = useCareerDeclination();

  const tagBuilders: Record<ClientProfileTagType, () => ClientProfileTagProps | undefined> = {
    /* ToDo: add premium tag */
    type: () =>
      clientProfile?.type && {
        label: capitalize(CLIENT_TYPE_TRANSLATIONS[clientProfile.type]),
      },
    career: () =>
      clientProfile?.type === ClientType.Specialist && clientProfile.mainCareerId
        ? {
            label: careerDeclination(1, clientProfile.mainCareerId),
            icon: getCareerIcon(clientProfile.mainCareerId),
          }
        : undefined,
    sex: () =>
      clientProfile?.sex && {
        label: capitalize(SEX_TRANSLATIONS[clientProfile.sex]),
      },
    age: () =>
      clientProfile?.age !== undefined
        ? {
            label: `${clientProfile.age} ${declination(Number(clientProfile.age), DeclinationMnemonic.Year)}`,
          }
        : undefined,
  };

  const tags = (
    clientProfile ? tagTypes.map((tagType) => tagBuilders[tagType]()).filter(Boolean) : [{ label: <Skeleton width={80} /> }]
  ) as ClientProfileTagProps[];

  return tags;
};
