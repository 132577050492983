import { CatalogOptionGroupViewModel } from '@common/model/catalog/options/group.view';
import { CatalogOptionsDictionary } from '@common/model/catalog/options/dictionary';

export const catalogOptionsDictionaryDataMapper = (rawResponse: CatalogOptionGroupViewModel[]): CatalogOptionsDictionary => {
  const r: CatalogOptionsDictionary = {
    options: {},
    groups: {},
  };

  for (const og of rawResponse) {
    const optIds: number[] = [];

    for (const o of og.options) {
      optIds.push(o.id);

      if (!r.options[o.id]) {
        r.options[o.id] = o;
      }
    }

    r.groups[og.id] = {
      ...og,
      options: optIds,
    };
  }

  return r;
};
