// eslint-disable-next-line import/no-duplicates
import { format, isThisYear } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { ru } from 'date-fns/locale';
import { DateFormat } from '@common/const/date';

export const defaultDateFormatter = (date: Date): string => {
  if (isThisYear(date)) {
    return format(date, DateFormat.CatalogItemDateThisYear, { locale: ru });
  }

  return format(date, DateFormat.CatalogItemDateAnotherYear, { locale: ru });
};

export const defaultDateTimeFormatter = (date: Date): string => {
  return format(date, DateFormat.DateTimeModerationTask, { locale: ru });
};
