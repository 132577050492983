import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { mergeSx } from '../../utils/sx/merge';
import { StyledSpan } from '../styled/styledSpan';
import { useTheme } from '../../hooks/useTheme';
import { StyledAnchor } from '../styled/styledAnchor';
import { StyledButton } from '../styled/styledButton';
import { TAG_COLOR_DEFAULT, TAG_SIZE_DEFAULT } from './const';
import { TagProps, TagStateProps } from './model';
import { buildTagSX } from './utils';
export type { TagProps } from './model';

export const Tag = React.forwardRef<HTMLAnchorElement | HTMLButtonElement, TagProps>(
  (
    {
      color = TAG_COLOR_DEFAULT,
      size = TAG_SIZE_DEFAULT,
      isClosable = false,
      iconLeft,
      iconRight,
      children,
      onClick,
      href,
      sx,
      isFocused = false,
    },
    ref,
  ) => {
    const theme = useTheme();

    const ownerState: TagStateProps = {
      color,
      size,
      isFocused,
      hasIconLeft: Boolean(iconLeft),
      isClosable,
    };

    const Container = href ? StyledAnchor : StyledButton;
    const containerSx = buildTagSX({ theme, ownerState });

    return (
      <Container
        href={href}
        onClick={onClick}
        ref={ref as React.RefObject<HTMLAnchorElement> & React.RefObject<HTMLButtonElement>}
        sx={mergeSx(containerSx, sx)}
        tabIndex={href ? undefined : 0}
      >
        {iconLeft && <StyledSpan sx={{ mr: 0.5, display: 'inline-flex', alignItems: 'center' }}>{iconLeft}</StyledSpan>}
        {children}
        {ownerState.isClosable && !iconRight && <CloseIcon fontSize="small" sx={{ ml: 1.5 }} />}
        {iconRight && <StyledSpan sx={{ ml: 1.5, display: 'inline-flex', alignItems: 'center' }}>{iconRight}</StyledSpan>}
      </Container>
    );
  },
);
