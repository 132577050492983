import { DialogMemberViewModel } from '@common/model/dialog/dialogMemberViewModel';
import { useSelector } from 'react-redux';
import { useCurrentUser } from '../../currentUser';
import { currentDialogIdSelector } from './store/currentDialogSelectors';
import { currentDialogMemberSelector } from './store/selectors';
import { DialogReducerStateShape } from './store/dialog';

export const useDialogMemberForCurrentUser = (): DialogMemberViewModel | undefined => {
  const user = useCurrentUser();
  const dialogId = useSelector(currentDialogIdSelector);
  if (!dialogId) {
    return;
  }

  return useSelector<DialogReducerStateShape, ReturnType<typeof currentDialogMemberSelector>>((state) =>
    currentDialogMemberSelector(state, { dialogId, user }),
  );
};
