import React from 'react';
import { useTheme } from '../../../../../hooks/useTheme';
import { Box } from '../../../../box';
import { RangeInputEdge } from './components/edge';
import { RangeInputRoot } from './components/root';
import { RangeInputProps, RangeInputStateProps, RangeInputValue } from './model';
import { RANGE_INPUT_DEFAULT_MAX, RANGE_INPUT_DEFAULT_MIN, RANGE_INPUT_DEFAULT_STEP } from './const';
import { Slider } from './components/slider';

export const RangeInput = React.forwardRef<HTMLDivElement, RangeInputProps>((props, ref) => {
  const theme = useTheme();
  const {
    error = false,
    disabled = false,
    sx,
    value: _value,
    onChange,
    step = RANGE_INPUT_DEFAULT_STEP,
    min = RANGE_INPUT_DEFAULT_MIN,
    max = RANGE_INPUT_DEFAULT_MAX,
    renderValueItem = (v) => v,
    single = false,
    size = 'normal',
    ...otherProps
  } = props;
  const [localValue, setLocalValue] = React.useState<RangeInputValue | null>(null);

  const isSingle = _value && Array.isArray(_value) ? false : single;

  const value =
    localValue !== null
      ? localValue
      : (([undefined, null].includes(_value as undefined) ? (isSingle ? 0 : [0, 0]) : _value) as RangeInputValue);

  const ownerState: Required<RangeInputStateProps> = { error, disabled, value, size };

  return (
    <RangeInputRoot ownerState={ownerState} ref={ref} sx={sx} {...otherProps}>
      <Box component="div" sx={{ display: 'flex' }}>
        {isSingle ? (
          <RangeInputEdge ownerState={ownerState}>{renderValueItem(Number(value), { index: 0 })}</RangeInputEdge>
        ) : (
          <React.Fragment>
            <RangeInputEdge ownerState={ownerState} sx={{ borderRight: `1px solid ${theme.palette['gray light']}` }}>
              {renderValueItem(value[0], { index: 0 })}
            </RangeInputEdge>
            <RangeInputEdge ownerState={ownerState} sx={{ pl: 2 }}>
              {renderValueItem(value[1], { index: 1 })}
            </RangeInputEdge>
          </React.Fragment>
        )}
      </Box>

      <Slider
        disabled={disabled}
        marks
        max={max}
        min={min}
        onChange={(event, newValue) => {
          setLocalValue(newValue as RangeInputValue);
        }}
        onChangeCommitted={(event, newValue) => {
          onChange?.(newValue as RangeInputValue);
          setLocalValue(null);
        }}
        step={step}
        value={value}
      />
    </RangeInputRoot>
  );
});
