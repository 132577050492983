import { authenticationTokenSelector } from '@cp/shared/api/core';
import { useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { useSocketManager } from '@cp/shared/api/socket';

export const useStatsSocket = () => {
  const authToken = useSelector(authenticationTokenSelector) as string;
  const manager = useSocketManager();
  const socket = useMemo(() => manager.socket('/stats', { auth: { token: authToken } }), [authToken, manager]);

  useEffect(() => {
    socket.connect();
    return () => {
      socket.disconnect();
    };
  });

  return socket;
};
